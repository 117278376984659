import { Platform, StyleSheet } from 'react-native'
import Colors from 'shared/styles/colors_enum'
import { FontSize } from 'shared/styles/font_enums'
import { Padding } from 'shared/styles/layout'

const style = StyleSheet.create({
    container: {
        flexDirection: 'row',
        padding: Padding.thirtySix,
    },
    title: {
        color: Colors.indigo,
        fontSize: FontSize.thirtyFive,
        ...Platform.select({
            ios: {
                paddingLeft: 10,
            },
        }),
    },
    progressBar: {
        backgroundColor: '#FFF0F0',
        borderRadius: 3,
        paddingVertical: 20,
        paddingHorizontal: 24,
        marginHorizontal: 10,
        marginBottom: 10,
    },
    titleRow: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
        paddingBottom: 15,
        paddingLeft: Padding.thirtySix,
    },
    content: {
        flexDirection: 'row',
        zIndex: 1,
    },
    column: {
        justifyContent: 'flex-start',
        flexDirection: 'column',
    },
})

export default style
