import AsyncStorage from '@react-native-async-storage/async-storage'
import { paramsToAuth } from 'domain/query_params'
import appConfiguration from 'infrastructure/appConfiguration'
import { discovery } from 'infrastructure/authentication/configuration'
import { useCallback, useEffect, useState } from 'react'
import { AuthContextType, UserIdentity } from 'shared/contexts/AuthContext'
import useServices from '../useServices'
import useWindow from '../useWindow'
import AuthError from './auth_error'
import useAuthClient from './useAuthClient'
import useRefresh from './useRefresh'

const useOIDC = (): AuthContextType => {
    const [user, setUser] = useState<UserIdentity | null>(null)
    const { isWeb } = useWindow()
    const { credentialsStorage } = useServices()
    const existingUser = useRefresh()
    const {
        request,
        response,
        promptAsync,
        doPKCE,
        fetchUserInfoAsync,
        revokeAsync,
    } = useAuthClient(isWeb, credentialsStorage)

    const signIn: () => void = useCallback(async () => {
        if (!request || request.url === null) return

        if (isWeb) {
            if (request.url && request.state && request.codeVerifier) {
                AsyncStorage.setItem(
                    `auth`,
                    JSON.stringify({
                        state: request.state,
                        codeVerifier: request.codeVerifier,
                    })
                )
                window.open(request.url, '_self')
            }
        } else {
            promptAsync()
        }
    }, [request])

    const handlePKCE: () => void = useCallback(async () => {
        if (!isWeb) return

        const queryParams = window.location.search
        const { code, state } = paramsToAuth(queryParams)
        if (!(code && state)) return

        const auth = await AsyncStorage.getItem(`auth`)
        if (!auth) return

        const { codeVerifier, state: parsedState } = JSON.parse(auth)
        if (state === parsedState) {
            setUserInfo(code, codeVerifier)
            AsyncStorage.removeItem(`auth`)
        }
    }, [])

    const signOut: () => void = useCallback(async () => {
        const { accessToken } = await credentialsStorage.get()
        if (accessToken) {
            await revokeAsync(
                {
                    token: accessToken,
                    clientId: appConfiguration.HOZEE_IDP_CLIENT_ID,
                },
                discovery
            )
            await credentialsStorage.clear()
        }
        setUser(null)
    }, [revokeAsync, setUser])

    const resetUser = () => {
        setUser(null)
    }

    const setUserInfo = async (code: string, codeVerifier: string) => {
        try {
            const userCredentials = await doPKCE(code, codeVerifier)
            const userInfo = await fetchUserInfoAsync(
                { accessToken: userCredentials.accessToken },
                discovery
            )
            if (userInfo) {
                setUser({
                    name: userInfo.name,
                })
            }
        } catch (error) {
            if (error instanceof AuthError) {
                // Handle AuthError
                // TODO: show an auth specific error Snackbar
            } else {
                // Handle other errors
                // TODO: show a generic error Snackbar
            }
        }
    }

    useEffect(() => {
        if (isWeb) return
        const setUserOnMobile = async () => {
            if (response?.type === 'success' && request?.codeVerifier) {
                setUserInfo(response.params.code, request.codeVerifier)
            }
        }
        setUserOnMobile()
    }, [response])

    useEffect(() => {
        if (existingUser) {
            setUser(existingUser)
        }
    }, [existingUser])

    return {
        handlePKCE,
        signIn,
        signOut,
        resetUser,
        user,
    }
}

export default useOIDC
