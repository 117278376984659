import { View } from 'react-native'
import RiskTooltip from '../../RiskTooltip/RiskTooltip'
import style from './ExistingRisk.style'
import { FontType } from 'shared/styles/font_enums'
import StyledText from 'shared/components/StyledText/StyledText'

export enum YesOrNo {
    yes = 'Oui',
    no = 'Non',
}

type Props = {
    title: string
    riskExists: boolean
    infos?: string
    zIndex?: number
}

const ExistingRisk: React.FC<Props> = (props: Props) => {
    const text = props.riskExists ? YesOrNo.yes : YesOrNo.no
    const moreInfos = props.infos

    return (
        <View style={{ zIndex: props.zIndex, ...style.container }}>
            <StyledText weight={FontType.BOLD} style={style.title}>
                {props.title}
            </StyledText>
            <StyledText weight={FontType.SEMIBOLD} style={style.value}>
                {text}
            </StyledText>
            <View style={style.tooltipContainer}>
                {moreInfos && <RiskTooltip content={moreInfos} />}
            </View>
        </View>
    )
}

export default ExistingRisk
