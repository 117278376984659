import Svg, { Path } from 'react-native-svg'
import { DEFAULT_ICON_PROPS, IconProps } from '../icon.types'

const IconComponent: React.FC<IconProps> = function ({
    size = DEFAULT_ICON_PROPS.size,
    color = DEFAULT_ICON_PROPS.color,
}: IconProps) {
    return (
        <Svg width={size} height={size} fill={color} viewBox="0 0 512 512">
            <Path d="M320 128c0 35.348-28.652 64-64 64s-64-28.652-64-64 28.652-64 64-64 64 28.652 64 64ZM234.668 0h42.664v42.668h-42.664ZM150.402 52.574l30.168-30.172 30.172 30.172-30.172 30.168ZM128 106.668h42.668v42.664H128ZM150.395 203.434l30.167-30.168 30.172 30.168-30.172 30.171ZM234.668 213.332h42.664V256h-42.664ZM301.277 203.422l30.168-30.172 30.172 30.172-30.172 30.168ZM341.332 106.668H384v42.664h-42.668ZM301.262 52.559l30.172-30.168 30.168 30.168-30.168 30.171ZM219.586 320H57.046l15.571 93.523 71.254-17.812ZM0 437.332h53.332V512H0ZM334.719 320H249.75l-80.66 80.66 45.203 15.082 135.703-19.367ZM35.414 320H0v96h51.414ZM356.48 320l16.02 80.086 37.25 37.246H512V320ZM416 458.668h96V512h-96ZM202.582 434.324l-53.676-17.898-74.238 18.562V512h137.684l-10.668-.129ZM223.895 435.945 223.02 512h171.648v-59.586l-35.777-35.773Zm0 0" />
        </Svg>
    )
}

export default IconComponent
