import { AuthContext } from 'shared/contexts/AuthContext'
import useOIDC from 'shared/hooks/authentication/useOIDC'

type Props = {
    children: JSX.Element
}

const AuthProvider: React.FC<Props> = (props: Props) => {
    const { handlePKCE, signOut, signIn, user, resetUser } = useOIDC()

    return (
        <AuthContext.Provider
            value={{
                handlePKCE,
                signOut,
                signIn,
                user,
                resetUser,
            }}
        >
            {props.children}
        </AuthContext.Provider>
    )
}

export default AuthProvider
