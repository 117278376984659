import { NavigationContainer } from '@react-navigation/native'
import appConfiguration from 'infrastructure/appConfiguration'
import { ActivityIndicator } from 'react-native'
import useWindow from 'shared/hooks/useWindow'
import Colors from 'shared/styles/colors_enum'
import MobileNavigation from './MobileNavigation'
import Navigation from './Navigation'

const NavigationWrapper: React.FC<unknown> = () => {
    const { isWeb, theme } = useWindow()
    const linking = {
        prefixes: [
            appConfiguration.HOZEE_BASE_URL,
            appConfiguration.HOZEE_MOBILE_APP_URL,
        ],
        config: {
            screens: {
                AddressAnalysis: 'analyse',
                Dashboard: 'tableau-de-bord',
                FavoriteAddresses: 'mes-adresses',
                HazardsUnderstanding: 'comprendre-les-aleas',
                Login: 'login',
                Methodology: 'methodologie',
            },
        },
    }

    return (
        <NavigationContainer
            linking={linking}
            fallback={<ActivityIndicator color={Colors.indigo} size="large" />}
            theme={theme}
        >
            {isWeb ? <Navigation /> : <MobileNavigation />}
        </NavigationContainer>
    )
}

export default NavigationWrapper
