import { Link } from '@react-navigation/native'
import React from 'react'
import { View } from 'react-native'
import Screens from 'screens/screens'
import useWindow from 'shared/hooks/useWindow'
import StyledText from '../StyledText/StyledText'
import style from './Menu.style'

const Menu: React.FC<unknown> = () => {
    const { fonts } = useWindow()
    return (
        <View style={style.menu}>
            <Link
                style={[style.link, { fontSize: fonts.link }]}
                to={{
                    screen: Screens.Dashboard,
                }}
            >
                TABLEAU DE BORD
            </Link>
            <StyledText style={style.separator}>|</StyledText>
            <Link
                style={[style.link, { fontSize: fonts.link }]}
                to={{ screen: Screens.FavoriteAddresses }}
            >
                MES ADRESSES
            </Link>
            <StyledText style={style.separator}>|</StyledText>
            <Link
                style={[style.link, { fontSize: fonts.link }]}
                to={{ screen: Screens.HazardsUnderstanding }}
            >
                COMPRENDRE LES ALÉAS
            </Link>
            <StyledText style={style.separator}>|</StyledText>
            <Link
                style={[style.link, { fontSize: fonts.link }]}
                to={{ screen: Screens.Methodology }}
            >
                MÉTHODOLOGIE
            </Link>
        </View>
    )
}

export default Menu
