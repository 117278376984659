import Svg, { Path } from 'react-native-svg'
import { DEFAULT_ICON_PROPS, IconProps } from '../icon.types'

const IconComponent: React.FunctionComponent<IconProps> = function ({
    size = DEFAULT_ICON_PROPS.size,
    color = DEFAULT_ICON_PROPS.color,
}: IconProps) {
    return (
        <Svg width={size} height={size} fill={color} viewBox="0 0 21 24">
            <Path
                fillRule="evenodd"
                d="M11.223.407a1.282 1.282 0 0 0-1.875 0L2.491 7.693a1.286 1.286 0 0 0 1.87 1.762L9 4.527v11.33c0 .713.573 1.286 1.286 1.286.712 0 1.285-.573 1.285-1.286V4.527l4.634 4.923c.488.52 1.302.541 1.816.054a1.286 1.286 0 0 0 .054-1.816L11.218.402l.005.005ZM1.286 21.43c-.713 0-1.286.573-1.286 1.285C0 23.427.573 24 1.286 24h18c.712 0 1.285-.573 1.285-1.286 0-.712-.573-1.285-1.285-1.285h-18Z"
                clipRule="evenodd"
            />
        </Svg>
    )
}

export default IconComponent
