import Svg, { Path, Defs, LinearGradient, Stop } from 'react-native-svg'
import {
    IllustrationProps,
    DEFAULT_ILLUSTRATION_PROPS,
} from 'shared/assets/illustration'

const IllustrationComponent: React.FC<IllustrationProps> = function ({
    size = DEFAULT_ILLUSTRATION_PROPS.size,
    idBaseName = DEFAULT_ILLUSTRATION_PROPS.idBaseName,
}: IllustrationProps) {
    return (
        <Svg width={size} height={size} fill="none" viewBox="0 0 180 180">
            <Path fill="#fff" d="M0 0h180v180H0z" />
            <Path
                fill={`url(#${idBaseName}-a)`}
                d="M158.42 107.822c-13.032 9.557-27.974 6.28-27.893-5.098.061-8.23-8.63-11.692-18.297-6.49-27.217 14.649-4.039 43.794-43.927 53.911-37.843 9.606-73.594-20.589-65.898-55.438-4.108 33.045 30.287 60.499 66.619 51.282 39.886-10.117 16.712-39.268 43.926-53.91 9.67-5.21 18.361-1.746 18.298 6.492-.079 11.37 14.863 14.645 27.893 5.097 8.631-6.32 16.099-13.28 19.117-27.191-2.011 15.517-10.356 24.402-19.838 31.345Z"
            />
            <Path
                fill={`url(#${idBaseName}-b)`}
                d="M178.265 76.47c-3.023 13.915-10.487 20.876-19.116 27.198-13.034 9.548-27.976 6.267-27.897-5.095.034-3.437-1.473-6.046-3.921-7.536a9.428 9.428 0 0 0-2.491-1.05c-3.268-.863-7.476-.288-11.883 2.088-.487.265-.964.53-1.419.807-.71.42-1.385.852-2.016 1.292-7.388 5.15-10.057 11.65-11.816 18.368-.189.719-.365 1.436-.543 2.155-.41 1.713-.786 3.426-1.195 5.13-.122.474-.244.95-.354 1.425-.232.905-.478 1.8-.752 2.697-.699 2.333-1.561 4.619-2.757 6.808-.056.099-.11.2-.167.299-1.673 2.962-3.975 5.724-7.32 8.168-3.71 2.708-8.716 5.016-15.593 6.763-36.327 9.217-70.724-18.235-66.616-51.28 2.347-10.654 8.759-21.739 20.41-32.26 51.842-46.838 111.735-48.263 142.112-21.595 11.773 10.332 14.94 23.296 13.334 35.618Z"
            />
            <Path
                fill={`url(#${idBaseName}-c)`}
                d="M40.428 105.959c-.565 1.117-1.453 2.137-2.658 2.96-4.117 2.798-10.351 2.238-13.926-1.263-.957-.938-1.624-1.985-2.009-3.077a6.636 6.636 0 0 1-.398-1.976 5.062 5.062 0 0 1-.005-.584l.038-.556.005-.013c.24-1.976 1.365-3.85 3.34-5.197 4.11-2.8 10.35-2.231 13.926 1.264 1.734 1.699 2.523 3.767 2.41 5.771l-.007.104-.05.436a4.614 4.614 0 0 1-.141.715 6.648 6.648 0 0 1-.525 1.416Z"
            />
            <Path
                fill="#6161FF"
                d="M56.953 71.291a5.791 5.791 0 0 1-.689 1.529c-.552.888-1.37 1.69-2.432 2.35-4.088 2.522-10.316 1.95-13.92-1.276-1.117-1.002-1.85-2.135-2.209-3.306a5.283 5.283 0 0 1-.234-1.243 5.103 5.103 0 0 1-.027-.585l.002-.051.068-.659a.133.133 0 0 1 .013-.078l-.007-.003c.293-1.715 1.39-3.326 3.262-4.488 4.088-2.522 10.315-1.949 13.919 1.276 1.764 1.574 2.57 3.489 2.453 5.334l.006.002-.013.101-.03.315c-.029.26-.083.524-.162.782Z"
            />
            <Path
                fill="#A2A2F8"
                d="M88.626 49.532c-.11.31-.254.607-.434.9-.548.85-1.406 1.622-2.58 2.229-4.04 2.09-10.272 1.508-13.919-1.3-.673-.519-1.211-1.074-1.61-1.663-.487-.68-.777-1.398-.879-2.115a3.243 3.243 0 0 1-.04-.78l.038-.404.007.002c.22-1.513 1.284-2.924 3.203-3.918 4.036-2.099 10.268-1.519 13.915 1.29 2.045 1.574 2.84 3.516 2.439 5.285-.036.162-.084.319-.14.474Z"
            />
            <Path
                fill="#CFCFFF"
                d="M131.07 43.747a4.524 4.524 0 0 1-.775 1.346c-.534.667-1.279 1.268-2.236 1.76-4.037 2.1-10.269 1.52-13.915-1.29-.894-.688-1.55-1.452-1.969-2.237a4.6 4.6 0 0 1-.522-1.557 3.059 3.059 0 0 1-.048-.51l.007-.059a3.905 3.905 0 0 1 .072-.823l.012-.033c.29-1.412 1.351-2.727 3.16-3.668 4.04-2.09 10.266-1.513 13.919 1.299 1.93 1.488 2.748 3.308 2.489 4.996l-.012.095c-.045.229-.103.458-.182.68Z"
            />
            <Path
                fill="#EDEDFF"
                d="M161.547 64.993c-.11.371-.275.729-.482 1.073-.473.795-1.193 1.517-2.139 2.106-3.485 2.15-8.809 1.665-11.886-1.086-.685-.614-1.198-1.281-1.549-1.982a4.912 4.912 0 0 1-.523-1.754 4.466 4.466 0 0 1-.038-.553l.063-.712v-.023c.236-1.476 1.173-2.865 2.788-3.86 3.484-2.152 8.807-1.66 11.883 1.092 1.532 1.368 2.214 3.036 2.086 4.634a.67.67 0 0 1-.005.12c-.029.323-.096.64-.198.945Z"
            />
            <Path
                fill={`url(#${idBaseName}-d)`}
                d="M170.039 155.91c-1.577 1.798-5.676-.486-5.676-.486l-57.754-30.691s5.244-1.218 7.327-5.538c1.998-4.14.165-5.489.011-5.592l.836.42 55.258 38.088c-.002-.002 1.579 2.001-.002 3.799Z"
            />
            <Path
                fill={`url(#${idBaseName}-e)`}
                d="m104.967 123.58-25.47-17.873s4.524-2.461 4.594-7.076l28.169 14.129c0-.002 1.021 6.9-7.293 10.82Z"
            />
            <Path
                fill={`url(#${idBaseName}-f)`}
                d="M113.934 119.195c-2.082 4.32-7.327 5.538-7.327 5.538l-1.642-1.153c8.313-3.92 7.291-10.822 7.291-10.822l1.675.838s.005 0 .012.005c.155.105 1.986 1.454-.009 5.594Z"
            />
            <Path
                fill={`url(#${idBaseName}-g)`}
                d="M89.817 101.504s.523 5.394-4.961 7.968l3.608 2.531s6.121-3.927 5.101-8.62l-3.748-1.879Z"
            />
            <Path
                fill={`url(#${idBaseName}-h)`}
                d="M113.934 119.195c-2.082 4.32-7.327 5.538-7.327 5.538l-1.642-1.153c8.313-3.92 7.291-10.822 7.291-10.822l1.675.838s.005 0 .012.005c.155.105 1.986 1.454-.009 5.594Z"
            />
            <Path
                fill={`url(#${idBaseName}-i)`}
                d="M84.09 98.631c.08 6.777-4.595 7.076-4.595 7.076-8.622 2.396-14.879-5.439-17.51-11.33-2.632-5.89-7.166-5.678-5.795-6.727.307-.234 1.142-.412 2.314-.526 3-.29 6.027.078 8.892 1.009.304.099.612.202.93.314-.07-.07-.86-.845-2.822-1.364 3.392.196 6.905.679 9.022 1.508 4.845 1.895 9.564 10.04 9.564 10.04Z"
            />
            <Defs>
                <LinearGradient
                    id={`${idBaseName}-a`}
                    x1={8.445}
                    x2={159.732}
                    y1={77.722}
                    y2={131.609}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#7C889D" />
                    <Stop offset={0.186} stopColor="#929EB0" />
                    <Stop offset={0.536} stopColor="#B7C2CF" />
                    <Stop offset={0.82} stopColor="#CED9E2" />
                    <Stop offset={1} stopColor="#D6E1E9" />
                </LinearGradient>
                <LinearGradient
                    id={`${idBaseName}-b`}
                    x1={96.212}
                    x2={82.427}
                    y1={179.83}
                    y2={-43.823}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#C0D2E1" />
                    <Stop offset={0.038} stopColor="#C5D6E3" />
                    <Stop offset={0.259} stopColor="#DFE8F0" />
                    <Stop offset={0.488} stopColor="#F1F5F8" />
                    <Stop offset={0.729} stopColor="#FBFCFD" />
                    <Stop offset={1} stopColor="#fff" />
                </LinearGradient>
                <LinearGradient
                    id={`${idBaseName}-c`}
                    x1={30.323}
                    x2={-21.691}
                    y1={99.395}
                    y2={-71.105}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#465A80" />
                    <Stop offset={0.651} stopColor="#2B3D52" />
                    <Stop offset={1} stopColor="#20313F" />
                </LinearGradient>
                <LinearGradient
                    id={`${idBaseName}-d`}
                    x1={143.095}
                    x2={138.25}
                    y1={131.827}
                    y2={140.565}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#677A98" />
                    <Stop offset={0.115} stopColor="#607390" />
                    <Stop offset={0.67} stopColor="#41576C" />
                    <Stop offset={0.967} stopColor="#354C5E" />
                </LinearGradient>
                <LinearGradient
                    id={`${idBaseName}-e`}
                    x1={93.825}
                    x2={98.694}
                    y1={114.798}
                    y2={105.242}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#7C879C" />
                    <Stop offset={0.18} stopColor="#919CAE" />
                    <Stop offset={0.533} stopColor="#B6C1CE" />
                    <Stop offset={0.819} stopColor="#CDD8E1" />
                    <Stop offset={1} stopColor="#D5E0E8" />
                </LinearGradient>
                <LinearGradient
                    id={`${idBaseName}-f`}
                    x1={98.329}
                    x2={118.962}
                    y1={125.588}
                    y2={113.348}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#3D4F71" stopOpacity={0} />
                    <Stop
                        offset={0.17}
                        stopColor="#334561"
                        stopOpacity={0.068}
                    />
                    <Stop
                        offset={0.433}
                        stopColor="#293A4E"
                        stopOpacity={0.173}
                    />
                    <Stop
                        offset={0.706}
                        stopColor="#223343"
                        stopOpacity={0.282}
                    />
                    <Stop offset={1} stopColor="#20313F" stopOpacity={0.4} />
                </LinearGradient>
                <LinearGradient
                    id={`${idBaseName}-g`}
                    x1={90.26}
                    x2={98.703}
                    y1={106.019}
                    y2={91.353}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#7C879C" />
                    <Stop offset={0.18} stopColor="#919CAE" />
                    <Stop offset={0.533} stopColor="#B6C1CE" />
                    <Stop offset={0.819} stopColor="#CDD8E1" />
                    <Stop offset={1} stopColor="#D5E0E8" />
                </LinearGradient>
                <LinearGradient
                    id={`${idBaseName}-h`}
                    x1={111.333}
                    x2={119.777}
                    y1={118.152}
                    y2={103.486}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#7C879C" />
                    <Stop offset={0.18} stopColor="#919CAE" />
                    <Stop offset={0.533} stopColor="#B6C1CE" />
                    <Stop offset={0.819} stopColor="#CDD8E1" />
                    <Stop offset={1} stopColor="#D5E0E8" />
                </LinearGradient>
                <LinearGradient
                    id={`${idBaseName}-i`}
                    x1={55.938}
                    x2={84.091}
                    y1={96.588}
                    y2={96.588}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#EDEDFF" />
                    <Stop offset={0.448} stopColor="#A2A2F8" />
                    <Stop offset={0.859} stopColor="#3B3BE2" />
                </LinearGradient>
            </Defs>
        </Svg>
    )
}

export default IllustrationComponent
