import Svg, { Path } from 'react-native-svg'
import { DEFAULT_ICON_PROPS, IconProps } from '../icon.types'

const IconComponent: React.FC<IconProps> = function ({
    size = DEFAULT_ICON_PROPS.size,
}: IconProps) {
    return (
        <Svg height={size} width={size} fill="none" viewBox="0 0 80 80">
            <Path
                fill="#8598B6"
                d="M65.803 71.727c.098 3.729-14.481 7.138-32.564 7.614S.417 77.18.319 73.45c-.098-3.73 14.48-7.138 32.564-7.614 18.083-.476 32.82 2.16 32.92 5.89Z"
            />
            <Path d="m70.969 62.775-.097.26c-.142.29-.37.504-.664.562H70.2l-44.223 6.386c-.44.094-.892-.211-1.123-.753L6.65 17.659c-.01-.025-.021-.048-.028-.073a1.882 1.882 0 0 1-.104-.926c.038-.256.124-.488.256-.674.176-.256.432-.418.736-.412l38.302.785c.307.003.604.193.815.515l24.134 44.404c.294.446.208 1.497.208 1.497Z" />
            <Path d="m72.337 58.662-.004.014-.097.259c-.142.29-.37.505-.664.563h-.007l-36.35 5.36-.35.062-7.523.964c-.439.094-.892-.211-1.123-.753L8.395 14.636c-.01-.025-.02-.048-.028-.073a1.866 1.866 0 0 1-.104-.913v-.013c.038-.256.125-.488.256-.674.004-.004.004-.004.004-.007a1.088 1.088 0 0 1 .142-.162v-.004a.816.816 0 0 1 .59-.238l3.437.068.608.013.152.004.557.01 1.186.024.39.01 2.573.052.54.013.218.004.487.01 4.229.086.467.01.277.004h.003l.422.01 4.29.086.408.007.339.006.363.01 4.353.087.349.006.388.01.314.004 4.404.09.3.006.437.01.262.004 4.46.094.73.013 1.013.028c.307.003.604.193.816.515L72.124 57.18c.294.446.346 1.02.212 1.483Z" />
            <Path d="M73.342 53.826c-5.135 6.693-38.329 10.748-38.329 10.748l-7.525 1.31c-.439.093-.89-.212-1.123-.754l-2.116-5.998-2.893-8.194L8.543 14.636l-.029-.075a1.904 1.904 0 0 1-.103-.912v-.013a1.57 1.57 0 0 1 .256-.674l.002-.008a1.108 1.108 0 0 1 .142-.162v-.002a.833.833 0 0 1 .592-.241l3.438.07.607.015.152.003.556.01 1.188.023.39.01 2.573.052.537.015.22.002.485.01 4.229.088.468.01.276.003h.003l.424.01 4.29.085.408.008.339.008.361.01 4.353.085.351.007.385.01.315.003 4.405.09.302.008.437.01.26.003 4.462.093.729.015 1.012.026c.31.005.608.194.816.517l7.58 13.629a178.344 178.344 0 0 0 3.962 7.308 164.159 164.159 0 0 0 1.87 3.175c.002.007.008.012.013.022.248.408.49.806.734 1.191 5.538 8.878 9.575 12.446 10.818 13.642.269.267.419.708.191 1.01Z" />
            <Path d="M19.123 15.61a.71.71 0 0 1-.18-.06 1.196 1.196 0 0 1-.388-.248 1.137 1.137 0 0 1-.307-.436c-.107-.294.017-.502.284-.509.041 0 .09.004.138.014.197.035.405.152.57.307.139.129.253.28.308.436.124.344-.066.565-.425.496Z" />
            <Path d="M14.028 15.114c.125.342-.065.564-.424.495-.358-.068-.751-.402-.876-.744-.125-.342.065-.564.424-.495.359.07.752.402.877.744Z" />
            <Path d="M24.447 15.53a.843.843 0 0 0 .228.08c.36.07.55-.152.426-.494-.073-.201-.24-.398-.436-.543a1.142 1.142 0 0 0-.442-.2.514.514 0 0 0-.267.006c-.176.062-.244.245-.155.488a.968.968 0 0 0 .166.283c.124.16.3.298.48.38Z" />
            <Path d="M30.006 15.61a.78.78 0 0 1-.19-.063 1.265 1.265 0 0 1-.426-.29 1.124 1.124 0 0 1-.262-.391c-.104-.28.006-.48.249-.505a.65.65 0 0 1 .176.01c.184.035.377.138.537.277.155.135.28.3.342.467.124.343-.066.564-.426.495Z" />
            <Path d="M35.237 15.547a.992.992 0 0 0 .194.063c.36.07.55-.152.426-.495a1.202 1.202 0 0 0-.342-.467 1.195 1.195 0 0 0-.536-.277.62.62 0 0 0-.173-.01c-.246.024-.357.221-.253.505.052.138.146.277.266.39.121.126.266.229.418.291Z" />
            <Path d="M40.855 15.61a1.336 1.336 0 0 1-.616-.353 1.124 1.124 0 0 1-.262-.391c-.104-.284.007-.48.253-.505a.62.62 0 0 1 .173.01c.183.035.376.138.536.277.152.135.277.3.339.467.127.343-.063.564-.423.495Z" />
            <Path d="M46.146 15.53a.844.844 0 0 0 .228.08c.36.07.55-.152.425-.494-.072-.201-.242-.401-.439-.543-.138-.1-.29-.173-.439-.2a.514.514 0 0 0-.266.006c-.176.062-.245.245-.155.488.033.097.093.194.162.283.127.16.3.298.484.38Z" />
            <Path d="m35.214 64.51-.35.062-19.669-51.901.391.01L35.2 64.465c.006.013.01.028.014.045Z" />
            <Path d="M18.788 12.403c-.474-1.178-1.288-2.064-2.266-2.325-.654-.177-1.296-.051-1.867.356-.581.419-1.034 1.115-1.291 1.968-.474-1.177-1.286-2.062-2.267-2.324-.705-.19-1.47.045-2.092.643-.743.72-1.39 2.123-.974 3.92.041.184.228.3.411.256.024-.003-.048-.263-.048-.263l-.007-.015a1.882 1.882 0 0 1-.125-.984c.038-.256.124-.487.256-.674.045-.062.093-.12.145-.17v-.003c.142-.688.467-1.227.823-1.57.442-.425.964-.598 1.431-.474.737.197 1.376.92 1.774 1.874.318.758.48 1.663.426 2.57a.34.34 0 0 0 .321.366.044.044 0 0 0 .02.003.345.345 0 0 0 .346-.325 6.589 6.589 0 0 0-.352-2.595l.556.01c.21-.726.577-1.314 1.051-1.653.395-.283.837-.37 1.283-.249.764.208 1.425.978 1.815 1.988.197.498.325 1.052.373 1.625.025.273.028.55.01.83-.003.041 0 .079.014.114.114.107.25.196.388.249a.343.343 0 0 0 .287-.319c.01-.186.013-.37.01-.553a6.58 6.58 0 0 0-.324-1.928l.487.01c.203-.781.584-1.41 1.082-1.767.394-.283.837-.37 1.283-.249.795.215 1.473 1.037 1.864 2.102.17.474.287.996.325 1.532.024.253.027.512.01.771.124.16.3.298.48.38a.348.348 0 0 0 .208-.297c.014-.221.017-.442.01-.66a8.973 8.973 0 0 0-.053-.634v-.003a7 7 0 0 0-.234-1.074l.422.01c.197-.83.588-1.504 1.11-1.878a1.486 1.486 0 0 1 1.282-.249c.82.222 1.522 1.093 1.899 2.213.149.44.25.913.287 1.404.024.272.027.55.01.826 0 .024 0 .048.004.069.121.125.27.228.425.29a.342.342 0 0 0 .263-.314c.014-.197.017-.39.01-.584a6.762 6.762 0 0 0-.052-.712 7.13 7.13 0 0 0-.2-.965 5.687 5.687 0 0 0-.2-.566c-.474-1.18-1.286-2.065-2.265-2.327-.654-.176-1.297-.052-1.867.356-.585.418-1.034 1.113-1.293 1.967v.004a4.123 4.123 0 0 0 0 0V12.4c-.478-1.179-1.29-2.06-2.265-2.323-.654-.176-1.301-.051-1.868.356-.583.42-1.034 1.116-1.291 1.97Z" />
            <Path d="M35.504 15.233a7.154 7.154 0 0 0-.04-1.293 6.1 6.1 0 0 0-.174-.854l.314.004c.176-.93.588-1.691 1.155-2.096.394-.283.837-.37 1.282-.249.875.235 1.611 1.21 1.968 2.434a5.723 5.723 0 0 1 .228 2.009c0 .024 0 .048.003.069.125.125.274.228.426.29a.342.342 0 0 0 .262-.314 7.224 7.224 0 0 0-.04-1.296 7.059 7.059 0 0 0-.142-.74l.26.004c.164-.982.585-1.784 1.176-2.206a1.486 1.486 0 0 1 1.283-.249c.899.242 1.657 1.269 2.002 2.548a5.889 5.889 0 0 1 .193 1.856c.128.16.301.298.484.38a.348.348 0 0 0 .208-.297c.015-.221.018-.442.007-.66a6.544 6.544 0 0 0-.162-1.265c-.377-1.617-1.338-2.903-2.552-3.228-.653-.176-1.3-.052-1.867.356-.584.418-1.036 1.115-1.292 1.968-.473-1.178-1.286-2.063-2.264-2.325-.653-.176-1.3-.052-1.867.356-.584.418-1.037 1.117-1.293 1.97-.473-1.178-1.285-2.063-2.264-2.326-.654-.176-1.301-.052-1.868.356-.584.418-1.034 1.117-1.293 1.97.076.184.141.37.2.567l.364.01c.186-.881.587-1.597 1.134-1.988.394-.283.837-.37 1.283-.249.847.228 1.566 1.151 1.935 2.324.129.407.215.843.253 1.293.02.272.024.55.007.826 0 .024 0 .048.006.069.121.125.267.228.419.29a.345.345 0 0 0 .266-.314Z" />
            <Path d="m72.236 58.933-.596 1.61c0 .007-.006.019-.006.024-.012.066-.03.125-.048.185-.13.376-.388.668-.734.746l-44.236 6.383c-.442.09-.895-.214-1.121-.757L12.478 30.237l-2.202-6.228-2.601-7.373c-.226-.526-.185-1.104.03-1.522l.167-.441.054-.15.334-.888v.012c-.011.09-.017.179-.017.268 0 .072.006.144.017.215v.006c.018.143.054.286.108.424.005.03.017.054.023.077l3.162 8.937 2.38 6.753L26.217 65.13c.214.5.62.8 1.026.763a.47.47 0 0 0 .095-.012l.955-.136 43.27-6.253h.005c.46-.101.668-.56.668-.56Z" />
            <Path d="M71.634 60.567c-.012.066-.03.125-.048.185l-44.606 6.55a.913.913 0 0 1-.18.018c-.423 0-.829-.31-1.037-.823l-17.831-50.5a1.95 1.95 0 0 1-.06-1.324l.054-.15a.752.752 0 0 1 .048-.107l.286-.77c-.012.09-.018.18-.018.27 0 .07.006.143.018.214v.005l-.13.347c-.221.43-.227.984-.03 1.456l17.825 50.494c.209.5.62.781 1.02.698l44.689-6.563Z" />
            <Path d="M22.485 23.02h-.307c-.04 0-.05.024-.03.065l.064.127h-.325l-.166-.33c-.07-.138-.05-.188.085-.188h1.396c.135 0 .205.05.275.189l.166.33h-.324l-.063-.128c-.021-.041-.055-.065-.095-.065h-.307l.888 1.762h-.369l-.888-1.762ZM23.611 22.693h.31l.174.344h-.31l-.174-.344Zm.473.97c-.022-.043-.055-.064-.094-.064h-.113l-.16-.318h.34c.133 0 .22.062.29.2l.464.92c.022.042.055.066.095.066h.113l.159.315h-.341c-.133 0-.219-.06-.29-.2l-.463-.918ZM24.916 23.663c-.022-.044-.055-.065-.094-.065h-.113l-.16-.317h.318c.133 0 .212.061.254.144l.03.06h.005s0-.239.337-.239c.389 0 .802.32 1.036.786.24.476.113.785-.264.785-.285 0-.511-.197-.511-.197h-.006s.032.053.071.13l.313.62h-.355l-.86-1.707Zm1.125.839c.198 0 .28-.167.13-.464-.144-.286-.384-.471-.596-.471-.186 0-.29.141-.122.474.117.231.35.461.588.461ZM26.995 24.305s.295.22.54.22c.11 0 .17-.047.12-.146-.107-.212-.864-.209-1.108-.695-.15-.3.04-.438.337-.438.194 0 .54.068.662.312l.078.156h-.316l-.037-.073c-.035-.071-.155-.106-.234-.106-.127 0-.19.047-.146.135.118.236.859.188 1.111.688.143.282-.008.459-.32.459-.395 0-.733-.268-.733-.268l.046-.244Z" />
            <Path d="m22.459 25.349 1.633-.086 1.634-.064c.544-.019 1.088-.073 1.633-.042.545.029 1.089.074 1.634.192v.042c-.545.117-1.09.163-1.634.192-.545.03-1.089-.024-1.633-.043l-1.634-.064-1.633-.085v-.042Z" />
        </Svg>
    )
}

export default IconComponent
