import { Animated, View } from 'react-native'
import style from './ScrollBar.style'

type Props = {
    height: number
    position: Animated.AnimatedInterpolation<number>
}
const ScrollBar: React.FC<Props> = (props: Props) => {
    const totalVerticalMargin = 4

    return (
        <View style={style.container}>
            <Animated.View
                testID="scrollBar"
                style={{
                    ...style.content,
                    height: props.height - totalVerticalMargin,
                    transform: [
                        {
                            translateY: props.position,
                        },
                    ],
                }}
            />
        </View>
    )
}

export default ScrollBar
