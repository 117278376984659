import { Platform, StyleSheet } from 'react-native'
import { screenBoxShadow } from './layout'
import Colors from './colors_enum'

const style = StyleSheet.create({
    screen: {
        ...screenBoxShadow,
        alignSelf: 'center',
        backgroundColor: Colors.white,
        borderRadius: 20,
        marginTop: 10,
        minHeight: '70vh',
        marginBottom: 30,
        marginHorizontal: 'auto',
        width: '70vw',
        ...Platform.select({
            ios: {
                borderRadius: 0,
                height: '100%',
                minHeight: '70%',
                width: '100%',
            },
        }),
        overflow: 'hidden',
    },
    content: {
        justifyContent: 'space-between',
        flex: 1,
    },
})

export default style
