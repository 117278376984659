import axios, {
    AxiosError,
    AxiosInstance,
    InternalAxiosRequestConfig,
} from 'axios'
import CredentialsStorage from 'domain/storage/credentials_storage'
import { refreshAsync } from 'expo-auth-session'
import appConfiguration from 'infrastructure/appConfiguration'
import { discovery } from 'infrastructure/authentication/configuration'
import HttpError from '../http_error'
import HttpClient from './http_client'

class HozeeHttpClient extends HttpClient {
    credentialsStorage: CredentialsStorage

    constructor(client: AxiosInstance, credentialsStorage: CredentialsStorage) {
        super(client)
        this.credentialsStorage = credentialsStorage
        this.initInterceptors()
    }

    initInterceptors(): void {
        this.client.interceptors.request.use(this.onRequestFullfilled, error =>
            Promise.reject(error)
        )

        this.client.interceptors.response.use(
            response => response,
            this.onResponseRejected
        )
    }

    private onRequestFullfilled = async (
        config: InternalAxiosRequestConfig
    ) => {
        const credentials = await this.credentialsStorage.get()
        config.headers.Authorization = `Bearer ${credentials.accessToken}`
        config.headers['Content-Type'] = 'application/json'
        return config
    }

    private onResponseRejected = async (error: AxiosError) => {
        const config = error?.config || { headers: { Authorization: null } }
        const httpStatusCode = error.response?.status
        const httpError = new HttpError(error.message, error.response?.status)

        switch (httpStatusCode) {
            case 403: {
                const credentials = await this.credentialsStorage.get()

                if (credentials.refreshToken) {
                    try {
                        const response = await refreshAsync(
                            {
                                clientId: appConfiguration.HOZEE_IDP_CLIENT_ID,
                                refreshToken: credentials.refreshToken,
                            },
                            discovery
                        )

                        if (response) {
                            await this.credentialsStorage.set(
                                response.accessToken,
                                response.refreshToken
                            )

                            config.headers.Authorization = `Bearer ${response.accessToken}`
                            return axios(config)
                        }
                    } catch (error: unknown) {
                        throw httpError
                    }
                }
                throw httpError
            }
            default: {
                throw httpError
            }
        }
    }
}

export default HozeeHttpClient
