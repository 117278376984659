import { createContext } from 'react'
import CredentialsStorage from 'domain/storage/credentials_storage'
import { RiskService } from 'domain/risk_service'
import { AddressService } from 'domain/address_service'
import { FavoriteService } from 'domain/favorite_service'
import { AnalyticsService } from 'domain/analytics_service'

export type ServicesContextType = {
    addressService: AddressService
    analyticsService: AnalyticsService
    credentialsStorage: CredentialsStorage
    favoriteService: FavoriteService
    riskService: RiskService
}

export const ServiceContext = createContext<ServicesContextType>(
    {} as ServicesContextType
)
