import { DarkTheme, DefaultTheme } from '@react-navigation/native'
import { Platform, useWindowDimensions } from 'react-native'
import { WindowContext } from 'shared/contexts/WindowContext'
import useColorScheme from 'shared/hooks/useColorScheme'
import { FontSize } from 'shared/styles/font_enums'
import { ScreenProportion } from 'shared/styles/layout'

type Props = {
    children: JSX.Element
}

const WindowProvider: React.FC<Props> = (props: Props) => {
    const colorScheme = useColorScheme()
    const { width, height } = useWindowDimensions()
    const isSmallWindow = width < ScreenProportion.width1045
    const isWeb = Platform.OS == 'web'
    const theme = colorScheme === 'dark' ? DarkTheme : DefaultTheme

    const fonts = {
        link:
            width <= ScreenProportion.width1150
                ? FontSize.ten
                : FontSize.twelve,
        text:
            width <= ScreenProportion.width1150
                ? FontSize.twelve
                : FontSize.fourteen,
        title:
            width <= ScreenProportion.width1150
                ? FontSize.twenty
                : FontSize.thirtyFive,
    }

    return (
        <WindowContext.Provider
            value={{ fonts, height, isWeb, isSmallWindow, theme }}
        >
            {props.children}
        </WindowContext.Provider>
    )
}

export default WindowProvider
