import { StyleSheet } from 'react-native'
import Colors from 'shared/styles/colors_enum'
import { FontSize } from 'shared/styles/font_enums'
import { Padding } from 'shared/styles/layout'

const style = StyleSheet.create({
    container: {
        flexDirection: 'column',
    },
    subContainer: {
        flexDirection: 'row',
    },
    progressBar: { flex: 1 },
    textContainer: {
        alignItems: 'flex-start',
        flex: 1,
        paddingLeft: Padding.thirtySix,
    },
    textContent: {
        color: Colors.customBlack,
        fontSize: FontSize.fourteen,
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingBottom: 8,
        zIndex: 1,
    },
    title: {
        color: Colors.customBlack,
        fontSize: FontSize.eighteen,
        paddingBottom: 6,
    },
    padding: {
        paddingBottom: 35,
    },
})

export default style
