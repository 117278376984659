export enum FontSize {
    ten = 10,
    eleven = 11,
    twelve = 12,
    thirteen = 13,
    fourteen = 14,
    sixteen = 16,
    eighteen = 18,
    twenty = 20,
    twentyFour = 24,
    thirtyFive = 35,
}

export enum LetterSpacing {
    small = 0.5,
}

export enum FontType {
    EXTRALIGHT,
    LIGHT,
    MEDIUM,
    REGULAR,
    SEMIBOLD,
    BOLD,
    EXTRABOLD,
}
