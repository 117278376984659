import axios, { AxiosResponse } from 'axios'
import { AddressService } from 'domain/address_service'
import { Address } from 'domain/types/address'
import HttpError from './http_error'

type BaseAdressesNationaleResponse = {
    geometry: {
        coordinates: number[]
        type: string
    }
    properties: {
        city: string
        citycode: string
        context: string
        housenumber?: string | undefined
        id: string
        importance: number
        label: string
        name?: string
        postcode: string
        score: number
        street: string
        type: string
        x: number
        y: number
    }
}

class HttpBaseAdressesNationaleService implements AddressService {
    private addressProviderUrl: string
    private limit: number

    constructor() {
        this.addressProviderUrl = 'https://api-adresse.data.gouv.fr/search/'
        this.limit = 10
    }

    async get(text: string): Promise<Address[] | undefined> {
        try {
            const httpResponse = await axios.get(
                `${this.addressProviderUrl}?limit=${this.limit}&q=${text}`
            )

            return this.buildHttpAddressesResponse(httpResponse)
        } catch (error) {
            if (axios.isAxiosError(error)) {
                throw new HttpError(error.message, error.status)
            }
        }
    }

    private buildHttpAddressesResponse(http_response: AxiosResponse) {
        const data = http_response.data
        const addressSuggestions: Address[] = data.features
            .map(
                (
                    baseAdressesNationaleResponse: BaseAdressesNationaleResponse
                ) => {
                    const {
                        geometry: { coordinates },
                        properties: {
                            city,
                            housenumber,
                            id,
                            label,
                            postcode,
                            street,
                        },
                    } = baseAdressesNationaleResponse
                    const suggestion = {
                        city,
                        department: postcode.slice(0, 2),
                        housenumber,
                        interopkey: id,
                        label,
                        latitude: coordinates[1],
                        longitude: coordinates[0],
                        postcode,
                        street,
                    }

                    return suggestion
                }
            )
            .filter((address: Address) => address)
        return addressSuggestions
    }
}

export default HttpBaseAdressesNationaleService
