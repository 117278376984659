import { StyleSheet } from 'react-native'
import Colors from 'shared/styles/colors_enum'
import { FontSize } from 'shared/styles/font_enums'

const style = StyleSheet.create({
    container: {
        backgroundColor: 'white',
        marginTop: 8,
        borderRadius: 6,
        shadowColor: Colors.black,
        shadowOffset: {
            width: 0,
            height: 10,
        },
        shadowOpacity: 0.12,
        shadowRadius: 24,
    },
    warning: {
        flex: 1,
        marginHorizontal: 8,
        marginVertical: 4,
        fontSize: FontSize.fourteen,
        color: Colors.red,
    },
})

export default style
