import Svg, { Path } from 'react-native-svg'
import { DEFAULT_ICON_PROPS, IconProps } from '../icon.types'

const IconComponent: React.FC<IconProps> = function ({
    size = DEFAULT_ICON_PROPS.size,
    color = DEFAULT_ICON_PROPS.color,
}: IconProps) {
    return (
        <Svg width={size} height={size} fill={color} viewBox="0 0 20 20">
            <Path d="m7.992 12.983-2.888-2.887a.834.834 0 1 0-1.179 1.179l4.067 4.067 7.44-7.522a.83.83 0 1 0-1.182-1.168l-6.258 6.331Z" />
        </Svg>
    )
}
export default IconComponent
