import { View } from 'react-native'
import Colors from 'shared/styles/colors_enum'
import {
    floodRisk,
    droughtRisk,
    combinedRisk,
    waterRestrictions,
} from 'domain/types/risk_type'
import { Analytics } from 'domain/analytics_service'
import CurveGradient, { Direction } from './CurveGradient/CurveGradient'
import Svg, { G, Circle } from 'react-native-svg'
import style from './HighRisksChart.style'
import useWindow from 'shared/hooks/useWindow'
import { FontType } from 'shared/styles/font_enums'
import StyledText from '../StyledText/StyledText'
import HighRisksChartLegend from './HighRisksChartLegend/HighRisksChartLegend'

export type Repartition = {
    flood: number
    drought: number
    waterRestrictions: number
    combined: number
}

type Props = {
    analytics: Analytics
}

const HighRisksChart: React.FC<Props> = (props: Props) => {
    const { fonts } = useWindow()
    const radius = 90
    const circleCircumference = 2 * Math.PI * radius
    const riskRepartition = props.analytics.allocation as Repartition

    const { combined: strongCombinedRisks, ...strongRisksRepartition } =
        riskRepartition

    const floodStrokeDashoffset =
        circleCircumference -
        (circleCircumference * strongRisksRepartition.flood) / 100
    const floodAngle = (strongRisksRepartition.flood / 100) * 360

    const droughtStrokeDashoffset =
        circleCircumference -
        (circleCircumference * strongRisksRepartition.drought) / 100
    const droughtAngle = (strongRisksRepartition.drought / 100) * 360

    const waterRestrictionStrokeDashoffset =
        circleCircumference -
        (circleCircumference * strongRisksRepartition.waterRestrictions) / 100
    const waterRestrictionAngle =
        (strongRisksRepartition.waterRestrictions / 100) * 360

    const combinedStrokeDashoffset =
        circleCircumference - (circleCircumference * strongCombinedRisks) / 100
    const combinedAngle = (strongCombinedRisks / 100) * 360

    const rotationRisksAngle =
        90 -
        (floodAngle + droughtAngle + combinedAngle + waterRestrictionAngle) / 2

    return (
        <View style={style.container}>
            <View style={style.highRisksContainer}>
                <View style={style.chartContainer}>
                    <Svg height="200" width="200" viewBox="0 0 240 240">
                        <CurveGradient
                            id={'gradient-flood'}
                            offsets={{
                                start: 0.1,
                                middle: 0.45,
                                end: 0.9,
                            }}
                            angle={floodAngle}
                            colors={{
                                start: floodRisk.colors[0],
                                middle: floodRisk.colors[1],
                                end: floodRisk.colors[2],
                            }}
                            direction={Direction.HORIZONTAL}
                        />
                        <CurveGradient
                            id={'gradient-drought'}
                            angle={droughtAngle}
                            offsets={{
                                start: 0.1,
                                middle: 0.5,
                                end: 0.9,
                            }}
                            colors={{
                                start: droughtRisk.colors[0],
                                middle: droughtRisk.colors[1],
                                end: droughtRisk.colors[2],
                            }}
                            direction={Direction.HORIZONTAL}
                        />
                        <CurveGradient
                            id={'gradient-waterRestriction'}
                            angle={waterRestrictionAngle}
                            offsets={{
                                start: 0.1,
                                middle: 0.6,
                                end: 0.9,
                            }}
                            colors={{
                                start: waterRestrictions.colors[0],
                                middle: waterRestrictions.colors[1],
                                end: waterRestrictions.colors[2],
                            }}
                            direction={Direction.HORIZONTAL}
                        />
                        <CurveGradient
                            id={'gradient-combined'}
                            angle={combinedAngle}
                            offsets={{
                                start: 0.1,
                                middle: 0.4,
                                end: 0.9,
                            }}
                            colors={{
                                start: combinedRisk.colors[0],
                                middle: combinedRisk.colors[1],
                                end: combinedRisk.colors[2],
                            }}
                            direction={Direction.HORIZONTAL}
                        />
                        <G
                            rotation={rotationRisksAngle}
                            originX="120"
                            originY="120"
                        >
                            <Circle
                                cx="50%"
                                cy="50%"
                                r={radius}
                                stroke={Colors.lighterGrey}
                                fill="transparent"
                                strokeWidth="15"
                            />
                            <G rotation={0} originX="120" originY="120">
                                <Circle
                                    cx="50%"
                                    cy="50%"
                                    r={radius}
                                    stroke="url(#gradient-flood)"
                                    fill="transparent"
                                    strokeWidth="25"
                                    strokeDasharray={circleCircumference}
                                    strokeDashoffset={floodStrokeDashoffset}
                                    originX="120"
                                    originY="120"
                                    rotation={
                                        droughtAngle +
                                        combinedAngle +
                                        waterRestrictionAngle
                                    }
                                    strokeLinecap="round"
                                />
                                <Circle
                                    cx="50%"
                                    cy="50%"
                                    r={radius}
                                    stroke="url(#gradient-drought)"
                                    fill="transparent"
                                    strokeWidth="25"
                                    strokeDasharray={circleCircumference}
                                    strokeDashoffset={droughtStrokeDashoffset}
                                    originX="120"
                                    originY="120"
                                    rotation={
                                        combinedAngle + waterRestrictionAngle
                                    }
                                    strokeLinecap="round"
                                />
                                <Circle
                                    cx="50%"
                                    cy="50%"
                                    r={radius}
                                    stroke="url(#gradient-waterRestriction)"
                                    fill="transparent"
                                    strokeWidth="25"
                                    strokeDasharray={circleCircumference}
                                    strokeDashoffset={
                                        waterRestrictionStrokeDashoffset
                                    }
                                    originX="120"
                                    originY="120"
                                    rotation={combinedAngle}
                                    strokeLinecap="round"
                                />
                                <Circle
                                    cx="50%"
                                    cy="50%"
                                    r={radius}
                                    stroke="url(#gradient-combined)"
                                    fill="transparent"
                                    strokeWidth="25"
                                    strokeDasharray={circleCircumference}
                                    strokeDashoffset={combinedStrokeDashoffset}
                                    originX="120"
                                    originY="120"
                                    rotation={0}
                                    strokeLinecap="round"
                                />
                            </G>
                        </G>
                    </Svg>
                </View>
                <View style={style.addressesContainer}>
                    <StyledText
                        weight={FontType.BOLD}
                        style={{
                            ...style.analysisNumber,
                            fontSize: fonts.title,
                        }}
                    >
                        {props.analytics?.address_count}
                    </StyledText>
                    <StyledText
                        weight={FontType.SEMIBOLD}
                        style={style.analyzedAddresses}
                    >
                        {props.analytics && props.analytics.address_count > 1
                            ? 'Adresses\nanalysées'
                            : 'Adresse\nanalysée'}
                    </StyledText>
                </View>
            </View>
            <HighRisksChartLegend riskRepartition={riskRepartition} />
        </View>
    )
}

export default HighRisksChart
