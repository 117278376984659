import { StyleSheet } from 'react-native'

const style = StyleSheet.create({
    category: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    categoryLabel: {
        color: '#272927',
        fontSize: 24,
        marginTop: 3,
        marginBottom: 8,
    },
    categoryDetails: {
        color: '#272927',
        fontSize: 14,
        lineHeight: 20,
        maxWidth: 264,
        weight: 400,
        textAlign: 'center',
    },
})

export default style
