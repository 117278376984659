import { Summary } from 'domain/progress_bar_rules'
import { droughtRisk } from 'domain/types/risk_type'
import React, { LegacyRef, forwardRef } from 'react'
import { View } from 'react-native'
import { ICON_COMPONENTS } from 'shared/assets/icons'
import useWindow from 'shared/hooks/useWindow'
import MainRiskIndicator from '../RiskSection/MainRiskIndicator/MainRiskIndicator'
import RiskOccurences from '../RiskSection/RiskOccurences/RiskOccurences'
import SubRiskIndicator from '../RiskSection/SubRiskIndicator/SubRiskIndicator'
import Separator, { Direction } from '../Separator/Separator'
import style from './DroughtRiskSection.style'
import { IconSize } from 'shared/styles/icon_enum'
import Colors from 'shared/styles/colors_enum'
import { useFormatMessage } from 'i18n/hooks'
import { FontType } from 'shared/styles/font_enums'
import StyledText from '../StyledText/StyledText'

type Props = {
    summary: Summary
    zIndex?: number
}

const DroughtRiskSection: React.FC<Props> = forwardRef((props: Props, ref) => {
    const t = useFormatMessage()
    const { summary } = props
    const drougthInfos = t({
        defaultMessage:
            'La sécheresse est un déficit anormal, sur une période prolongée, d’une des composantes du cycle de l’eau.',
    })
    const H1A2mtDtlInfos = t({
        defaultMessage:
            'Elle correspond à un déficit prolongé de précipitations.',
    })
    const H1A2slDtlInfos = t({
        defaultMessage:
            'Liée à une sécheresse des sols, elle impacte directement la production agricole.  Elle résulte d’un manque d’eau disponible dans le sol pour la flore, ce qui impacte toute la production végétale, et indirectement la production animale.',
    })
    const rgargInfos = t({
        defaultMessage:
            'Les terrains argileux superficiels peuvent voir leur volume varier en fonction de leur teneur en eau, en lien avec les conditions météorologiques. Ils se « rétractent » lors des périodes de sécheresse (« retrait ») et gonflent au retour des pluies lorsqu’ils sont de nouveau hydratés (« gonflement »). Ces variations sont lentes, mais elles peuvent atteindre une amplitude assez importante pour endommager les bâtiments localisés sur ces terrains.',
    })
    const { isSmallWindow } = useWindow()

    return (
        <View
            testID="drought"
            style={{ zIndex: props.zIndex }}
            ref={ref as LegacyRef<View>}
        >
            <View style={style.titleRow}>
                <StyledText weight={FontType.BOLD} style={style.title}>
                    {droughtRisk.label}
                </StyledText>
            </View>
            <View style={style.mainRisk}>
                <MainRiskIndicator
                    colors={droughtRisk.colors}
                    description={drougthInfos}
                    severity={summary.global}
                    zIndex={1}
                />
            </View>
            <View
                style={[
                    style.content,
                    style.container,
                    isSmallWindow && { flexDirection: 'column' },
                ]}
            >
                <View style={[!isSmallWindow && { flex: 1 }, { zIndex: 2 }]}>
                    <SubRiskIndicator
                        title={
                            t({
                                defaultMessage:
                                    'Sécheresse météo (horizon 35 ans)',
                            }) as string
                        }
                        severity={summary.subRisks.H1A2mtDtL}
                        colors={droughtRisk.colors}
                        icon={ICON_COMPONENTS.temperatureSunlight({
                            color: Colors.droughtOrange,
                            size: IconSize.twentyFour,
                        })}
                        infos={H1A2mtDtlInfos}
                        zIndex={4}
                    />
                    <Separator direction={Direction.HORIZONTAL} />
                    <SubRiskIndicator
                        title={
                            t({
                                defaultMessage:
                                    'Sécheresse agricole (horizon 35 ans)',
                            }) as string
                        }
                        severity={summary.subRisks.H1A2slDtL}
                        colors={droughtRisk.colors}
                        icon={ICON_COMPONENTS.wheat({
                            color: Colors.droughtOrange,
                            size: IconSize.twentyFour,
                        })}
                        infos={H1A2slDtlInfos}
                        zIndex={3}
                    />
                    <Separator direction={Direction.HORIZONTAL} />
                    <SubRiskIndicator
                        title={
                            t({
                                defaultMessage:
                                    'Retrait-gonflement des argiles',
                            }) as string
                        }
                        severity={summary.subRisks.rgargs}
                        colors={droughtRisk.colors}
                        icon={ICON_COMPONENTS.arrowMaximize({
                            color: Colors.droughtOrange,
                            size: IconSize.twentyFour,
                        })}
                        infos={rgargInfos}
                        zIndex={2}
                    />
                </View>
                <Separator direction={Direction.VERTICAL} />
                <View style={{ flex: 1 }}>
                    <RiskOccurences // FIXME: Use catnats values from API
                        precisions={[
                            `${3} ${
                                t({
                                    defaultMessage: 'Mouvements de terrain',
                                }) as string
                            }`,
                        ]}
                        occurences={3}
                        title={
                            t({
                                defaultMessage:
                                    'Arrêtés "Catastrophe Naturelle"',
                            }) as string
                        }
                        zIndex={1}
                    />
                </View>
            </View>
        </View>
    )
})
DroughtRiskSection.displayName = 'DroughtRiskSection'

export default DroughtRiskSection
