import { View } from 'react-native'
import RiskOccurences from '../RiskSection/RiskOccurences/RiskOccurences'
import { TownRisks } from 'domain/types/risk_at_address'
import Separator, { Direction } from '../Separator/Separator'
import useWindow from 'shared/hooks/useWindow'
import style from './TownRisksSection.style'
import StyledText from '../StyledText/StyledText'
import { FontType } from 'shared/styles/font_enums'
import { useFormatMessage } from 'i18n/hooks'

type Props = {
    townRisks: TownRisks
}
const TownRisksSection: React.FC<Props> = (props: Props) => {
    const t = useFormatMessage()

    const { isSmallWindow } = useWindow()
    const uniquePprnLabels = [...new Set(props.townRisks.pprns.labels)]
    const catnatInfos = t({
        defaultMessage:
            'Un arrêté constate l’État de catastrophe naturelle. Il permet l’indemnisation des dommages directement causés aux biens assurés, en vertu de la loi du 13 juillet 1982, relative à l’indemnisation des victimes de catastrophes naturelles.',
    })
    const pcsInfos = t({
        defaultMessage:
            'Il s’agit d’un document qui recense toutes les informations nécessaires à une gestion de crise optimisée.',
    })
    const pprnInfos = t({
        defaultMessage:
            'Le Plan de Prévention des Risques Naturels est un document réalisé par les services de l’État et élaboré sous la responsabilité du Préfet sur des communes qui présentent une vulnérabilité importante vis-à-vis des risques. Ce document de planification réglemente l’utilisation des sols en fonction des risques naturels prévisibles auxquels ils sont soumis. Cette réglementation va de l’interdiction de construire à la possibilité de construire sous certaines conditions, en passant par des mesures de prévention, de protection et de sauvegarde.',
    })

    return (
        <View style={style.container}>
            <StyledText weight={FontType.BOLD} style={style.title}>
                {'Situation dans la commune'}
            </StyledText>
            <View
                style={[
                    style.content,
                    isSmallWindow && { flexDirection: 'column' },
                ]}
            >
                <View
                    style={[
                        style.column,
                        !isSmallWindow && { flex: 1 },
                        { zIndex: 20 },
                    ]}
                >
                    <RiskOccurences
                        infos={catnatInfos}
                        precisions={[]}
                        title={'Arrêtés "Catastrophe Naturelle"'}
                        occurences={props.townRisks.catnat_count}
                        zIndex={20}
                    />
                    <Separator direction={Direction.HORIZONTAL} />
                    <RiskOccurences
                        precisions={[]}
                        title={'Plan communal de sauvegarde'}
                        occurences={0}
                        infos={pcsInfos}
                        zIndex={3}
                    />
                </View>
                <Separator direction={Direction.VERTICAL} />
                <View
                    style={[
                        style.column,
                        !isSmallWindow && { flex: 1 },
                        { zIndex: 10 },
                    ]}
                >
                    <RiskOccurences
                        precisions={uniquePprnLabels}
                        title={'PPRN'}
                        occurences={props.townRisks.pprns.count}
                        infos={pprnInfos}
                        zIndex={1}
                    />
                </View>
            </View>
            <Separator
                extraStyle={{
                    marginTop: 0,
                }}
                direction={Direction.HORIZONTAL}
            />
        </View>
    )
}

export default TownRisksSection
