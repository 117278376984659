import Svg, { Path } from 'react-native-svg'
import { DEFAULT_ICON_PROPS, IconProps } from '../icon.types'

const IconComponent: React.FunctionComponent<IconProps> = function ({
    size = DEFAULT_ICON_PROPS.size,
    color = DEFAULT_ICON_PROPS.color,
}: IconProps) {
    return (
        <Svg width={size} height={size} fill={color} viewBox="0 0 24 25">
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.994.5a4.126 4.126 0 0 0-4.022 3.21 5.708 5.708 0 0 0-1.265-1.823l-.792-.792a1.12 1.12 0 0 0-1.59 0l-.791.792a5.217 5.217 0 0 0-1.458 2.803 1.121 1.121 0 0 0-1.458.113l-.792.792A5.217 5.217 0 0 0 7.37 8.398a1.121 1.121 0 0 0-1.458.112l-.792.792a5.253 5.253 0 0 0 0 7.425l.53.53-5.32 5.324a1.124 1.124 0 0 0 1.589 1.589l5.329-5.334.525.525a5.253 5.253 0 0 0 7.424 0l.792-.793a1.121 1.121 0 0 0 .113-1.457 5.217 5.217 0 0 0 2.802-1.458l.793-.792a1.121 1.121 0 0 0 .112-1.458 5.217 5.217 0 0 0 2.803-1.457l.792-.792a1.12 1.12 0 0 0 0-1.59l-.792-.791a5.978 5.978 0 0 0-1.823-1.252A4.122 4.122 0 0 0 23.994 3.5V1.625c0-.623-.502-1.125-1.125-1.125h-1.875Zm-1.875 4.125c0-1.036.839-1.875 1.875-1.875h.75v.75a1.874 1.874 0 0 1-1.875 1.875h-.75v-.75Zm-4.987 3.088.473.474a3.748 3.748 0 0 0-.478-4.715 2.999 2.999 0 0 0 0 4.241h.005Zm-3.239 4.181-.473-.473h-.005a2.999 2.999 0 0 1 0-4.242 3.748 3.748 0 0 1 .478 4.715Zm-4.185 3.239.473.473a3.748 3.748 0 0 0-.478-4.715 2.999 2.999 0 0 0 0 4.242h.005Zm2.175 2.17a3.755 3.755 0 0 1 4.715.478 2.999 2.999 0 0 1-4.242 0l-.473-.478Zm8.427-3.234-.002-.002.002-.003v.005Zm-.002-.002a2.998 2.998 0 0 1-4.24-.003l-.473-.473a3.755 3.755 0 0 1 4.713.476Zm-1.006-4.184a3.76 3.76 0 0 1 4.72.474h-.005a2.999 2.999 0 0 1-4.241 0l-.474-.474Z"
            />
        </Svg>
    )
}

export default IconComponent
