import { useIsFocused, useNavigation } from '@react-navigation/native'
import { Analytics } from 'domain/analytics_service'
import { risks } from 'domain/types/risk_type'
import HttpError from 'infrastructure/http/http_error'
import React, { useCallback, useEffect, useState } from 'react'
import { ActivityIndicator, View } from 'react-native'
import useAuth from 'shared/hooks/authentication/useAuth'
import useServices from 'shared/hooks/useServices'
import useWindow from 'shared/hooks/useWindow'
import Colors from 'shared/styles/colors_enum'
import MainRiskIndicator from 'shared/components/RiskSection/MainRiskIndicator/MainRiskIndicator'
import Separator, { Direction } from 'shared/components//Separator/Separator'
import style from './Summary.style'
import HighRisksChart from 'shared/components/HighRisksChart/HighRisksChart'
import { useFormatMessage } from 'i18n/hooks'
import { FontType } from 'shared/styles/font_enums'
import StyledText from 'shared/components/StyledText/StyledText'
import RedirectButton from 'shared/components/RedirectButton/RedirectButton'
import { StackNavigationProp } from '@react-navigation/stack'
import { ScreenStackList } from 'navigation/types'
import Screens from 'screens/screens'
import {
    ILLUSTRATION_COMPONENTS,
    IllustrationSize,
} from 'shared/assets/illustration'

const Summary: React.FC<unknown> = () => {
    const navigation =
        useNavigation<StackNavigationProp<ScreenStackList, Screens>>()

    const t = useFormatMessage()
    const [analytics, setAnalytics] = useState<Analytics | undefined>(undefined)
    const [isLoading, setIsLoading] = useState<boolean>(true)

    const { analyticsService } = useServices()
    const { isSmallWindow } = useWindow()
    const { resetUser } = useAuth()
    const isFocused = useIsFocused()

    const getAnalytics = useCallback(async () => {
        try {
            const data = await analyticsService.get()
            setAnalytics(data)
            setIsLoading(false)
        } catch (error: unknown) {
            if (error instanceof HttpError && error.status_code == 403) {
                resetUser()
            }
            setAnalytics(undefined)
            setIsLoading(false)
        }
    }, [analytics])

    const handleOnMyAddresses = async () => {
        navigation.navigate(Screens.FavoriteAddresses)
    }

    useEffect(() => {
        if (isFocused) {
            getAnalytics()
        }
    }, [isFocused])

    if (isLoading) {
        return <ActivityIndicator color={Colors.indigo} size="large" />
    }

    return (
        <View style={style.container}>
            <StyledText weight={FontType.EXTRABOLD} style={style.title}>
                {t({ defaultMessage: 'Mes adresses' })}
            </StyledText>
            <Separator direction={Direction.HORIZONTAL} />
            {analytics ? (
                <>
                    <View
                        style={[
                            style.subContainer,
                            isSmallWindow && {
                                flexDirection: 'column-reverse',
                            },
                        ]}
                    >
                        <View style={style.section}>
                            <StyledText
                                weight={FontType.BOLD}
                                style={style.subTitle}
                            >
                                {t({ defaultMessage: 'Répartition du risque' })}
                            </StyledText>
                            <HighRisksChart analytics={analytics} />
                        </View>
                        <Separator
                            direction={
                                isSmallWindow
                                    ? Direction.HORIZONTAL
                                    : Direction.VERTICAL
                            }
                        />
                        <View style={style.section}>
                            <StyledText
                                weight={FontType.BOLD}
                                style={style.subTitle}
                            >
                                {t({ defaultMessage: 'Sévérité globale' })}
                            </StyledText>
                            <StyledText
                                weight={FontType.BOLD}
                                style={style.addresses}
                            >
                                {analytics.address_count > 1
                                    ? `${analytics.address_count} adresses`
                                    : `${analytics.address_count} adresse`}
                            </StyledText>
                            <View style={style.risksContainer}>
                                {Object.keys(analytics.summary).map(
                                    (riskName: string) => (
                                        <View
                                            key={riskName}
                                            style={style.riskContainer}
                                        >
                                            <StyledText
                                                weight={FontType.BOLD}
                                                style={style.riskLabel}
                                            >
                                                {risks[riskName].label}
                                            </StyledText>
                                            <View style={style.riskChart}>
                                                <MainRiskIndicator
                                                    colors={
                                                        risks[riskName].colors
                                                    }
                                                    severity={
                                                        analytics.summary[
                                                            riskName
                                                        ]
                                                    }
                                                    infos={
                                                        risks[riskName]
                                                            .description
                                                    }
                                                    showTitle={false}
                                                />
                                            </View>
                                        </View>
                                    )
                                )}
                            </View>
                        </View>
                    </View>
                    <View style={style.myAddressesContainer}>
                        <RedirectButton
                            onPress={handleOnMyAddresses}
                            label="MES ADRESSES"
                        />
                    </View>
                </>
            ) : (
                <View style={style.noAddressesContainer}>
                    {ILLUSTRATION_COMPONENTS.notepad({
                        size: IllustrationSize.twoHundred,
                        idBaseName: 'Summary',
                    })}
                    <View style={style.noAddressesTextContainer}>
                        <StyledText style={style.noAddressesText}>
                            {
                                t({
                                    defaultMessage:
                                        "Retrouvez ici la répartition des risques de votre carnet d'adresses",
                                }) as string
                            }
                        </StyledText>
                    </View>
                </View>
            )}
        </View>
    )
}

export default Summary
