import React, { useState } from 'react'
import { View, Pressable } from 'react-native'
import style from './RiskTooltip.style'
import { ICON_COMPONENTS } from 'shared/assets/icons'
import { IconSize } from 'shared/styles/icon_enum'
import Colors from 'shared/styles/colors_enum'
import StyledText from '../StyledText/StyledText'

type Props = {
    content: string
}

const RiskTooltip: React.FC<Props> = (props: Props) => {
    const [isHoveredOn, setIsHoveredOn] = useState<boolean>(false)

    return (
        <View style={style.container}>
            <Pressable
                accessibilityRole="button"
                testID="tooltipButton"
                onHoverIn={() => setIsHoveredOn(true)}
                onHoverOut={() => setIsHoveredOn(false)}
            >
                {ICON_COMPONENTS.info({
                    size: IconSize.twenty,
                    color: isHoveredOn ? Colors.hoverGrey : Colors.lightGrey3,
                })}
            </Pressable>
            {isHoveredOn ? (
                <View style={style.content}>
                    <StyledText style={style.text}>{props.content}</StyledText>
                </View>
            ) : null}
        </View>
    )
}

export default RiskTooltip
