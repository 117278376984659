import { RiskSeverity } from './types/risk_severity'
import { RiskSeverities } from './types/risk_severity'

export interface ProgressBarRules {
    build(value: number | null, levels: Levels): RiskSeverity
    summarize(infos: RiskInformation): Summary
}

interface Levels {
    nearlyNonExistent?: number
    veryLow?: number
    low: number
    medium: number
    strong: number
}

enum SubRisks {
    exzeco = 'exzeco',
    ppris = 'ppris',
    remnas = 'remnas',
    waterlevels = 'waterlevels',
    H1A2mtDtL = 'H1A2mtDtL',
    H1A2slDtL = 'H1A2slDtL',
    rgargs = 'rgargs',
    surfrestris = 'surfrestris',
    undrestris = 'undrestris',
}

export type RiskInformation = {
    [key: string]: {
        value: number | null
        levels: Levels
    }
}

export interface Summary {
    global: RiskSeverity
    subRisks: {
        [key: string]: RiskSeverity
    }
}
export const SubRiskRules = {
    PPRIS: {
        veryLow: 0,
        low: 1,
        medium: 2,
        strong: 3,
    },
    EXZECO: {
        veryLow: 0,
        low: 1,
        medium: 3,
        strong: 5,
    },
    REMNAS: {
        veryLow: 0,
        low: 1,
        medium: 3,
        strong: 4,
    },
    WATERLEVELS: {
        nearlyNonExistent: 0,
        low: 1,
        medium: 2,
        strong: 3,
    },
    H1A2: {
        low: 0,
        medium: 2,
        strong: 3,
    },
    RGARGL: {
        low: 1,
        medium: 2,
        strong: 3,
    },
    SURFRESTRIS: {
        veryLow: 0,
        low: 1,
        medium: 3,
        strong: 4,
    },
    UNDRESTRIS: {
        veryLow: 0,
        low: 1,
        medium: 3,
        strong: 4,
    },
}

export class ProgressBarRulesImpl implements ProgressBarRules {
    build(value: number | null, levels: Levels): RiskSeverity {
        if (typeof value === 'number') {
            if (value >= levels.strong) {
                return RiskSeverities.strong
            } else if (value >= levels.medium) {
                return RiskSeverities.medium
            } else if (value >= levels.low) {
                return RiskSeverities.low
            } else if (
                typeof levels.veryLow === 'number' &&
                value >= levels.veryLow
            ) {
                return RiskSeverities.veryLow
            } else if (
                typeof levels.nearlyNonExistent === 'number' &&
                value >= levels.nearlyNonExistent
            ) {
                return RiskSeverities.nearlyNonExistent
            }
        }
        return RiskSeverities.noData
    }

    summarize(infos: RiskInformation): Summary {
        const summary: Summary = {
            global: RiskSeverities.noData,
            subRisks: {},
        }
        Object.entries(infos).map(([riskType, riskValue]) => {
            const severity = this.build(riskValue.value, riskValue.levels)
            if (!SubRisks[riskType as keyof typeof SubRisks])
                throw new Error(
                    `The given ${riskType} key does not match any type of risk.`
                )
            summary.subRisks[riskType] = severity
            if (summary.global.progress < severity.progress) {
                summary.global = severity
            }
        })
        return summary
    }
}
