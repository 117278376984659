import { StyleSheet } from 'react-native'
import Colors from 'shared/styles/colors_enum'
import { FontSize } from 'shared/styles/font_enums'

const style = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        minHeight: 40,
        paddingHorizontal: 10,
    },
    button: {
        flexDirection: 'row',
        alignItems: 'center',
        flex: 1,
    },
    addressLabel: {
        flex: 1,
        marginLeft: 20,
        color: Colors.customBlack,
        fontSize: FontSize.fourteen,
    },
    analysisButton: {
        backgroundColor: Colors.indigo,
        borderRadius: 20,
        paddingHorizontal: 20,
        paddingVertical: 7,
        color: Colors.white,
        fontSize: FontSize.fourteen,
    },
})

export default style
