import Svg, { Path } from 'react-native-svg'
import { DEFAULT_ICON_PROPS, IconProps } from '../icon.types'

const IconComponent: React.FC<IconProps> = function ({
    size = DEFAULT_ICON_PROPS.size,
    color = DEFAULT_ICON_PROPS.color,
}: IconProps) {
    return (
        <Svg
            width={size}
            height={size}
            fill={color}
            viewBox="0 0 39.276 39.276"
        >
            <Path d="M38.563 11.406a2.5 2.5 0 0 0-3.406-.941l-5.065 2.873c-2.788-5.432-6.063-10.594-7.103-12.195a2.502 2.502 0 0 0-4.2 0C16.873 4.1 7.318 19.188 7.318 25.708c0 .181.012.354.02.531l-5.684 3.226a2.499 2.499 0 0 0 1.236 4.674c.418 0 .841-.104 1.231-.324l33.5-19a2.499 2.499 0 0 0 .942-3.409zM20.888 7.212c1.617 2.667 3.352 5.709 4.833 8.601l-12.876 7.304c1.319-4.129 4.905-10.73 8.043-15.905zM33.33 20.598c.696 1.957 1.128 3.729 1.128 5.11 0 7.479-6.087 13.568-13.569 13.568-4.442 0-8.38-2.156-10.857-5.466l4.396-2.493a8.542 8.542 0 0 0 6.461 2.959c4.727 0 8.569-3.845 8.569-8.568 0-.668-.197-1.563-.533-2.612l4.405-2.498z" />
        </Svg>
    )
}

export default IconComponent
