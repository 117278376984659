import { useNavigation } from '@react-navigation/native'
import { Address } from 'domain/types/address'
import HttpError from 'infrastructure/http/http_error'
import React, { useEffect, useState } from 'react'
import {
    ImageBackground,
    Pressable,
    View,
    TouchableOpacity,
} from 'react-native'
import Screens from 'screens/screens'
import { ICONS, ICON_COMPONENTS } from 'shared/assets/icons'
import useAuth from 'shared/hooks/authentication/useAuth'
import useServices from 'shared/hooks/useServices'
import topography from 'shared/styles/topography.style'
import style from './AddressDetails.style'
import { ScreenStackList } from 'navigation/types'
import { StackNavigationProp } from '@react-navigation/stack'
import ConfirmModal from '../ConfirmModal/ConfirmModal'
import FavoriteAddressButton from './FavoriteAddressButton/FavoriteAddressButton'
import { useFormatMessage } from 'i18n/hooks'
import { IconSize } from 'shared/styles/icon_enum'
import useSnackbar from 'shared/hooks/snackbars/useSnackbar'
import { SnackMessageType } from 'shared/contexts/SnackbarContext'
import Colors from 'shared/styles/colors_enum'
import StyledText from '../StyledText/StyledText'
import { FontType } from 'shared/styles/font_enums'

type Props = {
    searchedAddress?: Address
}

const AddressDetails: React.FC<Props> = (props: Props) => {
    const t = useFormatMessage()
    const { addSnack } = useSnackbar()
    const { resetUser } = useAuth()
    const navigation =
        useNavigation<StackNavigationProp<ScreenStackList, Screens>>()
    const { favoriteService } = useServices()
    const [favoriteAddressId, setFavoriteAddressId] = useState<
        number | undefined
    >(undefined)
    const [isFavorite, setIsFavorite] = useState<boolean>(false)
    const [isDeleting, setIsDeleting] = useState<boolean>(false)
    const errorMessages = {
        addFavorite: t({
            defaultMessage:
                "Cette adresse n'a pas pu être ajoutée à vos favoris.",
        }) as string,
        deleteFavorite: t({
            defaultMessage:
                "Cette adresse n'a pas pu être supprimée de vos favoris.",
        }) as string,
    }
    const [showModal, setShowModal] = useState<boolean>(false)

    const handleFavoriteAddress = async () => {
        const interopKey = props.searchedAddress?.interopkey

        if (interopKey) {
            try {
                const response = await favoriteService.get(interopKey)
                setFavoriteAddressId(response?.favoriteAddressId)
                setIsFavorite(response !== undefined)
            } catch (error: unknown) {
                if (error instanceof HttpError && error.status_code == 403) {
                    setIsFavorite(false)
                    resetUser()
                }
            }
        }
    }

    useEffect(() => {
        handleFavoriteAddress()
    }, [])

    const handleRedirection = (screen: Screens) => {
        navigation.navigate(screen)
    }

    const onAddFavoriteAddress = async () => {
        const interopKey = props.searchedAddress?.interopkey

        if (interopKey) {
            try {
                const responseId = await favoriteService.save(interopKey)
                setIsFavorite(true)
                setFavoriteAddressId(responseId)
            } catch (error: unknown) {
                if (error instanceof HttpError && error.status_code == 403) {
                    resetUser()
                } else {
                    addSnack(errorMessages.addFavorite, SnackMessageType.ERROR)
                }
            }
        }
    }

    const onDeleteFavoriteAddress = async () => {
        try {
            if (favoriteAddressId) {
                await favoriteService.remove(favoriteAddressId)
            }
            setIsFavorite(false)
            setFavoriteAddressId(undefined)
            setShowModal(false)
            setIsDeleting(false)
        } catch (error: unknown) {
            if (error instanceof HttpError && error.status_code == 403) {
                resetUser()
            } else {
                setIsDeleting(false)
                setShowModal(false)
                addSnack(errorMessages.deleteFavorite, SnackMessageType.ERROR)
            }
        }
    }

    return (
        <View style={style.container}>
            <ImageBackground
                style={topography.background}
                source={ICONS.topography}
            />
            <View style={style.header}>
                <View style={style.backButton}>
                    <TouchableOpacity
                        testID="backButton"
                        onPress={() => navigation.goBack()}
                        style={style.link}
                    >
                        {ICON_COMPONENTS.arrowBack({
                            color: Colors.indigo,
                            size: IconSize.twentyFour,
                        })}
                    </TouchableOpacity>
                    <StyledText style={style.headerTitle}>
                        {
                            t({
                                defaultMessage: "Analyse de l'adresse",
                            }) as string
                        }
                    </StyledText>
                </View>
                <View style={style.actions}>
                    <Pressable
                        onPress={() => handleRedirection(Screens.Dashboard)}
                        style={style.link}
                    >
                        {ICON_COMPONENTS.share({
                            color: Colors.indigo,
                            size: IconSize.twenty,
                        })}
                        <StyledText
                            weight={FontType.SEMIBOLD}
                            style={style.linkText}
                        >
                            {t({ defaultMessage: 'PARTAGER' }) as string}
                        </StyledText>
                    </Pressable>
                    <TouchableOpacity
                        onPress={() => handleRedirection(Screens.Dashboard)}
                        style={{ ...style.link, ...style.linkMargin }}
                    >
                        {ICON_COMPONENTS.file({
                            color: Colors.indigo,
                            size: IconSize.twenty,
                        })}
                        <StyledText
                            weight={FontType.SEMIBOLD}
                            style={style.linkText}
                        >
                            {t({ defaultMessage: 'RAPPORT CLIENT' }) as string}
                        </StyledText>
                    </TouchableOpacity>
                    <FavoriteAddressButton
                        onAdd={onAddFavoriteAddress}
                        onDelete={() => {
                            setIsDeleting(true)
                            setShowModal(true)
                        }}
                        isSavedAsFavorite={isFavorite}
                        isDeleting={isDeleting}
                    />
                </View>
            </View>
            <StyledText weight={FontType.BOLD} style={style.address}>
                {props.searchedAddress?.label || ''}
            </StyledText>
            <ConfirmModal
                onConfirm={onDeleteFavoriteAddress}
                visible={showModal}
                onCancel={() => {
                    setShowModal(false)
                    setIsDeleting(false)
                }}
            >
                <StyledText weight={FontType.BOLD} style={style.modalTitle}>
                    {t({ defaultMessage: "Supprimer l'addresse" }) as string}
                </StyledText>
                <View style={style.modalTextContainer}>
                    <StyledText weight={FontType.LIGHT} style={style.modalText}>
                        {"Êtes-vous sûr(e) de vouloir supprimer l'adresse "}
                    </StyledText>
                    <StyledText
                        weight={FontType.SEMIBOLD}
                        style={style.modalAddress}
                    >
                        {props.searchedAddress?.label || ''}
                    </StyledText>
                    <StyledText weight={FontType.LIGHT} style={style.modalText}>
                        {' de vos favoris ?'}
                    </StyledText>
                </View>
            </ConfirmModal>
        </View>
    )
}

export default AddressDetails
