import { RouteProp, useRoute } from '@react-navigation/native'
import { paramsToAddress } from 'domain/query_params'
import { Address } from 'domain/types/address'
import { RisksAtAddress } from 'domain/types/risk_at_address'
import HttpError from 'infrastructure/http/http_error'
import { ScreenStackList } from 'navigation/types'
import { RefObject, useCallback, useEffect, useRef, useState } from 'react'
import { ActivityIndicator, ScrollView } from 'react-native'
import Screens from 'screens/screens'
import AddressDetails from 'shared/components/AddressDetails/AddressDetails'
import DroughtRiskSection from 'shared/components/DroughtRiskSection/DroughtRiskSection'
import FloodRiskSection from 'shared/components/FloodRiskSection/FloodRiskSection'
import RisksSynthesis from 'shared/components/RisksSynthesis/RisksSynthesis'
import Separator, { Direction } from 'shared/components/Separator/Separator'
import TownRisksSection from 'shared/components/TownRisksSection/TownRisksSection'
import WaterRestrictionsSection from 'shared/components/WaterRestrictionsSection/WaterRestrictionsSection'
import useAuth from 'shared/hooks/authentication/useAuth'
import useServices from 'shared/hooks/useServices'
import useWindow from 'shared/hooks/useWindow'
import Colors from 'shared/styles/colors_enum'
import { Margin } from 'shared/styles/layout'
import style from 'shared/styles/Screen.style'
import { adaptWidth } from 'shared/styles/window'

const AddressAnalysis: React.FC<unknown> = () => {
    const flood = useRef<HTMLElement>(null)
    const drought = useRef<HTMLElement>(null)
    const waterRestrictions = useRef<HTMLElement>(null)
    const refs: Record<string, RefObject<null | HTMLElement>> = {
        flood,
        drought,
        waterRestrictions,
    }
    const [risksAtAddress, setRisksAtAddress] = useState<RisksAtAddress>()
    const { params } =
        useRoute<RouteProp<ScreenStackList, Screens.AddressAnalysis>>()
    const { isSmallWindow, isWeb } = useWindow()
    const { resetUser } = useAuth()
    const { riskService } = useServices()
    const addressInformations = params?.addressInformations || undefined
    const favoriteAddressId = params?.favoriteAddressId || undefined
    const [isLoading, setIsLoading] = useState<boolean>(true)

    const getAddressAnalysis = useCallback(
        async (addressIdentifier: number) => {
            try {
                const data =
                    await riskService.getLastAnalysisForFavoriteAddress(
                        addressIdentifier
                    )
                setRisksAtAddress(data)
                setIsLoading(false)
            } catch (error: unknown) {
                if (error instanceof HttpError && error.status_code == 403) {
                    resetUser()
                }
            }
        },
        []
    )

    const getRisksAtAddress = useCallback(async (address: Address) => {
        try {
            const data = await riskService.get(address)
            setRisksAtAddress(data)
            setIsLoading(false)
        } catch (error: unknown) {
            if (error instanceof HttpError && error.status_code == 403) {
                resetUser()
            }
        }
    }, [])

    useEffect(() => {
        if (addressInformations) {
            getRisksAtAddress(paramsToAddress(addressInformations))
        } else if (favoriteAddressId) {
            getAddressAnalysis(favoriteAddressId)
        }
        return () => {
            setRisksAtAddress(undefined)
            setIsLoading(true)
        }
    }, [addressInformations, favoriteAddressId])

    useEffect(() => {
        if (params?.anchor) {
            refs[params.anchor]?.current?.scrollIntoView({
                behavior: 'smooth',
            })
        }
    }, [params?.anchor])

    if (isLoading) {
        return <ActivityIndicator color={Colors.indigo} size="large" />
    }

    return (
        <ScrollView
            style={[style.screen, adaptWidth(isSmallWindow, isWeb)]}
            contentContainerStyle={style.content}
        >
            {risksAtAddress ? (
                <>
                    <AddressDetails searchedAddress={risksAtAddress.address} />
                    <RisksSynthesis summary={risksAtAddress.summary} />
                    <TownRisksSection townRisks={risksAtAddress.town} />
                    <FloodRiskSection
                        data={risksAtAddress.risks.flood}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        ref={flood}
                        summary={risksAtAddress.summary.flood}
                        zIndex={3}
                    />
                    <Separator
                        extraStyle={{
                            marginHorizontal: Margin.thirtySix,
                        }}
                        direction={Direction.HORIZONTAL}
                    />
                    <DroughtRiskSection
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        ref={drought}
                        summary={risksAtAddress.summary.drought}
                        zIndex={2}
                    />
                    <Separator
                        extraStyle={{
                            marginHorizontal: Margin.thirtySix,
                        }}
                        direction={Direction.HORIZONTAL}
                    />
                    <WaterRestrictionsSection
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        ref={waterRestrictions}
                        summary={risksAtAddress.summary.waterRestrictions}
                        zIndex={1}
                    />
                </>
            ) : null}
        </ScrollView>
    )
}

export default AddressAnalysis
