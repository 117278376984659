import { Platform, StyleSheet } from 'react-native'
import Colors from 'shared/styles/colors_enum'
import { FontSize, LetterSpacing } from 'shared/styles/font_enums'
import { Margin, Padding } from 'shared/styles/layout'

const style = StyleSheet.create({
    container: {
        flex: 2,
        padding: Padding.thirtySix,
        ...Platform.select({
            ios: {
                flex: 0,
            },
        }),
    },
    noAddressesContainer: {
        flex: 1,
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
    },
    noAddressesTextContainer: {
        marginVertical: Margin.thirtyTwo,
    },
    title: {
        color: Colors.grey3,
        fontSize: FontSize.twentyFour,
        textAlign: 'center',
    },
    text: {
        color: Colors.customBlack,
        fontSize: FontSize.fourteen,
        textAlign: 'center',
        marginVertical: Margin.eight,
    },
    button: {
        paddingHorizontal: Padding.thirty,
        height: 40,
        backgroundColor: Colors.indigo,
        borderRadius: 33,
        justifyContent: 'center',
    },
    buttonText: {
        textAlign: 'center',
        color: Colors.white,
        fontSize: FontSize.twelve,
        letterSpacing: LetterSpacing.small,
    },
})

export default style
