import { ReactNode } from 'react'
import { Text } from 'react-native'
import { FontType } from 'shared/styles/font_enums'

type Props = {
    testID?: string
    weight?: FontType
    italic?: boolean
    style?: object
    children: ReactNode
}

const StyledFont = {
    [FontType.EXTRALIGHT]: {
        defaultFamily: 'plus-jakarta-sans-extraLight',
        italicFamily: 'plus-jakarta-sans-extraLightItalic',
    },
    [FontType.LIGHT]: {
        defaultFamily: 'plus-jakarta-sans-light',
        italicFamily: 'plus-jakarta-sans-lightItalic',
    },
    [FontType.MEDIUM]: {
        defaultFamily: 'plus-jakarta-sans-medium',
        italicFamily: 'plus-jakarta-sans-mediumItalic',
    },
    [FontType.REGULAR]: {
        defaultFamily: 'plus-jakarta-sans-regular',
        italicFamily: 'plus-jakarta-sans-italic',
    },
    [FontType.SEMIBOLD]: {
        defaultFamily: 'plus-jakarta-sans-semiBold',
        italicFamily: 'plus-jakarta-sans-semiBoldItalic',
    },
    [FontType.BOLD]: {
        defaultFamily: 'plus-jakarta-sans-bold',
        italicFamily: 'plus-jakarta-sans-boldItalic',
    },
    [FontType.EXTRABOLD]: {
        defaultFamily: 'plus-jakarta-sans-extraBold',
        italicFamily: 'plus-jakarta-sans-extraBoldItalic',
    },
}

const StyledText: React.FC<Props> = (props: Props) => {
    const weight = props.weight || FontType.REGULAR
    const isItalic = props.italic || false

    const baseStyle = {
        fontFamily: isItalic
            ? StyledFont[weight].italicFamily
            : StyledFont[weight].defaultFamily,
        fontStyle: isItalic ? ('italic' as const) : ('normal' as const),
    }

    return (
        <Text style={{ ...baseStyle, ...props.style }} testID={props.testID}>
            {props.children}
        </Text>
    )
}

export default StyledText
