import { StyleSheet } from 'react-native'
import Colors from 'shared/styles/colors_enum'
import { FontSize } from 'shared/styles/font_enums'
import { Padding } from 'shared/styles/layout'

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        marginBottom: 16,
    },
    content: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 8,
        padding: Padding.sixteen,
        borderRadius: 4,
        outlineStyle: 'none',
        minWidth: '33%',
        platform: {
            ios: {
                width: '100%',
            },
            android: {
                width: '100%',
            },
        },
    },
    textContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 8,
    },
    text: {
        color: Colors.customBlack,
        fontSize: FontSize.eighteen,
    },
})

export default styles
