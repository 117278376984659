import { StyleSheet } from 'react-native'
import Colors from 'shared/styles/colors_enum'
import { FontSize, LetterSpacing } from 'shared/styles/font_enums'

const style = StyleSheet.create({
    saveButton: {
        borderRadius: 100,
        borderColor: Colors.indigo,
        borderWidth: 1,
        minWidth: 100,
        paddingHorizontal: 16,
        height: 40,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    saveButtonText: {
        color: Colors.indigo,
        fontSize: FontSize.twelve,
        letterSpacing: LetterSpacing.small,
        marginHorizontal: 8,
    },
})

export default style
