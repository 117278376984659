import { AxiosInstance } from 'axios'

class HttpClient {
    client: AxiosInstance

    constructor(client: AxiosInstance) {
        this.client = client
    }
}

export default HttpClient
