import Svg, { Defs, LinearGradient, Path, Stop } from 'react-native-svg'
import {
    DEFAULT_ILLUSTRATION_PROPS,
    IllustrationProps,
} from 'shared/assets/illustration'

const IllustrationComponent: React.FC<IllustrationProps> = function ({
    size = DEFAULT_ILLUSTRATION_PROPS.size,
    idBaseName = DEFAULT_ILLUSTRATION_PROPS.idBaseName,
}: IllustrationProps) {
    return (
        <Svg width={size} height={size} fill="none" viewBox="0 0 80 80">
            <Path
                fill={`url(#${idBaseName}-a)`}
                d="M1.8 41.277 78.2 10 23.476 49.554 1.801 41.277Z"
            />
            <Path
                fill="#A2A2F8"
                d="m29.684 52.736 8.355 5.837-1.422 1.716-6.928 8.38-.004-12.165-.001-3.768Z"
            />
            <Path
                fill={`url(#${idBaseName}-b)`}
                d="M78.201 10 54.397 70 38.04 58.573l-8.355-5.836 16.76-14.763L78.201 10Z"
            />
            <Path
                fill={`url(#${idBaseName}-c)`}
                d="M78.2 10 46.443 37.974l-16.76 14.763v3.767l.005 12.164-6.213-19.114L78.2 10Z"
            />
            <Defs>
                <LinearGradient
                    id={`${idBaseName}-a`}
                    x1="57.604"
                    x2="7.895"
                    y1="6.506"
                    y2="60.493"
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#F0F8FF" />
                    <Stop offset="1" stopColor="#EDEEFF" />
                </LinearGradient>
                <LinearGradient
                    id={`${idBaseName}-b`}
                    x1="76.51"
                    x2="26.8"
                    y1="23.913"
                    y2="77.9"
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#F0F8FF" />
                    <Stop offset="1" stopColor="#EDEEFF" />
                </LinearGradient>
                <LinearGradient
                    id={`${idBaseName}-c`}
                    x1="23.475"
                    x2="78.2"
                    y1="39.333"
                    y2="39.333"
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#EDEDFF" />
                    <Stop offset=".448" stopColor="#A2A2F8" />
                    <Stop offset=".859" stopColor="#3B3BE2" />
                </LinearGradient>
            </Defs>
        </Svg>
    )
}
export default IllustrationComponent
