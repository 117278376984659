import { Summary } from 'domain/progress_bar_rules'
import { waterRestrictions } from 'domain/types/risk_type'
import React, { LegacyRef, forwardRef } from 'react'
import { View } from 'react-native'
import { ICON_COMPONENTS } from 'shared/assets/icons'
import useWindow from 'shared/hooks/useWindow'
import Colors from 'shared/styles/colors_enum'
import { IconSize } from 'shared/styles/icon_enum'
import MainRiskIndicator from '../RiskSection/MainRiskIndicator/MainRiskIndicator'
import SubRiskIndicator from '../RiskSection/SubRiskIndicator/SubRiskIndicator'
import Separator, { Direction } from '../Separator/Separator'
import style from './WaterRestrictionsSection.style'
import { useFormatMessage } from 'i18n/hooks'
import { FontType } from 'shared/styles/font_enums'
import StyledText from '../StyledText/StyledText'

type Props = {
    summary: Summary
    zIndex?: number
}

const WaterRestrictionsSection: React.FC<Props> = forwardRef(
    (props: Props, ref) => {
        const t = useFormatMessage()
        const { summary } = props
        const tooltipMessage = 'Lorem Ipsum'
        const waterRestrictionsInfos = t({
            defaultMessage:
                'Mesures prises afin de préserver l’état des milieux naturels et de conserver les usages prioritaires.',
        })
        const { isSmallWindow } = useWindow()

        return (
            <View
                style={{ zIndex: props.zIndex }}
                ref={ref as LegacyRef<View>}
                testID="waterRestrictions"
            >
                <View style={style.titleRow}>
                    <StyledText weight={FontType.BOLD} style={style.title}>
                        {waterRestrictions.label}
                    </StyledText>
                </View>
                <View style={style.progressBar}>
                    <MainRiskIndicator
                        colors={waterRestrictions.colors}
                        description={waterRestrictionsInfos}
                        severity={summary.global}
                        zIndex={3}
                    />
                </View>
                <View
                    style={[
                        style.content,
                        style.container,
                        isSmallWindow && { flexDirection: 'column' },
                    ]}
                >
                    <View
                        style={[
                            style.column,
                            !isSmallWindow && { flex: 1 },
                            { zIndex: 1 },
                        ]}
                    >
                        <SubRiskIndicator
                            colors={waterRestrictions.colors}
                            title={
                                t({
                                    defaultMessage:
                                        'Restriction en eaux superficielles',
                                }) as string
                            }
                            severity={summary.subRisks.surfrestris}
                            icon={ICON_COMPONENTS.arrowDownToLine({
                                color: Colors.waterRestrictionsPink,
                                size: IconSize.twentyFour,
                            })}
                            zIndex={2}
                        />
                        <Separator direction={Direction.HORIZONTAL} />
                        <SubRiskIndicator
                            colors={waterRestrictions.colors}
                            title={
                                t({
                                    defaultMessage:
                                        'Restriction en eaux souterraines',
                                }) as string
                            }
                            severity={summary.subRisks.undrestris}
                            icon={ICON_COMPONENTS.arrowUpToLine({
                                color: Colors.waterRestrictionsPink,
                                size: IconSize.twentyFour,
                            })}
                            zIndex={1}
                        />
                    </View>
                    <Separator direction={Direction.VERTICAL} />
                    <View style={{ flex: 1 }}></View>
                </View>
            </View>
        )
    }
)

WaterRestrictionsSection.displayName = 'WaterRestrictionsSection'

export default WaterRestrictionsSection
