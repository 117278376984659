import { FavoriteAddress } from 'domain/types/favorite_address'
import { Pressable, View } from 'react-native'
import { ICON_COMPONENTS } from 'shared/assets/icons'
import style from './AddressElement.style'
import { IconSize } from 'shared/styles/icon_enum'
import Colors from 'shared/styles/colors_enum'
import { useCallback, useState } from 'react'
import { FontType } from 'shared/styles/font_enums'
import StyledText from 'shared/components/StyledText/StyledText'

type Props = {
    address: FavoriteAddress
    showAnalysis: (favoriteAddressId: number) => void
}
const AddressElement: React.FC<Props> = (props: Props) => {
    const address = props.address
    const [isHoveredOn, setIsHoveredOn] = useState<boolean>(false)

    const handleActiveHover = useCallback(() => {
        setIsHoveredOn(true)
    }, [setIsHoveredOn])

    const handleInactiveHover = useCallback(() => {
        setIsHoveredOn(false)
    }, [setIsHoveredOn])

    const handleShowAnalysis = () => {
        if (isHoveredOn) {
            props.showAnalysis(address.favoriteAddressId)
        }
    }

    return (
        <View
            style={{
                ...style.container,
                backgroundColor: isHoveredOn
                    ? Colors.lightGrey2
                    : 'transparent',
            }}
        >
            <Pressable
                style={style.button}
                testID="showFavoriteAddressAnalysis"
                onPress={handleShowAnalysis}
                onHoverIn={handleActiveHover}
                onHoverOut={handleInactiveHover}
            >
                {ICON_COMPONENTS.localizationPin({
                    size: IconSize.twentyFour,
                    color: isHoveredOn ? Colors.indigo : Colors.grey2,
                })}
                <StyledText
                    weight={isHoveredOn ? FontType.SEMIBOLD : FontType.REGULAR}
                    style={style.addressLabel}
                >
                    {address.address.label}
                </StyledText>
                {isHoveredOn && (
                    <StyledText
                        weight={FontType.BOLD}
                        style={style.analysisButton}
                    >
                        {'Consulter'}
                    </StyledText>
                )}
            </Pressable>
        </View>
    )
}
export default AddressElement
