import {
    RouteProp,
    useIsFocused,
    useNavigation,
    useRoute,
} from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { objectToURLSearchParams } from 'domain/query_params'
import { Address } from 'domain/types/address'
import { ScreenStackList } from 'navigation/types'
import React, { useEffect, useRef } from 'react'
import { ScrollView } from 'react-native'
import Screens from 'screens/screens'
import SearchAddress from 'shared/components/SearchAddress/SearchAddress'
import Summary from 'shared/components/Summary/Summary'
import Tutorial from 'shared/components/Tutorial/Tutorial'
import useWindow from 'shared/hooks/useWindow'
import style from 'shared/styles/Screen.style'
import { adaptWidth } from 'shared/styles/window'

const Dashboard: React.FC<unknown> = () => {
    const { isSmallWindow, isWeb } = useWindow()
    const navigation =
        useNavigation<StackNavigationProp<ScreenStackList, Screens>>()
    const { params } = useRoute<RouteProp<ScreenStackList, Screens.Dashboard>>()
    const isFocused = useIsFocused()

    const searchAddressRef = useRef<HTMLInputElement>(null)

    const handleOnAnalyzeAddress = () => {
        searchAddressRef.current?.focus({
            preventScroll: true,
        })
        searchAddressRef.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
        })
    }

    const handleOnTriggerAddressAnalysis = async (searchedAddress: Address) => {
        navigation.navigate(Screens.AddressAnalysis, {
            addressInformations:
                objectToURLSearchParams(searchedAddress).toString(),
        })
    }

    useEffect(() => {
        if (params?.addNewAddress) {
            handleOnAnalyzeAddress()
        }
    }, [params?.addNewAddress, isFocused])

    return (
        <ScrollView
            style={[style.screen, adaptWidth(isSmallWindow, isWeb)]}
            contentContainerStyle={style.content}
        >
            <SearchAddress
                onPress={handleOnTriggerAddressAnalysis}
                ref={searchAddressRef}
            />
            <Summary />
            <Tutorial handleScrollToInput={handleOnAnalyzeAddress} />
        </ScrollView>
    )
}

export default Dashboard
