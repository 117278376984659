import { StyleSheet } from 'react-native'
import Colors from 'shared/styles/colors_enum'
import { FontSize } from 'shared/styles/font_enums'
import { boxShadow } from 'shared/styles/layout'

const style = StyleSheet.create({
    container: {
        paddingLeft: 10,
        flexDirection: 'column',
        alignItems: 'flex-end',
        position: 'relative',
        zIndex: 1,
        height: 'fit-content',
    },
    content: {
        ...boxShadow,
        backgroundColor: Colors.lightGrey2,
        padding: 10,
        borderRadius: 10,
        minWidth: 200,
        position: 'absolute',
        top: -10,
        right: 20,
        zIndex: 1,
        marginTop: 6,
    },
    text: {
        color: Colors.customBlack,
        fontSize: FontSize.fourteen,
    },
})

export default style
