import { Theme } from '@react-navigation/native'
import React from 'react'

export type WindowContextType = {
    fonts: Record<string, number>
    height: number
    isSmallWindow: boolean
    isWeb: boolean
    theme: Theme
}

export const WindowContext = React.createContext<WindowContextType>(
    {} as WindowContextType
)
