import Svg, { Path } from 'react-native-svg'
import { DEFAULT_ICON_PROPS, IconProps } from '../icon.types'

const IconComponent: React.FunctionComponent<IconProps> = function ({
    size = DEFAULT_ICON_PROPS.size,
    color = DEFAULT_ICON_PROPS.color,
}: IconProps) {
    return (
        <Svg
            width={size}
            height={size}
            fill={color}
            viewBox="0 0 492.004 492.004"
        >
            <Path d="M265.2 382.678 484.144 163.73c5.068-5.064 7.86-11.824 7.86-19.032s-2.792-13.968-7.86-19.032l-16.12-16.124c-10.504-10.492-27.576-10.492-38.064 0L246.104 293.398l-184.06-184.06c-5.068-5.064-11.824-7.86-19.028-7.86-7.212 0-13.968 2.796-19.04 7.86L7.86 125.462C2.792 130.53 0 137.286 0 144.494s2.792 13.968 7.86 19.032l219.144 219.152c5.084 5.076 11.872 7.864 19.088 7.848 7.244.016 14.028-2.772 19.108-7.848z" />
        </Svg>
    )
}

export default IconComponent
