import Svg, { G, Path } from 'react-native-svg'
import { DEFAULT_ICON_PROPS, IconProps } from '../icon.types'

const IconComponent: React.FunctionComponent<IconProps> = function ({
    size = DEFAULT_ICON_PROPS.size,
    color = DEFAULT_ICON_PROPS.color,
}: IconProps) {
    return (
        <Svg width={size} height={size} viewBox="0 0 95 95">
            <G fill={color}>
                <Path d="M92.44 73.62H2.56a2.5 2.5 0 0 1-2.5-2.37C0 70.46 0 69.67 0 68.88a47.5 47.5 0 0 1 95 0c0 .79 0 1.58-.06 2.37a2.5 2.5 0 0 1-2.5 2.37zM5 68.62h85a42.5 42.5 0 0 0-85 0z" />
                <Path d="M55.75 70.34a8.3 8.3 0 1 0-16.6 0l-5 .03c0-7.345 5.955-13.3 13.3-13.3s13.3 5.955 13.3 13.3z" />
                <Path d="m49.85 59.58 7.41-16.84c1.44-2.762 5.622-.931 4.57 2l-7.4 16.84zM47.5 32.34a2.5 2.5 0 0 1-2.5-2.5v-6h5v6a2.5 2.5 0 0 1-2.5 2.5zM28 37.57a2.52 2.52 0 0 1-2.17-1.25l-3-5.16 4.33-2.5 3 5.16a2.51 2.51 0 0 1-.91 3.42 2.54 2.54 0 0 1-1.25.33zM13.69 51.86a2.54 2.54 0 0 1-1.25-.33l-5.17-3 2.5-4.33 5.17 3c2.201 1.283 1.298 4.651-1.25 4.66zm67.62 0c-2.548-.009-3.451-3.377-1.25-4.66l5.17-3 2.5 4.33-5.17 3a2.54 2.54 0 0 1-1.25.33zM67 37.57a2.54 2.54 0 0 1-1.25-.33 2.51 2.51 0 0 1-.91-3.42l3-5.16 4.33 2.5-3 5.16A2.52 2.52 0 0 1 67 37.57z" />
            </G>
        </Svg>
    )
}

export default IconComponent
