import { useFormatMessage } from 'i18n/hooks'
import { useEffect } from 'react'
import { Pressable, ScrollView, View } from 'react-native'
import { ICON_COMPONENTS } from 'shared/assets/icons'
import {
    ILLUSTRATION_COMPONENTS,
    IllustrationSize,
} from 'shared/assets/illustration'
import Category from 'shared/components/Category/Category'
import StyledText from 'shared/components/StyledText/StyledText'
import useAuth from 'shared/hooks/authentication/useAuth'
import useWindow from 'shared/hooks/useWindow'
import Colors from 'shared/styles/colors_enum'
import { FontType } from 'shared/styles/font_enums'
import { IconSize } from 'shared/styles/icon_enum'
import style from './Login.style'

type Props = {
    style: object
}

const CategorySeparator: React.FC<Props> = (props: Props) => {
    return (
        <View
            style={{
                ...props.style,
                backgroundColor: '#3B3BE2',
                borderRadius: 10,
                height: 2,
                marginTop: 60,
                opacity: 0.1,
                position: 'absolute',
                width: 200,
            }}
        />
    )
}

const Login: React.FC<unknown> = () => {
    const { signIn, handlePKCE } = useAuth()
    const t = useFormatMessage()
    const { isWeb } = useWindow()

    useEffect(() => {
        handlePKCE()
    }, [])

    return (
        <ScrollView>
            <View style={style.container}>
                <View>
                    <StyledText weight={FontType.BOLD} style={style.title}>
                        {
                            t({
                                defaultMessage:
                                    "Comprendre les vulnérabilités climatiques d'une adresse",
                            }) as string
                        }
                    </StyledText>
                </View>
                <View
                    style={
                        isWeb ? style.subContainer : { flexDirection: 'column' }
                    }
                >
                    <Category
                        asset={ICON_COMPONENTS.glass({
                            size: IconSize.eighty,
                        })}
                        details={
                            t({
                                defaultMessage:
                                    "Obtenir, à l'adresse, des informations claires relatives aux risques d'inondations, de sécheresses et de restrictions d'eau.",
                            }) as string
                        }
                        label={
                            t({
                                defaultMessage: 'Analyser',
                            }) as string
                        }
                    />
                    {isWeb && <CategorySeparator style={{ left: 190 }} />}
                    <Category
                        asset={ILLUSTRATION_COMPONENTS.notepad({
                            size: IllustrationSize.eighty,
                            idBaseName: 'Conseiller',
                        })}
                        details={
                            t({
                                defaultMessage:
                                    "Recevoir, à l'adresse, des préconisations d'actions d'adaptation du bâti pour limiter ou éviter un endommagement futur.",
                            }) as string
                        }
                        extraStyle={{ marginHorizontal: 60 }}
                        label={
                            t({
                                defaultMessage: 'Conseiller',
                            }) as string
                        }
                    />
                    {isWeb && <CategorySeparator style={{ right: 190 }} />}
                    <Category
                        asset={ILLUSTRATION_COMPONENTS.send({
                            size: IllustrationSize.eighty,
                            idBaseName: 'Partager',
                        })}
                        details={
                            t({
                                defaultMessage:
                                    "Pouvoir enregistrer des analyses dans un portefeuille consultable en ligne et envoyer un rapport à l'adresse afin de le partager avec son client.",
                            }) as string
                        }
                        label={
                            t({
                                defaultMessage: 'Partager',
                            }) as string
                        }
                    />
                </View>
                <Pressable style={style.doLogin} onPress={() => signIn()}>
                    {ICON_COMPONENTS.arrowRight({
                        size: IconSize.twentyFour,
                        color: Colors.white,
                    })}
                    <StyledText
                        weight={FontType.SEMIBOLD}
                        style={style.doLoginLabel}
                    >
                        {
                            t({
                                defaultMessage: 'SE CONNECTER',
                            }) as string
                        }
                    </StyledText>
                </Pressable>
            </View>
        </ScrollView>
    )
}

export default Login
