import Svg, { Path } from 'react-native-svg'
import { DEFAULT_ICON_PROPS, IconProps } from '../icon.types'

const IconComponent: React.FunctionComponent<IconProps> = function ({
    size = DEFAULT_ICON_PROPS.size,
    color = DEFAULT_ICON_PROPS.color,
}: IconProps) {
    return (
        <Svg width={size} height={size} fill="none" viewBox="0 0 21 24">
            <Path
                fillRule="evenodd"
                fill={color}
                d="m10.286 17.732.937-.996.006.005 6.857-7.286.878-.937-1.875-1.763-.884.938-4.634 4.923V0H9v12.616L4.366 7.693l-.878-.938-1.875 1.763.878.932 6.857 7.286.938.996ZM0 24h20.571v-2.571H0V24Z"
                clipRule="evenodd"
            />
        </Svg>
    )
}

export default IconComponent
