import React, { useEffect } from 'react'
import { Pressable, View } from 'react-native'
import { ICON_COMPONENTS } from 'shared/assets/icons'
import Colors from 'shared/styles/colors_enum'
import { IconSize } from 'shared/styles/icon_enum'
import styles from './Snackbar.style'
import { SnackMessageType } from 'shared/contexts/SnackbarContext'
import useSnackbarActivation from 'shared/hooks/snackbars/useSnackbarActivation'
import StyledText from '../StyledText/StyledText'

type Props = {
    message: string
    type: SnackMessageType
    duration?: number
    id: string
    children?: React.ReactNode
}

const Snackbar: React.FC<Props> = (props: Props) => {
    const { removeSnack } = useSnackbarActivation()
    const duration = props.duration || 5000

    const SnackbarStyle = {
        [SnackMessageType.SUCCESS]: {
            icon: ICON_COMPONENTS.circleCheck,
            colorIcon: Colors.darkGreen,
            color: Colors.successGreen,
        },
        [SnackMessageType.INFO]: {
            icon: ICON_COMPONENTS.info,
            colorIcon: Colors.indigo,
            color: Colors.hoverIndigo,
        },
        [SnackMessageType.WARN]: {
            icon: ICON_COMPONENTS.cross,
            colorIcon: Colors.orange,
            color: Colors.yellow,
        },
        [SnackMessageType.ERROR]: {
            icon: ICON_COMPONENTS.cross,
            colorIcon: Colors.alarmingRed,
            color: Colors.red,
        },
    }

    const onDismiss = () => removeSnack(props.id)

    useEffect(() => {
        const timer = setTimeout(onDismiss, duration)
        return () => clearTimeout(timer)
    }, [])

    return (
        <View style={styles.container}>
            <View
                style={{
                    ...styles.content,
                    backgroundColor: SnackbarStyle[props.type].color,
                }}
            >
                <View style={styles.textContainer}>
                    {SnackbarStyle[props.type].icon({
                        size: IconSize.thirtyTwo,
                        color: SnackbarStyle[props.type].colorIcon,
                    })}

                    <StyledText style={styles.text}>{props.message}</StyledText>
                </View>

                {props.children}

                <Pressable onPress={onDismiss} testID="removeSnackButton">
                    {ICON_COMPONENTS.cross({
                        size: IconSize.twentyFour,
                        color: Colors.placeholderGrey,
                    })}
                </Pressable>
            </View>
        </View>
    )
}

export default Snackbar
