import { RiskSeverity } from 'domain/types/risk_severity'
import React from 'react'
import { View } from 'react-native'
import RiskTooltip from '../../RiskTooltip/RiskTooltip'
import RiskProgressBar from '../RiskProgressBar/RiskProgressBar'
import style from './MainRiskIndicator.style'
import { useFormatMessage } from 'i18n/hooks'
import StyledText from 'shared/components/StyledText/StyledText'
import { FontType } from 'shared/styles/font_enums'

type Props = {
    children?: JSX.Element
    colors: Array<string>
    description?: string
    infos?: string
    severity: RiskSeverity
    showTitle?: boolean
    zIndex?: number
}

const MainRiskIndicator: React.FC<Props> = ({
    showTitle = true,
    ...props
}: Props) => {
    const t = useFormatMessage()
    return (
        <View style={{ zIndex: props.zIndex, ...style.container }}>
            <View
                style={[
                    style.row,
                    !showTitle && { justifyContent: 'flex-end' },
                ]}
            >
                {showTitle && (
                    <StyledText weight={FontType.BOLD} style={style.title}>
                        {t({ defaultMessage: 'Sévérité du risque' })}
                    </StyledText>
                )}
                {props.infos && <RiskTooltip content={props.infos} />}
            </View>
            <View style={style.subContainer}>
                <View style={style.progressBar}>
                    <RiskProgressBar
                        colors={props.colors}
                        severity={props.severity}
                    />
                </View>
                {showTitle && (
                    <View style={style.textContainer}>
                        <StyledText style={style.textContent}>
                            {props.description}
                        </StyledText>
                    </View>
                )}
            </View>
            {props.children}
        </View>
    )
}

export default MainRiskIndicator
