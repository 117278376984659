import { ProgressBarRulesImpl } from 'domain/progress_bar_rules'
import HttpRiskService from 'infrastructure/http/http_risk_service'
import { ServiceContext } from 'shared/contexts/ServiceContext'
import WebAsyncCredentialsStorage from 'infrastructure/storage/web_async_credentials_storage'
import HttpFavoriteService from 'infrastructure/http/http_favorite_service'
import HttpBaseAdressesNationaleService from 'infrastructure/http/http_address_service'
import HttpAnalyticsService from 'infrastructure/http/http_analytics_service'
import axios from 'axios'
import HozeeHttpClient from 'infrastructure/http/client/hozee_http_client'

type Props = {
    children: JSX.Element
}

const ServicesProvider: React.FC<Props> = (props: Props) => {
    const axiosClient = axios.create()
    const credentialsStorage = new WebAsyncCredentialsStorage()
    const hozeeHttpClient = new HozeeHttpClient(axiosClient, credentialsStorage)

    const analyticsService = new HttpAnalyticsService(hozeeHttpClient)
    const favoriteService = new HttpFavoriteService(hozeeHttpClient)
    const riskService = new HttpRiskService(
        new ProgressBarRulesImpl(),
        hozeeHttpClient
    )
    const addressService = new HttpBaseAdressesNationaleService()

    return (
        <ServiceContext.Provider
            value={{
                addressService: addressService,
                analyticsService: analyticsService,
                credentialsStorage: credentialsStorage,
                favoriteService: favoriteService,
                riskService: riskService,
            }}
        >
            {props.children}
        </ServiceContext.Provider>
    )
}

export default ServicesProvider
