import { createNativeStackNavigator } from '@react-navigation/native-stack'
import React from 'react'
import AddressAnalysis from 'screens/addressAnalysis/AddressAnalysis'
import Dashboard from 'screens/dashboard/Dashboard'
import FavoriteAddresses from 'screens/favoriteAddresses/FavoriteAddresses'
import HazardsUnderstanding from 'screens/hazardsUnderstanding/HazardsUnderstanding'
import Login from 'screens/login/Login'
import Methodology from 'screens/methodology/Methodology'
import Screens from 'screens/screens'
import useAuth from 'shared/hooks/authentication/useAuth'
import { ScreenStackList } from './types'
import withLayout from './withLayout'

const Stack = createNativeStackNavigator<ScreenStackList>()
const addresses = withLayout(FavoriteAddresses)
const addressAnalysis = withLayout(AddressAnalysis)
const dashboard = withLayout(Dashboard)
const hazardsUnderstanding = withLayout(HazardsUnderstanding)
const methodology = withLayout(Methodology)
const login = withLayout(Login)

const Navigation: React.FC<unknown> = () => {
    const headerShown = { headerShown: false }
    const { user } = useAuth()

    return user ? (
        <Stack.Navigator>
            <Stack.Screen
                name={Screens.Dashboard}
                component={dashboard}
                options={{
                    title: 'Tableau de bord',
                    ...headerShown,
                }}
            />
            <Stack.Screen
                name={Screens.FavoriteAddresses}
                component={addresses}
                options={{
                    title: 'Mes adresses',
                    ...headerShown,
                }}
            />
            <Stack.Screen
                name={Screens.HazardsUnderstanding}
                component={hazardsUnderstanding}
                options={{
                    title: 'Comprendre les aléas',
                    ...headerShown,
                }}
            />
            <Stack.Screen
                name={Screens.Methodology}
                component={methodology}
                options={{
                    title: 'Méthodologie',
                    ...headerShown,
                }}
            />
            <Stack.Screen
                name={Screens.AddressAnalysis}
                component={addressAnalysis}
                options={{
                    title: "Analyse à l'adresse",
                    ...headerShown,
                }}
            />
        </Stack.Navigator>
    ) : (
        <Stack.Navigator>
            <Stack.Screen
                name={Screens.Login}
                component={login}
                options={{
                    title: 'Hozee PRO',
                    ...headerShown,
                }}
            />
        </Stack.Navigator>
    )
}

export default Navigation
