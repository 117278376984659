import { MessageDescriptor, useIntl } from 'react-intl'

type MessageFormatPrimitiveValue = string | number | boolean | null | undefined

type MessageFormatValues = Record<
    string,
    MessageFormatPrimitiveValue | React.ReactElement
>

export const useFormatMessage = () => {
    const intl = useIntl()

    return (descriptor: MessageDescriptor, values?: MessageFormatValues) =>
        intl.formatMessage(descriptor, values)
}
