import { SnackbarContext } from 'shared/contexts/SnackbarContext'
import useSnackbarActivation from 'shared/hooks/snackbars/useSnackbarActivation'

type Props = {
    children: JSX.Element
}

const SnackbarProvider: React.FC<Props> = (props: Props) => {
    const { addSnack, removeSnack, activeSnacks } = useSnackbarActivation()

    return (
        <SnackbarContext.Provider
            value={{ addSnack, removeSnack, activeSnacks }}
        >
            {props.children}
        </SnackbarContext.Provider>
    )
}

export default SnackbarProvider
