import { View, ImageStyle } from 'react-native'
import Svg, { Circle } from 'react-native-svg'
import { tutorialColors } from '../tutorial_content'
import style from './TutorialSection.style'
import { LinearGradient } from 'expo-linear-gradient'
import useWindow from 'shared/hooks/useWindow'
import { FontType } from 'shared/styles/font_enums'
import StyledText from 'shared/components/StyledText/StyledText'

type Props = {
    index: number
    content: {
        title: string
        description: string
        illustration: (style: ImageStyle) => JSX.Element
    }
    isLastElement: boolean
}
const TutorialSection: React.FC<Props> = (props: Props) => {
    const { isSmallWindow } = useWindow()

    return (
        <View
            key={`tutorial-${props.index}`}
            style={
                isSmallWindow
                    ? style.tutorialSubContainerSmallSize
                    : style.tutorialSubContainerRegularSize
            }
        >
            {props.content.illustration(style.tutorialImage)}
            {!isSmallWindow && (
                <View style={style.verticalSlider}>
                    <View>
                        <Svg height={30} width={30} viewBox="0 0 30 30">
                            <Circle
                                cx="50%"
                                cy="50%"
                                r={10}
                                stroke={tutorialColors[props.index]}
                                fill="transparent"
                                strokeWidth="10"
                                originX="15"
                                originY="15"
                            />
                        </Svg>
                    </View>
                    {!props.isLastElement && (
                        <LinearGradient
                            start={{ x: 0, y: 0 }}
                            end={{ x: 1, y: 1 }}
                            colors={[
                                tutorialColors[props.index],
                                tutorialColors[props.index + 1],
                            ]}
                            style={style.line}
                        />
                    )}
                </View>
            )}
            <View style={style.tutorialTextContainer}>
                <StyledText
                    weight={FontType.BOLD}
                    style={{
                        ...style.tutorialTitle,
                        textAlign: isSmallWindow ? 'center' : 'left',
                    }}
                >
                    {props.content.title}
                </StyledText>
                <StyledText
                    weight={FontType.LIGHT}
                    style={style.descriptionText}
                >
                    {props.content.description}
                </StyledText>
            </View>
        </View>
    )
}

export default TutorialSection
