import { useEffect, useState } from 'react'
import { Pressable } from 'react-native'
import Colors from 'shared/styles/colors_enum'
import { ICON_COMPONENTS } from 'shared/assets/icons'
import { IconSize } from 'shared/styles/icon_enum'
import style from './FavoriteAddressButton.style'
import { useFormatMessage } from 'i18n/hooks'
import StyledText from 'shared/components/StyledText/StyledText'
import { FontType } from 'shared/styles/font_enums'

type Props = {
    onAdd: () => void
    onDelete: () => void
    isSavedAsFavorite: boolean
    isDeleting: boolean // FIXME : this will be usfull to keep the changed pressed color in UI
}

enum favoriteAddressState {
    UNSAVED = 'unsaved',
    SAVED = 'saved',
}

const FavoriteAddressButton = (props: Props) => {
    const [isHoveredOn, setIsHoveredOn] = useState<boolean>(false)
    const t = useFormatMessage()
    const buttonContent = {
        [favoriteAddressState.UNSAVED]: {
            color: Colors.indigo,
            hover: Colors.hoverIndigo,
            text: t({ defaultMessage: 'ENREGISTRER' }) as string,
            icon: ICON_COMPONENTS.plus,
        },
        [favoriteAddressState.SAVED]: {
            color: Colors.greenButton,
            hover: Colors.hoverGreen,
            text: t({ defaultMessage: 'ENREGISTRÉE' }) as string,
            icon: ICON_COMPONENTS.check,
        },
    }

    const [buttonState, setButtonState] = useState<favoriteAddressState>(
        favoriteAddressState.UNSAVED
    )

    const activeColor = isHoveredOn
        ? buttonContent[buttonState].hover
        : buttonContent[buttonState].color

    useEffect(() => {
        if (!props.isSavedAsFavorite) {
            setButtonState(favoriteAddressState.UNSAVED)
        } else {
            setButtonState(favoriteAddressState.SAVED)
        }
    }, [props.isSavedAsFavorite])

    return (
        <Pressable
            accessibilityRole="button"
            style={{
                ...style.saveButton,
                borderColor: activeColor,
            }}
            onPress={props.isSavedAsFavorite ? props.onDelete : props.onAdd}
            onHoverIn={() => setIsHoveredOn(true)}
            onHoverOut={() => setIsHoveredOn(false)}
        >
            {buttonContent[buttonState].icon({
                size: IconSize.twenty,
                color: activeColor,
            })}
            <StyledText
                style={{
                    ...style.saveButtonText,
                    color: activeColor,
                }}
                weight={FontType.SEMIBOLD}
            >
                {buttonContent[buttonState].text}
            </StyledText>
        </Pressable>
    )
}

export default FavoriteAddressButton
