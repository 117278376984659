import { Address } from 'domain/types/address'
import { forwardRef } from 'react'
import { ImageBackground, View } from 'react-native'
import { ICONS } from 'shared/assets/icons'
import topography from 'shared/styles/topography.style'
import style from './SearchAddress.style'
import SearchInput from './SearchInput/SearchInput'
import { useFormatMessage } from 'i18n/hooks'
import { FontType } from 'shared/styles/font_enums'
import StyledText from '../StyledText/StyledText'

type Props = {
    onPress: (selectedAddress: Address) => void
}

const SearchAddress = forwardRef((props: Props, ref) => {
    const t = useFormatMessage()
    return (
        <View style={[style.container, { zIndex: 1 }]}>
            <ImageBackground
                style={topography.background}
                source={ICONS.topography}
            />
            <StyledText weight={FontType.BOLD} style={style.title}>
                {t({ defaultMessage: 'Analyser une adresse' })}
            </StyledText>
            <SearchInput
                onPress={props.onPress}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                ref={ref}
            />
        </View>
    )
})
SearchAddress.displayName = 'SearchAddress'
export default SearchAddress
