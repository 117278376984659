import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import React from 'react'
import AddressAnalysis from 'screens/addressAnalysis/AddressAnalysis'
import Dashboard from 'screens/dashboard/Dashboard'
import FavoriteAddresses from 'screens/favoriteAddresses/FavoriteAddresses'
import HazardsUnderstanding from 'screens/hazardsUnderstanding/HazardsUnderstanding'
import Login from 'screens/login/Login'
import Methodology from 'screens/methodology/Methodology'
import Screens from 'screens/screens'
import useAuth from 'shared/hooks/authentication/useAuth'
import { ScreenStackList } from './types'

const Tab = createBottomTabNavigator<ScreenStackList>()

const MobileNavigation: React.FC<unknown> = () => {
    const headerShown = { headerShown: true }
    const { user } = useAuth()

    return (
        <Tab.Navigator initialRouteName={Screens.Login}>
            {!user ? (
                <Tab.Screen
                    name={Screens.Login}
                    component={Login}
                    options={{
                        title: 'Hozee PRO',
                        ...headerShown,
                    }}
                />
            ) : (
                <>
                    <Tab.Screen
                        name={Screens.Dashboard}
                        component={Dashboard}
                        options={{
                            title: 'Tableau de bord',
                            ...headerShown,
                        }}
                    />
                    <Tab.Screen
                        name={Screens.FavoriteAddresses}
                        options={{ title: 'Mes adresses', ...headerShown }}
                        component={FavoriteAddresses}
                    />
                    <Tab.Screen
                        name={Screens.AddressAnalysis}
                        component={AddressAnalysis}
                        options={{
                            title: "Analyse à l'adresse",
                            ...headerShown,
                        }}
                    />
                    <Tab.Screen
                        name={Screens.HazardsUnderstanding}
                        component={HazardsUnderstanding}
                        options={{
                            title: 'Aléas',
                            ...headerShown,
                        }}
                    />
                    <Tab.Screen
                        name={Screens.Methodology}
                        component={Methodology}
                        options={{ title: 'Méthodologie', ...headerShown }}
                    />
                </>
            )}
        </Tab.Navigator>
    )
}

export default MobileNavigation
