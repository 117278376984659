import { View } from 'react-native'
import { FontType } from 'shared/styles/font_enums'
import StyledText from '../StyledText/StyledText'
import style from './Category.style'

type Props = {
    asset: JSX.Element
    label: string
    details: string
    extraStyle?: object
}

const Category: React.FC<Props> = (props: Props) => {
    return (
        <View style={[style.category, props.extraStyle]}>
            {props.asset}
            <StyledText weight={FontType.BOLD} style={style.categoryLabel}>
                {props.label}
            </StyledText>
            <StyledText style={style.categoryDetails}>
                {props.details}
            </StyledText>
        </View>
    )
}

export default Category
