import { LinearGradient } from 'expo-linear-gradient'
import React from 'react'
import { View, Image } from 'react-native'
import { floodRisk } from 'domain/types/risk_type'
import RiskTooltip from '../../RiskTooltip/RiskTooltip'
import style from './FloodElevation.style'
import helper from './helper'
import { ICONS, ICON_COMPONENTS } from 'shared/assets/icons'
import { IconSize } from 'shared/styles/icon_enum'
import Colors from 'shared/styles/colors_enum'
import StyledText from 'shared/components/StyledText/StyledText'

type Props = {
    title: string
    score: number
    step: 20 | 50
    infos?: string
    zIndex?: 1
}

const FloodElevation: React.FC<Props> = ({
    title,
    score,
    step,
    infos,
    zIndex,
}: Props) => {
    const floodElevationInfos = helper.formatFloodElevation(score, step)
    const doorHeight = 60
    const doorWidth = (doorHeight * 7) / 12
    const waterLevel = Math.min(floodElevationInfos.waterLevel, 2)
    const waterLevelOnDoor = (waterLevel * doorHeight) / 2
    const cursorMidHeight = 10

    return (
        <View style={{ ...style.container, zIndex: zIndex }}>
            <StyledText style={style.waterRiskText}>{title}</StyledText>
            <View
                style={{ ...style.waterRiskPictoContainer, height: doorHeight }}
            >
                <View
                    testID="levelText"
                    style={{
                        ...style.waterLevelInfoContainer,
                        transform: [
                            {
                                translateY:
                                    -1 * (waterLevelOnDoor - cursorMidHeight),
                            },
                        ],
                    }}
                >
                    <StyledText style={style.waterLevelText}>
                        {floodElevationInfos.text}
                    </StyledText>
                    <View style={style.cursorContainer}>
                        {ICON_COMPONENTS.triangleArrow({
                            size: IconSize.twenty,
                            color: Colors.pictoBlue,
                        })}
                    </View>
                </View>
                <View style={{ height: 'auto', flexDirection: 'column' }}>
                    <Image
                        accessibilityLabel="FloodedDoor"
                        source={ICONS.door}
                        style={{ ...style.waterRiskPicto, height: doorHeight }}
                    />
                    <LinearGradient
                        testID="linearGradient"
                        start={{ x: 0, y: 0 }}
                        end={{ x: 1, y: 1 }}
                        colors={floodRisk.colors}
                        style={{
                            ...style.waterIllustration,
                            height: waterLevelOnDoor,
                            width: doorWidth,
                        }}
                    />
                </View>
            </View>
            {infos && <RiskTooltip content={infos} />}
        </View>
    )
}

export default FloodElevation
