import React from 'react'

export enum SnackMessageType {
    SUCCESS,
    INFO,
    WARN,
    ERROR,
}

export type SnackType = {
    message: string
    type: SnackMessageType
    timestamp: number
    uuid: string
}

export type SnackbarContextType = {
    addSnack: (message: string, type: SnackMessageType) => void
    removeSnack: (uuid: string) => void
    activeSnacks: SnackType[]
}

export const SnackbarContext = React.createContext<SnackbarContextType>(
    {} as SnackbarContextType
)
