import Svg, { Path } from 'react-native-svg'
import { DEFAULT_ICON_PROPS, IconProps } from '../icon.types'

const IconComponent: React.FunctionComponent<IconProps> = function ({
    size = DEFAULT_ICON_PROPS.size,
    color = DEFAULT_ICON_PROPS.color,
}: IconProps) {
    return (
        <Svg width={size} height={size} viewBox="0 0 24 24">
            <Path
                fill={color}
                d="M16.5641 13.0594C17.15 12.4734 17.15 11.5219 16.5641 10.9359L10.5641 4.93594C10.1328 4.50469 9.49062 4.37812 8.92812 4.6125C8.36562 4.84687 8 5.39062 8 6V18C8 18.6047 8.36562 19.1531 8.92812 19.3875C9.49062 19.6219 10.1328 19.4906 10.5641 19.0641L16.5641 13.0641V13.0594Z"
            />
        </Svg>
    )
}
export default IconComponent
