import { StyleSheet } from 'react-native'

const style = StyleSheet.create({
    scrollViewContainer: {
        minHeight: 50,
        flexDirection: 'row',
    },
    listContainer: {
        flex: 1,
    },
    contentContainerStyle: {
        paddingRight: 14,
    },
})

export default style
