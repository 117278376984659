import { Platform, StyleSheet } from 'react-native'
import Colors from 'shared/styles/colors_enum'
import { FontSize } from 'shared/styles/font_enums'

const style = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        ...Platform.select({
            ios: {
                flexDirection: 'column',
            },
        }),
        width: '50%',
    },
    textInputContainer: {
        flex: 1,
        backgroundColor: Colors.shadowGrey,
        height: 40,
        borderRadius: 20,
        justifyContent: 'flex-end',
        overflow: 'hidden',
    },
    textInput: {
        borderRadius: 20,
        outlineStyle: 'none',
        borderWidth: 0,
        height: 40,
        paddingHorizontal: 25,
        paddingVertical: 11,
        backgroundColor: Colors.lightGrey2,
        color: Colors.customBlack,
        fontFamily: 'plus-jakarta-sans-light',
        fontSize: FontSize.fourteen,
    },
    cancelSearch: {
        alignItems: 'center',
        height: 35,
        justifyContent: 'center',
        position: 'absolute',
        right: 0,
        width: 35,
    },
})

export default style
