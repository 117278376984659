import { View } from 'react-native'
import useWindow from 'shared/hooks/useWindow'
import { default as screenStyle } from 'shared/styles/Screen.style'
import { adaptWidth } from 'shared/styles/window'
import style from './Methodology.style'
import StyledText from 'shared/components/StyledText/StyledText'
import {
    ILLUSTRATION_COMPONENTS,
    IllustrationSize,
} from 'shared/assets/illustration'
import { useFormatMessage } from 'i18n/hooks'
import ScreenHeader from 'shared/components/ScreenHeader/ScreenHeader'
import Screens from 'screens/screens'

const Methodology: React.FC<unknown> = () => {
    const t = useFormatMessage()
    const { isSmallWindow, isWeb } = useWindow()
    return (
        <View
            style={[
                screenStyle.screen,
                screenStyle.content,
                adaptWidth(isSmallWindow, isWeb),
            ]}
        >
            <ScreenHeader screen={Screens.Methodology} />
            <View style={style.container}>
                {ILLUSTRATION_COMPONENTS.colorPalette({
                    size: IllustrationSize.oneHundredAndFifty,
                    idBaseName: 'Methodology',
                })}
                <View style={style.textContainer}>
                    <StyledText style={style.text}>
                        {
                            t({
                                defaultMessage:
                                    'Page en cours de construction...',
                            }) as string
                        }
                    </StyledText>
                </View>
            </View>
        </View>
    )
}

export default Methodology
