import { LinearGradient } from 'expo-linear-gradient'
import React from 'react'
import { ScrollView, View } from 'react-native'
import useAuth from 'shared/hooks/authentication/useAuth'
import useSnackbar from 'shared/hooks/snackbars/useSnackbar'
import Colors from 'shared/styles/colors_enum'
import DropdownMenu from '../DropdownMenu/DropdownMenu'
import Logo from '../Logo/Logo'
import Menu from '../Menu/Menu'
import ProfileHeader from '../ProfileHeader/ProfileHeader'
import Snackbar from '../Snackbar/Snackbar'
import style from './Layout.style'

type Props = {
    children: JSX.Element
}

const Layout: React.FC<Props> = (props: Props) => {
    const { user } = useAuth()
    const { activeSnacks } = useSnackbar()
    return (
        <View style={style.container}>
            <ScrollView contentContainerStyle={{ minHeight: '100%' }}>
                <LinearGradient
                    colors={[Colors.lightestPink, Colors.lightestTurquoise]}
                    start={{ x: 0.5, y: 1 }}
                    end={{ x: 0.5, y: 0 }}
                    style={style.linearGradient}
                />
                <View style={style.header}>
                    <Logo />
                    {user ? (
                        <>
                            <Menu />
                            <ProfileHeader name={user?.name} />
                            <DropdownMenu />
                        </>
                    ) : null}
                </View>
                <View>{props.children}</View>
            </ScrollView>
            <View style={style.fixedBottomContainer}>
                <View style={style.snackbarContainer}>
                    {(activeSnacks || []).slice(0, 3).map(snack => {
                        return (
                            <Snackbar
                                key={snack.uuid}
                                id={snack.uuid}
                                type={snack.type}
                                message={snack.message}
                            />
                        )
                    })}
                </View>
            </View>
        </View>
    )
}

export default Layout
