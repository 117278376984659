import { Platform, StyleSheet } from 'react-native'
import Colors from 'shared/styles/colors_enum'
import { FontSize, LetterSpacing } from 'shared/styles/font_enums'

const style = StyleSheet.create({
    container: {
        flexDirection: 'column',
        flex: 1,
        paddingVertical: 25,
    },
    title: {
        color: Colors.customBlack,
        fontSize: FontSize.twentyFour,
        flexShrink: 0,
        ...Platform.select({
            ios: {
                paddingLeft: 10,
            },
        }),
    },
    titleRow: {
        flexDirection: 'row',
        paddingBottom: 18,
    },
    row: {
        alignItems: 'baseline',
        paddingTop: 18,
        flexDirection: 'row',
    },
    infos: {
        color: Colors.customBlack,
        fontSize: FontSize.fourteen,
        flex: 1,
    },
    action: {
        alignItems: 'center',
        borderRadius: 100,
        borderColor: Colors.indigo,
        borderWidth: 1,
        justifyContent: 'center',
        marginTop: 16,
        marginHorizontal: 'auto',
        paddingHorizontal: 16,
        paddingVertical: 8,
    },
    actionText: {
        color: Colors.indigo,
        fontSize: FontSize.eleven,
        letterSpacing: LetterSpacing.small,
    },
})

export default style
