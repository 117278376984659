import { AxiosResponse } from 'axios'
import { RiskService } from 'domain/risk_service'
import { RisksAtAddress } from 'domain/types/risk_at_address'
import { RiskType } from 'domain/types/risk'
import { ProgressBarRules } from 'domain/progress_bar_rules'
import { buildRiskSummary } from './helper'
import { Address } from 'domain/types/address'
import { ROUTES } from './endpoints'
import HttpClient from './client/http_client'

class HttpRiskService implements RiskService {
    private progressBarRules: ProgressBarRules
    private httpClient: HttpClient

    constructor(progressBarRules: ProgressBarRules, httpClient: HttpClient) {
        this.progressBarRules = progressBarRules
        this.httpClient = httpClient
    }

    async get(address: Address): Promise<RisksAtAddress | undefined> {
        const httpResponse = await this.httpClient.client.get(
            ROUTES.addressesRisks,
            {
                params: {
                    latitude: address.latitude,
                    longitude: address.longitude,
                    interopkey: address.interopkey,
                    postcode: address.postcode,
                    housenumber: address.housenumber,
                    street: address.street,
                    city: address.city,
                    department: address.department,
                },
            }
        )
        return this.buildHttpResponse(httpResponse)
    }

    async getLastAnalysisForFavoriteAddress(
        favorite_id: number
    ): Promise<RisksAtAddress | undefined> {
        const httpResponse = await this.httpClient.client.get(
            `${ROUTES.favorites}/${favorite_id}/risks`
        )
        return this.buildHttpResponse(httpResponse)
    }

    private buildHttpResponse(http_response: AxiosResponse) {
        const data = http_response.data
        const risks = {
            flood: {
                [RiskType.WATERLEVELS]: {
                    NH040_wl: data['synthesis']['NH040_wl'],
                    NN040_wl: data['synthesis']['NN040_wl'],
                    NX040_wl: data['synthesis']['NX040_wl'],
                    PM040_wl: data['synthesis']['PM040_wl'],
                    NH070_wl: data['synthesis']['NH070_wl'],
                    NN070_wl: data['synthesis']['NN070_wl'],
                    NX070_wl: data['synthesis']['NX070_wl'],
                    PM070_wl: data['synthesis']['PM070_wl'],
                    NH120_wl: data['synthesis']['NH120_wl'],
                    NN120_wl: data['synthesis']['NN120_wl'],
                    NX120_wl: data['synthesis']['NX120_wl'],
                    PM120_wl: data['synthesis']['PM120_wl'],
                },
                [RiskType.EXZECO]: {
                    exzecoRuiL: data['synthesis']['exzecoRuiL'],
                    exzecoRuiL_50m: data['synthesis']['exzecoRuiL_50m'],
                },
                [RiskType.PPRIS]: {
                    gasparId: data['synthesis']['gasparId'],
                    ppriDebL: data['synthesis']['ppriDebL'],
                    ppriRemL: data['synthesis']['ppriRemL'],
                    ppriRuiL: data['synthesis']['ppriRuiL'],
                    ppriSubL: data['synthesis']['ppriSubL'],
                    pprnSTs: data['synthesis']['pprnSTs'],
                    pprnTs: data['synthesis']['pprnTs'],
                    soumisalea: data['synthesis']['soumisalea'],
                },
                [RiskType.REMNAS]: {
                    remnaR: data['synthesis']['remnaR'],
                    remnaRemL: data['synthesis']['remnaRemL'],
                    remnaT: data['synthesis']['remnaT'],
                    isUnderSeaLevelRiseBy2120Risk:
                        data['synthesis']['is_under_cellar_flooding_risk'],
                    isUnderUnderwaterFlowRisk:
                        data['synthesis']['is_under_underwater_flow_risk'],
                },
                [RiskType.SURFRESTRIS]: {
                    surPrplRsH: data['synthesis']['surPrplRsH'],
                    surPrplRsL: data['synthesis']['surPrplRsL'],
                },
                [RiskType.TRIS]: {
                    triDebL: data['synthesis']['triDebL'],
                    triId: data['synthesis']['triId'],
                    triRemL: data['synthesis']['triRemL'],
                    triRuiL: data['synthesis']['triRuiL'],
                    triSubL: data['synthesis']['triSubL'],
                    triT: data['synthesis']['triT'],
                },
                [RiskType.UNDRESTRIS]: {
                    undPrplRsH: data['synthesis']['undPrplRsH'],
                    undPrplRsL: data['synthesis']['undPrplRsL'],
                },
                [RiskType.WATERCOURSES]: {
                    waterC: data['synthesis']['waterC'],
                    waterCode: data['synthesis']['waterCode'],
                    waterD: data['synthesis']['waterD'],
                    waterN: data['synthesis']['waterN'],
                },
            },
            drought: {
                [RiskType.DROUGHT]: {
                    H1A2: {
                        H1A2mtDtL: data['synthesis']['H1A2mtDtL'],
                        H1A2slDtL: data['synthesis']['H1A2slDtL'],
                        H1A2spiL: data['synthesis']['H1A2spiL'],
                        H1A2sswiL: data['synthesis']['H1A2sswiL'],
                    },
                },
                [RiskType.RGARGS]: {
                    rgargL: data['synthesis']['rgargL'],
                },
            },
            waterRestrictions: {
                [RiskType.SURFRESTRIS]: {
                    surPrplRsH: data['synthesis']['surPrplRsH'],
                    surPrplRsL: data['synthesis']['surPrplRsL'],
                },
                [RiskType.UNDRESTRIS]: {
                    undPrplRsH: data['synthesis']['undPrplRsH'],
                    undPrplRsL: data['synthesis']['undPrplRsL'],
                },
            },
        }
        const summary = buildRiskSummary(risks, this.progressBarRules)

        return {
            address: {
                city: data.address['city'],
                department: data.address['department'],
                housenumber: data.address['number'],
                interopkey: data.address['interopKey'],
                label: data.address['label'],
                postcode: data.address['postalCode'],
                street: data.address['street'],
            },
            town: {
                pprns: {
                    labels: data['analyzed_town']['pprns'],
                    count: data['analyzed_town']['pprns_count'],
                },
                catnat_count: data['analyzed_town']['catnat_count'],
            },
            summary: summary,
            risks: risks,
        }
    }
}

export default HttpRiskService
