import Svg, { Path } from 'react-native-svg'
import { DEFAULT_ICON_PROPS, IconProps } from '../icon.types'

const IconComponent: React.FunctionComponent<IconProps> = function ({
    size = DEFAULT_ICON_PROPS.size,
    color = DEFAULT_ICON_PROPS.color,
}: IconProps) {
    return (
        <Svg width={size} height={size} fill={color} viewBox="0 0 21 24">
            <Path
                fillRule="evenodd"
                d="M0 0h20.571v2.571H0V0Zm10.286 6.268.937.996.006-.005 6.857 7.286.878.937-1.875 1.763-.884-.938-4.634-4.923V24H9V11.384l-4.634 4.923-.878.938-1.875-1.763.878-.932 6.857-7.286.938-.996Z"
                clipRule="evenodd"
            />
        </Svg>
    )
}

export default IconComponent
