import { createContext } from 'react'

export type UserIdentity = {
    name: string
}

export type AuthParams = {
    code: string
    state: string
}

export type AuthContextType = {
    handlePKCE(): void
    resetUser(): void
    signIn(): void
    signOut(): void
    user: UserIdentity | null
}

export const AuthContext = createContext<AuthContextType>({} as AuthContextType)
