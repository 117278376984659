import { StyleSheet } from 'react-native'
import Colors from 'shared/styles/colors_enum'
import { FontSize } from 'shared/styles/font_enums'
import { Margin, Padding } from 'shared/styles/layout'

const style = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: Padding.thirtySix,
    },
    textContainer: {
        marginVertical: Margin.thirtyTwo,
    },
    text: {
        color: Colors.grey3,
        fontSize: FontSize.twentyFour,
        textAlign: 'center',
    },
})

export default style
