import { ICONS, prepImgIcon } from 'shared/assets/icons'
import Colors from 'shared/styles/colors_enum'

export const tutorialColors = [
    Colors.tutorialRed,
    Colors.tutorialPink,
    Colors.tutorialFuchsia,
]
export const TutorialContent = [
    {
        title: "Analyser les risques à l'adresse",
        description: '',
        illustration: prepImgIcon(ICONS.tutorialAnalysis),
    },
    {
        title: "Obtenez des notes de risques expliquées pour l'inondation et la sécheresse",
        description: '',
        illustration: prepImgIcon(ICONS.tutorialHouse),
    },
    {
        title: 'Préconisez des actions adaptées',
        description: '',
        illustration: prepImgIcon(ICONS.tutorialCertificate),
    },
]
