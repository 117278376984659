import { Platform, StyleSheet } from 'react-native'
import { FontSize } from 'shared/styles/font_enums'
import { Padding } from 'shared/styles/layout'
import Colors from '../../../styles/colors_enum'

const style = StyleSheet.create({
    container: {
        flexDirection: 'row',
        paddingTop: Padding.thirtySix,
    },
    waterRiskText: {
        color: Colors.customBlack,
        flex: 1,
        fontSize: FontSize.fourteen,
        margin: 'auto',
    },
    waterRiskPictoContainer: {
        flexFlow: 'row nowrap',
        justifyContent: 'flex-end',
        alignItems: 'baseline',
        flex: 2,
    },
    waterRiskPicto: {
        alignSelf: 'flex-end',
        bottom: 0,
        resizeMode: 'contain',
        aspectRatio: 7 / 12,
    },
    waterIllustration: {
        alignSelf: 'flex-end',
        bottom: 0,
        opacity: 0.9,
        position: 'absolute',
    },
    waterLevelInfoContainer: {
        flexFlow: 'row nowrap',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        height: 'fit-content',
        ...Platform.select({
            ios: {
                marginHorizontal: 0,
                height: 'auto',
            },
        }),
    },
    waterLevelText: {
        color: Colors.darkGrey,
        fontSize: FontSize.twentyFour,
        lineHeight: 30,
    },
    cursorContainer: {
        paddingBottom: 2,
    },
})

export default style
