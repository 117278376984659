import { Image, ImageSourcePropType, ImageStyle } from 'react-native'
import Advise from 'shared/assets/svg/advise'
import ArrowBack from 'shared/assets/svg/arrow_back'
import ArrowDownToLine from 'shared/assets/svg/arrow_down_to_line'
import ArrowMaximize from 'shared/assets/svg/arrow_maximize'
import ArrowRight from 'shared/assets/svg/arrow_right'
import ArrowUp from 'shared/assets/svg/arrow_up'
import ArrowUpToLine from 'shared/assets/svg/arrow_up_to_line'
import Calculator from 'shared/assets/svg/calculator'
import Check from 'shared/assets/svg/check'
import CircleCheck from 'shared/assets/svg/circle_check'
import Cross from 'shared/assets/svg/cross'
import Dashboard from 'shared/assets/svg/dashboard'
import DownArrow from 'shared/assets/svg/down_arrow'
import Drought from 'shared/assets/svg/drought'
import Eye from 'shared/assets/svg/eye'
import File from 'shared/assets/svg/file'
import FloodedHouse from 'shared/assets/svg/flooded_house'
import Glass from 'shared/assets/svg/glass'
import Info from 'shared/assets/svg/info'
import LocalizationPin from 'shared/assets/svg/localization_pin'
import MagnifyingGlass from 'shared/assets/svg/magnifying_glass'
import NoWater from 'shared/assets/svg/no_water'
import OpenBook from 'shared/assets/svg/open_book'
import Plus from 'shared/assets/svg/plus'
import Profile from 'shared/assets/svg/profile'
import Share from 'shared/assets/svg/share'
import TemperatureSunlight from 'shared/assets/svg/temperature_sunlight'
import TriangleArrow from 'shared/assets/svg/triangle_arrow'
import UpArrow from 'shared/assets/svg/up_arrow'
import WaterDown from 'shared/assets/svg/water_down'
import WaterUp from 'shared/assets/svg/water_up'
import Wave from 'shared/assets/svg/wave'
import Wheat from 'shared/assets/svg/wheat'
import WIP from 'shared/assets/svg/wip'
import { IconProps } from './icon.types'

export const ICONS = {
    topography: require('img/topography-pattern.svg'),
    door: require('img/picto-door.png'),
    marker: require('img/marker.svg'),
    cross: require('img/cross.svg'),
    original: {
        waterUp: require('img/original/water-up.png'),
        waterDown: require('img/original/water-down.png'),
        wave: require('img/original/wave.png'),
        waterArrow: require('img/original/arrow-from-bottom.png'),
        droughtTemperature: require('img/original/drought-temperature.png'),
        droughtRga: require('img/original/drought-rga.png'),
        droughtWheat: require('img/original/drought-wheat.png'),
        waterRestrictionArrowUp: require('img/original/arrow-to-top.png'),
        waterRestrictionArrowDown: require('img/original/arrow-to-bottom.png'),
    },
    white: {
        waterUp: require('img/white/water-up.png'),
        waterDown: require('img/white/water-down.png'),
        wave: require('img/white/wave.png'),
        waterArrow: require('img/white/arrow-from-bottom.png'),
        droughtTemperature: require('img/white/drought-temperature.png'),
        droughtRga: require('img/white/drought-rga.png'),
        droughtWheat: require('img/white/drought-wheat.png'),
        waterRestrictionArrowUp: require('img/white/arrow-to-top.png'),
        waterRestrictionArrowDown: require('img/white/arrow-to-bottom.png'),
    },
    indigo: {
        waterUp: require('img/indigo/water-up.png'),
        waterDown: require('img/indigo/water-down.png'),
        wave: require('img/indigo/wave.png'),
        waterArrow: require('img/indigo/arrow-from-bottom.png'),
        droughtTemperature: require('img/indigo/drought-temperature.png'),
        droughtRga: require('img/indigo/drought-rga.png'),
        droughtWheat: require('img/indigo/drought-wheat.png'),
        waterRestrictionArrowUp: require('img/indigo/arrow-to-top.png'),
        waterRestrictionArrowDown: require('img/indigo/arrow-to-bottom.png'),
        magnifyingGlass: require('img/indigo/magnifying-glass.png'),
    },
    hozeeLogo: require('img/logo.png'),
    tutorialAnalysis: require('img/tutorial-analysis.png'),
    tutorialHouse: require('img/tutorial-house.png'),
    tutorialCertificate: require('img/tutorial-certificate.png'),
}

export function prepImgIcon(iconSource: ImageSourcePropType) {
    return function renderImageIcon(style: ImageStyle) {
        return <Image source={iconSource} style={style} />
    }
}

export const ICON_COMPONENTS = {
    advise: (props?: IconProps) => <Advise {...props} />,
    arrowBack: (props?: IconProps) => <ArrowBack {...props} />,
    arrowDownToLine: (props?: IconProps) => <ArrowDownToLine {...props} />,
    arrowUpToLine: (props?: IconProps) => <ArrowUpToLine {...props} />,
    arrowMaximize: (props?: IconProps) => <ArrowMaximize {...props} />,
    arrowRight: (props?: IconProps) => <ArrowRight {...props} />,
    arrowUp: (props?: IconProps) => <ArrowUp {...props} />,
    calculator: (props?: IconProps) => <Calculator {...props} />,
    cross: (props?: IconProps) => <Cross {...props} />,
    circleCheck: (props?: IconProps) => <CircleCheck {...props} />,
    check: (props?: IconProps) => <Check {...props} />,
    dashboard: (props?: IconProps) => <Dashboard {...props} />,
    downArrow: (props?: IconProps) => <DownArrow {...props} />,
    drought: (props?: IconProps) => <Drought {...props} />,
    eye: (props?: IconProps) => <Eye {...props} />,
    file: (props?: IconProps) => <File {...props} />,
    floodedHouse: (props?: IconProps) => <FloodedHouse {...props} />,
    glass: (props?: IconProps) => <Glass {...props} />,
    info: (props?: IconProps) => <Info {...props} />,
    localizationPin: (props?: IconProps) => <LocalizationPin {...props} />,
    magnifyingGlass: (props?: IconProps) => <MagnifyingGlass {...props} />,
    noWater: (props?: IconProps) => <NoWater {...props} />,
    openBook: (props?: IconProps) => <OpenBook {...props} />,
    plus: (props?: IconProps) => <Plus {...props} />,
    profile: (props?: IconProps) => <Profile {...props} />,
    share: (props?: IconProps) => <Share {...props} />,
    temperatureSunlight: (props?: IconProps) => (
        <TemperatureSunlight {...props} />
    ),
    triangleArrow: (props?: IconProps) => <TriangleArrow {...props} />,
    upArrow: (props?: IconProps) => <UpArrow {...props} />,
    wip: (props?: IconProps) => <WIP {...props} />,
    waterDown: (props?: IconProps) => <WaterDown {...props} />,
    waterUp: (props?: IconProps) => <WaterUp {...props} />,
    wheat: (props?: IconProps) => <Wheat {...props} />,
    wave: (props?: IconProps) => <Wave {...props} />,
}
