import { useState } from 'react'
import {
    SnackMessageType,
    SnackType,
    SnackbarContextType,
} from 'shared/contexts/SnackbarContext'
import { v4 as uuidv4 } from 'uuid'

const useSnackbarActivation = (): SnackbarContextType => {
    const [activeSnacks, setActiveSnacks] = useState<SnackType[]>([])

    const isNotTenSecondsOld = (snackCreationTimestamp: number) => {
        const currentTime = new Date().getTime()
        return currentTime - snackCreationTimestamp < 10000
    }

    const addSnack = (message: string, type: SnackMessageType) => {
        const newSnack = {
            message: message,
            type: type,
            timestamp: new Date(Date.now()).getTime(),
            uuid: uuidv4(),
        }
        setActiveSnacks(activeSnacks => [...activeSnacks, newSnack])
    }

    const removeSnack = (snackUuid: string) => {
        const newList = activeSnacks.filter((snack, index) => {
            const isSelectedForDeletion = snack.uuid === snackUuid
            const isCurrentlyShowing = index <= 2
            if (
                !isSelectedForDeletion &&
                (isCurrentlyShowing || isNotTenSecondsOld(snack.timestamp))
            )
                return snack
        })
        setActiveSnacks(newList)
    }

    return { addSnack, removeSnack, activeSnacks }
}

export default useSnackbarActivation
