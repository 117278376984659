import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { FavoriteAddresses } from 'domain/types/favorite_address'
import { ScreenStackList } from 'navigation/types'
import { useMemo, useState } from 'react'
import { Pressable, View } from 'react-native'
import Screens from 'screens/screens'
import CustomScrollView from 'shared/components/CustomScrollView/CustomScrollView'
import Separator, { Direction } from 'shared/components/Separator/Separator'
import useWindow from 'shared/hooks/useWindow'
import AddressElement from './AddressElement/AddressElement'
import style from './AddressesList.style'
import SearchInputList from './SearchInputList/SearchInputList'
import { useFormatMessage } from 'i18n/hooks'
import StyledText from '../StyledText/StyledText'
import { FontType } from 'shared/styles/font_enums'
import {
    ILLUSTRATION_COMPONENTS,
    IllustrationSize,
} from 'shared/assets/illustration'

type Props = {
    favoriteAddresses: FavoriteAddresses
    setFavoriteAddresses: (favoriteAddress: FavoriteAddresses) => void
}

const AddressesList: React.FC<Props> = (props: Props) => {
    const t = useFormatMessage()
    const navigation =
        useNavigation<StackNavigationProp<ScreenStackList, Screens>>()
    const { height } = useWindow()
    const handleShowAddressAnalysis = async (favoriteAddressId: number) => {
        navigation.navigate(Screens.AddressAnalysis, {
            favoriteAddressId: favoriteAddressId,
        })
    }
    const handleNewAddress = () => {
        navigation.navigate(Screens.Dashboard, { addNewAddress: true })
    }

    // meant to only show the elements of the list that match the search
    // if user is searching something
    const [search, setSearch] = useState<string>('')
    const initialFavoriteAddresses = props.favoriteAddresses
    const addressList = useMemo(() => {
        const isSearchingAddress = search.length
        if (isSearchingAddress) {
            const list = initialFavoriteAddresses?.filter(element =>
                element.address.label.includes(search)
            )
            if (JSON.stringify(list) !== JSON.stringify(addressList))
                return list
        }
        return initialFavoriteAddresses
    }, [search, initialFavoriteAddresses])

    return (
        <View style={style.container}>
            {initialFavoriteAddresses.length ? (
                <>
                    <SearchInputList search={search} setSearch={setSearch} />
                    <Separator
                        direction={Direction.HORIZONTAL}
                        extraStyle={{ marginVertical: 30 }}
                    />
                    <CustomScrollView containerMaxHeight={height - 500}>
                        <>
                            {addressList.map(address => {
                                return (
                                    <View key={address.favoriteAddressId}>
                                        <AddressElement
                                            address={address}
                                            showAnalysis={
                                                handleShowAddressAnalysis
                                            }
                                        />
                                    </View>
                                )
                            })}
                        </>
                    </CustomScrollView>
                </>
            ) : (
                <View style={style.noAddressesContainer}>
                    {ILLUSTRATION_COMPONENTS.notepad({
                        size: IllustrationSize.twoHundred,
                        idBaseName: 'AddressesList',
                    })}
                    <View style={style.noAddressesTextContainer}>
                        <StyledText style={style.title}>
                            {
                                t({
                                    defaultMessage:
                                        'Aucune adresse enregistrée',
                                }) as string
                            }
                        </StyledText>
                        <StyledText style={style.text}>
                            {
                                t({
                                    defaultMessage:
                                        'Commencez tout d’abord par analyser une adresse',
                                }) as string
                            }
                        </StyledText>
                    </View>
                    <View
                        style={{
                            flexDirection: 'row',
                            justifyContent: 'space-around',
                        }}
                    >
                        <Pressable
                            style={style.button}
                            onPress={handleNewAddress}
                        >
                            <StyledText
                                weight={FontType.SEMIBOLD}
                                style={style.buttonText}
                            >
                                {t({
                                    defaultMessage: 'ANALYSER UNE ADRESSE',
                                })}
                            </StyledText>
                        </Pressable>
                    </View>
                </View>
            )}
        </View>
    )
}

export default AddressesList
