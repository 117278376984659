import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { RiskSeverity } from 'domain/types/risk_severity'
import { RiskType } from 'domain/types/risk_type'
import { ScreenStackList } from 'navigation/types'
import { Pressable, View } from 'react-native'
import Screens from 'screens/screens'
import RiskProgressBar from 'shared/components/RiskSection/RiskProgressBar/RiskProgressBar'
import style from './MainRiskSynthesis.style'
import { useFormatMessage } from 'i18n/hooks'
import { FontType } from 'shared/styles/font_enums'
import StyledText from 'shared/components/StyledText/StyledText'

type Props = {
    riskType: RiskType
    severity: RiskSeverity
    infos?: string
}

const MainRiskSynthesis: React.FC<Props> = (props: Props) => {
    const t = useFormatMessage()
    const code = props.riskType.code
    const title = props.riskType.label
    const colors = props.riskType.colors
    const severity = props.severity
    const navigation =
        useNavigation<StackNavigationProp<ScreenStackList, Screens>>()
    const { params } =
        useRoute<RouteProp<ScreenStackList, Screens.AddressAnalysis>>()

    return (
        <View style={style.container}>
            <View style={style.titleRow}>
                <StyledText weight={FontType.BOLD} style={style.title}>
                    {title}
                </StyledText>
            </View>
            <RiskProgressBar colors={colors} severity={severity} />
            {props.infos && (
                <View style={style.row}>
                    <StyledText style={style.infos}>{props.infos}</StyledText>
                </View>
            )}
            <Pressable
                testID="actionButton"
                style={style.action}
                onPress={() =>
                    navigation.navigate(Screens.AddressAnalysis, {
                        ...params,
                        anchor: code,
                    })
                }
            >
                <StyledText weight={FontType.SEMIBOLD} style={style.actionText}>
                    {t({ defaultMessage: 'VOIR DÉTAILS' })}
                </StyledText>
            </Pressable>
        </View>
    )
}

export default MainRiskSynthesis
