import Colors from 'shared/styles/colors_enum'
import { IconSize } from 'shared/styles/icon_enum'

export type IconProps = {
    size?: IconSize
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    color?: Colors
}

export const DEFAULT_ICON_PROPS = {
    size: IconSize.sixteen,
    color: Colors.white,
}
