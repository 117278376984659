export const boxShadow = {
    shadowColor: 'black',
    shadowOpacity: 0.2,
    shadowRadius: 6,
}

export const menuBoxShadow = {
    shadowColor: 'black',
    shadowOpacity: 0.2,
    shadowOffset: { width: 0, height: 18 },
    shadowRadius: 24,
}

export const screenBoxShadow = {
    ...menuBoxShadow,
    shadowRadius: 42,
}

export enum ScreenProportion {
    width1045 = 1045,
    width1150 = 1150,
}

export enum Padding {
    fourteen = 14,
    sixteen = 16,
    thirty = 30,
    thirtySix = 36,
    fourtyFive = 45,
}

export enum Margin {
    eight = 8,
    eighteen = 18,
    thirty = 30,
    thirtyTwo = 32,
    thirtySix = 36,
}
