import { useContext } from 'react'
import {
    SnackbarContext,
    SnackbarContextType,
} from 'shared/contexts/SnackbarContext'

const useSnackbar = (): SnackbarContextType => {
    const context = useContext(SnackbarContext)
    return context
}

export default useSnackbar
