import { AuthParams } from 'shared/contexts/AuthContext'
import { Address } from './types/address'

export function paramsToAddress(queryParams: string): Address {
    const params = new URLSearchParams(queryParams)

    return {
        city: params.get('city') || '',
        department: params.get('department') || '',
        housenumber: params.get('housenumber') || '',
        interopkey: params.get('interopkey') || '',
        label: params.get('label') || '',
        latitude: parseFloatQueryParam(params.get('latitude')),
        longitude: parseFloatQueryParam(params.get('longitude')),
        postcode: params.get('postcode') || '',
        street: params.get('street') || '',
    }
}

export function paramsToAuth(queryParams: string): AuthParams {
    const params = new URLSearchParams(queryParams)
    return {
        code: params.get('code') || '',
        state: params.get('state') || '',
    }
}

export function parseFloatQueryParam(param: string | null): number | undefined {
    if (param) {
        return parseFloat(param)
    }
    return undefined
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function objectToURLSearchParams(object: any): URLSearchParams {
    const params = new URLSearchParams()
    Object.keys(object).forEach(key => {
        if (object[key] === undefined) return
        params.append(key, object[key])
    })
    return params
}
