import { StyleSheet } from 'react-native'
import Colors from 'shared/styles/colors_enum'
import { FontSize, LetterSpacing } from 'shared/styles/font_enums'

const style = StyleSheet.create({
    container: {
        alignItems: 'center',
        backgroundColor: 'transparent',
        flexDirection: 'row',
        borderColor: Colors.indigo,
        borderRadius: 20,
        borderWidth: 1,
        paddingHorizontal: 30,
        paddingVertical: 10,
        textAlign: 'center',
    },
    text: {
        color: Colors.indigo,
        fontSize: FontSize.twelve,
        letterSpacing: LetterSpacing.small,
    },
})

export default style
