import React from 'react'
import { View } from 'react-native'
import { Margin } from 'shared/styles/layout'
import Colors from '../../styles/colors_enum'

export enum Direction {
    HORIZONTAL,
    VERTICAL,
}

type Props = {
    direction: Direction
    extraStyle?: object
}

const Separator: React.FC<Props> = (props: Props) => {
    const styleDependingOnDirection =
        props.direction == Direction.VERTICAL
            ? { borderLeftWidth: 1, marginHorizontal: Margin.thirtySix }
            : { borderBottomWidth: 1, marginVertical: Margin.thirtySix }
    return (
        <View
            style={{
                ...styleDependingOnDirection,
                ...props.extraStyle,
                borderColor: Colors.lighterGrey,
            }}
        />
    )
}

export default Separator
