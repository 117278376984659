import ColorPalette from 'shared/assets/svg/illustration/color_palette'
import Notepad from 'shared/assets/svg/illustration/notepad'
import Send from 'shared/assets/svg/illustration/send'
import Hozee from 'shared/assets/svg/illustration/hozee_logo'

export enum IllustrationSize {
    eighty = 80,
    oneHundredAndTwenty = 120,
    oneHundredAndFifty = 150,
    twoHundred = 200,
}

export type IllustrationProps = {
    size?: IllustrationSize
    idBaseName?: string
}
export const DEFAULT_ILLUSTRATION_PROPS = {
    size: IllustrationSize.oneHundredAndTwenty,
    idBaseName: 'default',
}

export const ILLUSTRATION_COMPONENTS = {
    colorPalette: (props?: IllustrationProps) => <ColorPalette {...props} />,
    notepad: (props?: IllustrationProps) => <Notepad {...props} />,
    send: (props?: IllustrationProps) => <Send {...props} />,
    hozee: (props?: IllustrationProps) => <Hozee {...props} />,
}
