import { Platform, StyleSheet } from 'react-native'
import Colors from 'shared/styles/colors_enum'
import { FontSize, LetterSpacing } from 'shared/styles/font_enums'
import { Padding } from 'shared/styles/layout'

const style = StyleSheet.create({
    container: {
        padding: Padding.thirtySix,
        backgroundColor: Colors.lightGrey2,
        width: '100%',
    },
    title: {
        color: Colors.indigo,
        fontSize: FontSize.twentyFour,
        paddingBottom: 5,
        ...Platform.select({
            ios: {
                textAlign: 'center',
            },
        }),
    },
    subTitleContainer: {
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        ...Platform.select({
            ios: {
                flexDirection: 'column',
            },
        }),
    },
    subTitle: {
        color: Colors.customBlack,
        fontSize: FontSize.fourteen,
        ...Platform.select({
            ios: {
                paddingBottom: 10,
            },
        }),
    },
    pressable: {
        alignItems: 'center',
        backgroundColor: 'transparent',
        flexDirection: 'row',
        borderColor: Colors.indigo,
        borderRadius: 20,
        borderWidth: 1,
        paddingRight: 30,
        paddingLeft: 18,
        paddingVertical: 10,
    },
    textValue: {
        color: Colors.indigo,
        paddingLeft: 10,
        fontSize: FontSize.twelve,
        letterSpacing: LetterSpacing.small,
    },
    magnifyingGlass: {
        height: 15,
        width: 15,
    },
})

export default style
