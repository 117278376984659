import React from 'react'
import { Pressable, View } from 'react-native'
import style from './Logo.style'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { ScreenStackList } from 'navigation/types'
import Screens from 'screens/screens'
import { ILLUSTRATION_COMPONENTS } from 'shared/assets/illustration'

const Logo: React.FC<unknown> = () => {
    const navigation =
        useNavigation<StackNavigationProp<ScreenStackList, Screens>>()
    const route = useRoute<RouteProp<ScreenStackList>>()

    return (
        <View style={style.container}>
            <Pressable onPress={() => navigation.navigate(Screens.Dashboard)}>
                {ILLUSTRATION_COMPONENTS.hozee({
                    idBaseName: route.name,
                })}
            </Pressable>
        </View>
    )
}

export default Logo
