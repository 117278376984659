import { StyleSheet } from 'react-native'
import { FontSize } from 'shared/styles/font_enums'
import Colors from 'shared/styles/colors_enum'

const style = StyleSheet.create({
    container: {
        boxSizing: 'content-box',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    row: {
        boxSizing: 'content-box',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    column: {
        width: '100%',
        flexDirection: 'column',
        flex: 1,
    },
    title: {
        color: Colors.customBlack,
        fontSize: FontSize.fourteen,
    },
    value: {
        color: Colors.darkGrey,
        fontSize: FontSize.twentyFour,
        position: 'absolute',
        right: 0,
        top: -10,
    },
    precisions: {
        color: Colors.precisionsGrey,
        fontSize: FontSize.fourteen,
        textAlign: 'left',
        paddingTop: 6,
    },
})

export default style
