import { StyleSheet } from 'react-native'
import Colors from 'shared/styles/colors_enum'
import { FontSize } from 'shared/styles/font_enums'

const style = StyleSheet.create({
    tutorialSubContainerSmallSize: {
        flexDirection: 'column',
        alignItems: 'center',
    },
    tutorialSubContainerRegularSize: {
        flexDirection: 'row',
        alignItems: 'stretch',
        width: '75%',
    },
    tutorialImage: {
        height: 90,
        width: 90,
        resizeMode: 'contain',
    },
    verticalSlider: {
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingTop: 20,
        marginHorizontal: 20,
    },
    line: {
        height: '100%',
        width: 10,
        transform: [{ translateY: -5 }],
    },
    tutorialTextContainer: {
        margin: 20,
        flex: 1,
    },
    tutorialTitle: {
        marginBottom: 15,
        color: Colors.customBlack,
        fontSize: FontSize.twentyFour,
    },
    descriptionText: {
        color: Colors.customBlack,
        fontSize: FontSize.fourteen,
    },
})

export default style
