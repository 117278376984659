import { useContext } from 'react'
import { AuthContextType, AuthContext } from 'shared/contexts/AuthContext'

const useAuth = (): AuthContextType => {
    const context = useContext(AuthContext)
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider')
    }
    return context
}

export default useAuth
