import {
    RiskType,
    combinedRisk,
    droughtRisk,
    floodRisk,
    waterRestrictions as waterRestrictionsRisk,
} from 'domain/types/risk_type'
import { LinearGradient } from 'expo-linear-gradient'
import { View } from 'react-native'
import StyledText from 'shared/components/StyledText/StyledText'
import useWindow from 'shared/hooks/useWindow'
import { FontSize, FontType } from 'shared/styles/font_enums'
import style from './HighRisksChartLegend.style'
import { Repartition } from '../HighRisksChart'
import { useEffect, useMemo, useState } from 'react'
import RiskTooltip from 'shared/components/RiskTooltip/RiskTooltip'
import { useFormatMessage } from 'i18n/hooks'

type Props = {
    riskRepartition: Repartition
}

type LegendProps = {
    margin: number
    riskType: RiskType
    riskValue: number
    setNumbersLayoutWidth: (prev: any) => void
    title: string
}

const Legend = (props: LegendProps) => {
    const { fonts } = useWindow()
    return (
        <View style={style.legendCell}>
            <LinearGradient
                start={{ x: 0, y: 0 }}
                end={{ x: 1, y: 0 }}
                colors={props.riskType.colors}
                style={{ width: 20, height: 20, borderRadius: 3 }}
            />
            <View
                onLayout={event => {
                    const { width } = event.nativeEvent.layout
                    props.setNumbersLayoutWidth(
                        (prev: {
                            flood: number
                            drought: number
                            waterRestrictions: number
                            combined: number
                        }) => ({
                            ...prev,
                            [props.riskType.code]: width,
                        })
                    )
                }}
                style={{ marginHorizontal: props.margin }}
            >
                <StyledText
                    weight={FontType.EXTRABOLD}
                    style={{
                        ...style.analysisNumber,
                        fontSize: FontSize.sixteen,
                    }}
                >
                    {props.riskValue > 0 ? `${props.riskValue}%` : '-'}
                </StyledText>
            </View>
            <StyledText style={{ fontSize: fonts.link }}>
                {props.title}
            </StyledText>
        </View>
    )
}

const HighRisksChartLegend = (props: Props) => {
    const [numbersMargin, setNumbersMargin] = useState({
        flood: 0,
        drought: 0,
        waterRestrictions: 0,
        combined: 0,
    })
    const [numbersLayoutWidth, setNumbersLayoutWidth] = useState({
        flood: 0,
        drought: 0,
        waterRestrictions: 0,
        combined: 0,
    })
    const { flood, combined, drought, waterRestrictions } =
        props.riskRepartition

    const maxNumberWidth = useMemo(() => {
        const widths = Object.values(numbersLayoutWidth)
        const max = Math.max(...widths)
        return max
    }, [numbersLayoutWidth])
    const t = useFormatMessage()

    const titles = {
        flood: t({
            defaultMessage: 'Risque fort inondation',
        }) as string,
        combined: t({
            defaultMessage: 'Risques forts combinés',
        }) as string,
        drought: t({
            defaultMessage: 'Risque fort sécheresse',
        }) as string,
        waterRestrictions: t({
            defaultMessage: "Risque fort restrictions d'eau",
        }) as string,
    }

    useEffect(() => {
        setNumbersMargin({
            flood: (maxNumberWidth - numbersLayoutWidth.flood) / 2,
            drought: (maxNumberWidth - numbersLayoutWidth.drought) / 2,
            waterRestrictions:
                (maxNumberWidth - numbersLayoutWidth.waterRestrictions) / 2,
            combined: (maxNumberWidth - numbersLayoutWidth.combined) / 2,
        })
    }, [maxNumberWidth, numbersLayoutWidth])

    return (
        <View style={style.highRisksLegendContainer}>
            <View style={style.legendColumn}>
                <Legend
                    margin={numbersMargin.flood}
                    riskType={floodRisk}
                    riskValue={flood}
                    setNumbersLayoutWidth={setNumbersLayoutWidth}
                    title={titles.flood}
                />
                <Legend
                    margin={numbersMargin.waterRestrictions}
                    riskType={waterRestrictionsRisk}
                    riskValue={waterRestrictions}
                    setNumbersLayoutWidth={setNumbersLayoutWidth}
                    title={titles.waterRestrictions}
                />
            </View>
            <View style={style.legendColumn}>
                <Legend
                    margin={numbersMargin.drought}
                    riskType={droughtRisk}
                    riskValue={drought}
                    setNumbersLayoutWidth={setNumbersLayoutWidth}
                    title={titles.drought}
                />
                <Legend
                    margin={numbersMargin.combined}
                    riskType={combinedRisk}
                    riskValue={combined}
                    setNumbersLayoutWidth={setNumbersLayoutWidth}
                    title={titles.combined}
                />
            </View>
            <View style={style.tooltipOuterContainer}>
                <View style={style.tooltipInnerContainer}>
                    <RiskTooltip
                        content={
                            "On parle de risques forts combinés lorsqu'au moins deux risques forts sont présents pour une même adresse."
                        }
                    />
                </View>
            </View>
        </View>
    )
}

export default HighRisksChartLegend
