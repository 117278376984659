import Svg, { Path } from 'react-native-svg'
import { DEFAULT_ICON_PROPS, IconProps } from '../icon.types'

const IconComponent: React.FunctionComponent<IconProps> = function ({
    size = DEFAULT_ICON_PROPS.size,
    color = DEFAULT_ICON_PROPS.color,
}: IconProps) {
    return (
        <Svg width={size} height={size} fill={color} viewBox="0 0 29 25">
            <Path
                fillRule="evenodd"
                d="m15.75 5.24-.848-.141-.661-3.97a.745.745 0 0 0-.45-.568.762.762 0 0 0-.722.075L9.75 2.961 6.432.636A.737.737 0 0 0 5.71.561a.752.752 0 0 0-.45.568l-.661 3.97-3.97.66a.745.745 0 0 0-.568.45c-.098.24-.07.512.075.723l2.325 3.318L.136 13.57a.737.737 0 0 0-.075.722c.103.239.314.408.568.45l3.97.66.66 3.971c.043.253.212.469.45.567.24.099.512.07.723-.075L9.75 17.54l3.235 2.264a9.024 9.024 0 0 1-.235-1.997l-2.569-1.8a.747.747 0 0 0-.862 0l-2.775 1.941-.553-3.328A.757.757 0 0 0 5.372 14l-3.328-.553 1.94-2.775a.747.747 0 0 0 0-.862L2.05 7.044l3.328-.553a.757.757 0 0 0 .619-.619l.553-3.328 2.775 1.94c.258.184.6.184.862 0l2.775-1.94.553 3.328c.052.314.3.563.619.619l1.622.272V5.239h-.005Zm-6 7.635a2.625 2.625 0 1 1 0-5.25 2.625 2.625 0 0 1 0 5.25Zm0 1.5a4.125 4.125 0 1 1 0-8.25 4.125 4.125 0 0 1 0 8.25Zm9-9.375c0-1.654 1.345-3 3-3s3 1.346 3 3v7.345c0 .699.328 1.299.75 1.73A5.235 5.235 0 0 1 27 17.75 5.247 5.247 0 0 1 21.75 23a5.247 5.247 0 0 1-5.25-5.25c0-1.43.572-2.728 1.5-3.675.422-.431.75-1.031.75-1.73V5Zm-1.5 0a4.501 4.501 0 0 1 9 0v7.345c0 .216.103.46.319.68a6.711 6.711 0 0 1 1.93 4.725 6.752 6.752 0 0 1-6.749 6.75A6.752 6.752 0 0 1 15 17.75a6.72 6.72 0 0 1 1.931-4.725c.216-.22.32-.464.32-.68V5Zm3 12.75a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Zm2.25-2.906a3.003 3.003 0 0 1 2.25 2.906c0 1.655-1.345 3-3 3s-3-1.345-3-3c0-1.397.956-2.573 2.25-2.906V5c0-.412.337-.75.75-.75s.75.338.75.75v9.844Z"
                clipRule="evenodd"
            />
        </Svg>
    )
}

export default IconComponent
