import { FontAwesome } from '@expo/vector-icons'
import * as Font from 'expo-font'
import * as SplashScreen from 'expo-splash-screen'
import { useEffect, useState } from 'react'

export default function useCachedResources() {
    const [isLoadingComplete, setLoadingComplete] = useState(false)
    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            try {
                SplashScreen.preventAutoHideAsync()
                await Font.loadAsync({
                    ...FontAwesome.font,
                    'space-mono': require('fonts/SpaceMono-Regular.ttf'),
                    'plus-jakarta-sans-bold': require('fonts/PlusJakartaSans-Bold.ttf'),
                    'plus-jakarta-sans-light': require('fonts/PlusJakartaSans-Light.ttf'),
                    'plus-jakarta-sans-medium': require('fonts/PlusJakartaSans-Medium.ttf'),
                    'plus-jakarta-sans-regular': require('fonts/PlusJakartaSans-Regular.ttf'),
                    'plus-jakarta-sans-boldItalic': require('fonts/PlusJakartaSans-BoldItalic.ttf'),
                    'plus-jakarta-sans-extraBold': require('fonts/PlusJakartaSans-ExtraBold.ttf'),
                    'plus-jakarta-sans-extraBoldItalic': require('fonts/PlusJakartaSans-ExtraBoldItalic.ttf'),
                    'plus-jakarta-sans-extraLight': require('fonts/PlusJakartaSans-ExtraLight.ttf'),
                    'plus-jakarta-sans-extraLightItalic': require('fonts/PlusJakartaSans-ExtraLightItalic.ttf'),
                    'plus-jakarta-sans-italic': require('fonts/PlusJakartaSans-Italic.ttf'),
                    'plus-jakarta-sans-lightItalic': require('fonts/PlusJakartaSans-LightItalic.ttf'),
                    'plus-jakarta-sans-mediumItalic': require('fonts/PlusJakartaSans-MediumItalic.ttf'),
                    'plus-jakarta-sans-semiBold': require('fonts/PlusJakartaSans-SemiBold.ttf'),
                    'plus-jakarta-sans-semiBoldItalic': require('fonts/PlusJakartaSans-SemiBoldItalic.ttf'),
                })
            } catch (e) {
                console.warn(e)
            } finally {
                setLoadingComplete(true)
                SplashScreen.hideAsync()
            }
        }
        loadResourcesAndDataAsync()
    }, [])
    return isLoadingComplete
}
