import { FavoriteService } from 'domain/favorite_service'
import { AddressInteropKey } from 'domain/types/address'
import {
    FavoriteAddress,
    FavoriteAddressId,
    FavoriteAddresses,
} from 'domain/types/favorite_address'
import HttpClient from './client/http_client'
import { ROUTES } from './endpoints'

class HttpFavoriteService implements FavoriteService {
    private httpClient: HttpClient

    constructor(httpClient: HttpClient) {
        this.httpClient = httpClient
    }

    async getAll(): Promise<FavoriteAddresses | undefined> {
        const favoriteAddresses = await this.httpClient.client.get(
            ROUTES.favorites
        )
        return favoriteAddresses.data
    }

    async get(
        addressInteropKey: AddressInteropKey
    ): Promise<FavoriteAddress | undefined> {
        const favoriteAddress = await this.httpClient.client.get(
            `${ROUTES.favorites}/${addressInteropKey}`
        )
        return favoriteAddress.data
    }

    async remove(favoriteAddressId: FavoriteAddressId): Promise<void> {
        await this.httpClient.client.delete(
            `${ROUTES.favorites}/${favoriteAddressId}`
        )
    }

    async save(
        addressInteropKey: AddressInteropKey
    ): Promise<number | undefined> {
        const payload = {
            interop_key: addressInteropKey,
        }
        const response = await this.httpClient.client.post(
            ROUTES.favorites,
            payload
        )

        return response.data.id
    }
}

export default HttpFavoriteService
