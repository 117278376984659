import { RiskSeverity } from 'domain/types/risk_severity'
import React from 'react'
import { View } from 'react-native'
import RiskTooltip from '../../RiskTooltip/RiskTooltip'
import RiskProgressBar from '../RiskProgressBar/RiskProgressBar'
import style from './SubRiskIndicator.style'
import StyledText from 'shared/components/StyledText/StyledText'
import { FontType } from 'shared/styles/font_enums'

type Props = {
    children?: JSX.Element | JSX.Element[]
    colors: Array<string>
    icon: JSX.Element
    infos?: string
    severity: RiskSeverity
    title: string
    zIndex?: number
}

const SubRiskIndicator: React.FC<Props> = (props: Props) => {
    const hasChildren = props.children

    return (
        <View style={{ zIndex: props.zIndex }}>
            <View style={style.subRisk}>
                <StyledText
                    weight={FontType.BOLD}
                    style={style.subIndicatorTitle}
                >
                    {props.title}
                </StyledText>
                <View style={style.subRiskIcon}>{props.icon}</View>
                <View style={style.progressBarContainer}>
                    <RiskProgressBar
                        sub={true}
                        severity={props.severity}
                        colors={props.colors}
                    />
                </View>
                {props.infos && <RiskTooltip content={props.infos} />}
            </View>
            {hasChildren && (
                <View style={style.childrenWrapper}>{props.children}</View>
            )}
        </View>
    )
}

export default SubRiskIndicator
