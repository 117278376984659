import { Summary } from 'domain/progress_bar_rules'
import { FloodData } from 'domain/types/risk'
import { floodRisk } from 'domain/types/risk_type'
import React, { LegacyRef, forwardRef } from 'react'
import { View } from 'react-native'
import { ICON_COMPONENTS } from 'shared/assets/icons'
import useWindow from 'shared/hooks/useWindow'
import Colors from 'shared/styles/colors_enum'
import { IconSize } from 'shared/styles/icon_enum'
import ExistingRisk from '../RiskSection/ExistingRisk/ExistingRisk'
import MainRiskIndicator from '../RiskSection/MainRiskIndicator/MainRiskIndicator'
import RiskOccurences from '../RiskSection/RiskOccurences/RiskOccurences'
import SubRiskIndicator from '../RiskSection/SubRiskIndicator/SubRiskIndicator'
import Separator, { Direction } from '../Separator/Separator'
import FloodElevation from './FloodElevation/FloodElevation'
import style from './FloodRiskSection.style'
import { useFormatMessage } from 'i18n/hooks'
import StyledText from '../StyledText/StyledText'
import { FontType } from 'shared/styles/font_enums'

type Props = {
    data: FloodData
    summary: Summary
    zIndex?: number
}

const FloodRiskSection: React.FC<Props> = forwardRef((props: Props, ref) => {
    const t = useFormatMessage()
    const {
        data: { exzeco, remnas, waterlevels },
        summary,
    } = props
    const floodInfos = t({
        defaultMessage:
            'Une inondation correspond à la submersion temporaire de zones habituellement hors d’eau.',
    })
    const debInfos = t({
        defaultMessage:
            'Une crue, lorsqu’elle est importante, peut amener le cours d’eau à sortir de son lit mineur et à inonder les terres alentours.',
    })
    const ruiInfos = t({
        defaultMessage:
            'Lors de précipitations très intenses, l’eau ne peut pas toujours s’infiltrer dans le sol et les réseaux d’évacuation d’eaux pluviales peuvent être saturés. Les eaux de pluies ruissellent alors dans les rues, avec des courants parfois dangereux, jusqu’à rejoindre une rivière ou un autre réseau d’évacuation.',
    })
    const subInfos = t({
        defaultMessage:
            'sur le littoral, des conditions météorologiques et océaniques défavorables (souvent accompagnées d’une forte houle et d’un vent fort venant du large) peuvent entraîner une hausse du niveau marin et alors inonder les zones côtières.',
    })
    const floodElevationInfos = t({
        defaultMessage:
            'La hausse du niveau des mers et des océans peut s’expliquer par plusieurs causes : la fonte des glaces terrestres (glaciers de montagnes, calottes glaciaires, etc.), la dilatation de l’eau…',
    })
    const remnaInfos = t({
        defaultMessage:
            'En cas de précipitations de longue durée, le niveau de la nappe phréatique,  remonte, entraînant une inondation des  zones alentours.',
    })
    const catnatInfos = t({
        defaultMessage:
            "Un arrêté constate l'état de catastrophe naturelle. Il permet l'indemnisation des dommages directement causés aux biens assurés, en vertu de la loi du 13 juillet 1982, relative à l'indemnisation des victimes de catastrophes naturelles.",
    })
    const { isSmallWindow } = useWindow()

    return (
        <View
            testID="flood"
            style={{ zIndex: -1 }}
            ref={ref as LegacyRef<View>}
        >
            <View style={style.titleRow}>
                <StyledText weight={FontType.BOLD} style={style.title}>
                    {floodRisk.label}
                </StyledText>
            </View>
            <View style={style.mainRisk}>
                <MainRiskIndicator
                    colors={floodRisk.colors}
                    description={floodInfos}
                    severity={summary.global}
                />
            </View>
            <View
                style={[
                    style.content,
                    style.container,
                    isSmallWindow && { flexDirection: 'column' },
                ]}
            >
                <View
                    style={[
                        style.column,
                        !isSmallWindow && { flex: 1 },
                        { zIndex: 2 },
                    ]}
                >
                    <SubRiskIndicator
                        colors={floodRisk.colors}
                        title={
                            t({
                                defaultMessage: "Débordement de cours d'eau",
                            }) as string
                        }
                        severity={summary.subRisks.ppris}
                        icon={ICON_COMPONENTS.waterUp({
                            color: Colors.floodBlue,
                            size: IconSize.twentyFour,
                        })}
                        infos={debInfos}
                        zIndex={4}
                    />
                    <Separator direction={Direction.HORIZONTAL} />
                    <SubRiskIndicator
                        infos={ruiInfos}
                        title={
                            t({
                                defaultMessage: 'Ruissellement pluvial',
                            }) as string
                        }
                        severity={summary.subRisks.exzeco}
                        colors={floodRisk.colors}
                        icon={ICON_COMPONENTS.waterDown({
                            color: Colors.floodBlue,
                            size: IconSize.twentyFour,
                        })}
                        zIndex={3}
                    >
                        {exzeco.exzecoRuiL !== null ? (
                            <FloodElevation
                                title={
                                    t({
                                        defaultMessage:
                                            'Accumulation dûe au ruissellement (hauteur potentielle)',
                                    }) as string
                                }
                                score={exzeco.exzecoRuiL}
                                step={20}
                                zIndex={1}
                            />
                        ) : undefined}
                    </SubRiskIndicator>
                    <Separator direction={Direction.HORIZONTAL} />
                    <RiskOccurences
                        infos={catnatInfos}
                        precisions={[
                            `${5} ${
                                t({
                                    defaultMessage:
                                        'Inondations et/ou Coulées de Boue',
                                }) as string
                            }`,
                        ]}
                        occurences={5}
                        title={
                            t({
                                defaultMessage:
                                    'Arrêtés "Catastrophe Naturelle"',
                            }) as string
                        }
                        zIndex={1}
                    />
                </View>
                <Separator
                    direction={
                        isSmallWindow
                            ? Direction.HORIZONTAL
                            : Direction.VERTICAL
                    }
                />
                <View
                    style={[
                        style.column,
                        !isSmallWindow && { flex: 1 },
                        { zIndex: 1 },
                    ]}
                >
                    <SubRiskIndicator
                        colors={floodRisk.colors}
                        icon={ICON_COMPONENTS.wave({
                            color: Colors.floodBlue,
                            size: IconSize.thirtyTwo,
                        })}
                        infos={subInfos}
                        severity={summary.subRisks.waterlevels}
                        title={
                            t({
                                defaultMessage: 'Submersion marine',
                            }) as string
                        }
                        zIndex={5}
                    >
                        {waterlevels.NX120_wl !== null ? (
                            <FloodElevation
                                title={
                                    t({
                                        defaultMessage:
                                            'Élévation des eaux 2030 : hauteur projetée',
                                    }) as string
                                }
                                score={waterlevels.NX120_wl}
                                step={50}
                                infos={floodElevationInfos}
                                zIndex={1}
                            />
                        ) : undefined}
                    </SubRiskIndicator>
                    <Separator direction={Direction.HORIZONTAL} />
                    <SubRiskIndicator
                        title={
                            t({
                                defaultMessage: 'Remontées de nappe',
                            }) as string
                        }
                        severity={summary.subRisks.remnas}
                        colors={floodRisk.colors}
                        icon={ICON_COMPONENTS.arrowUp({
                            color: Colors.floodBlue,
                            size: IconSize.twentyFour,
                        })}
                        infos={remnaInfos}
                        zIndex={2}
                    >
                        <Separator direction={Direction.HORIZONTAL} />
                        <ExistingRisk
                            title={
                                t({
                                    defaultMessage:
                                        'Risque d’inondation des sous-sol',
                                }) as string
                            }
                            riskExists={remnas.isUnderSeaLevelRiseBy2120Risk}
                            zIndex={2}
                        />
                        <ExistingRisk
                            title={
                                t({
                                    defaultMessage:
                                        'Risque de remontée de nappes au-dessus du sol',
                                }) as string
                            }
                            riskExists={remnas.isUnderUnderwaterFlowRisk}
                            zIndex={1}
                        />
                    </SubRiskIndicator>
                </View>
            </View>
        </View>
    )
})
FloodRiskSection.displayName = 'FloodRiskSection'

export default FloodRiskSection
