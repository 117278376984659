import { useContext } from 'react'
import {
    ServiceContext,
    ServicesContextType,
} from 'shared/contexts/ServiceContext'

const useServices = (): ServicesContextType => {
    const context = useContext(ServiceContext)
    return context
}

export default useServices
