export default function importAllMessages(): Record<
    string,
    Record<string, string>
> {
    const requiredContext = require.context('./lang', true, /\.json$/)
    const languages: Record<string, Record<string, string>> = {}

    requiredContext.keys().forEach((fileName: string) => {
        const lang = fileName.match(/\.\/(\w{2}(-\w{2})?)\.json$/)?.[1]
        if (lang) {
            languages[lang] = requiredContext(fileName)
        }
    })

    return languages
}
