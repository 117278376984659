import { StyleSheet } from 'react-native'

const topography = StyleSheet.create({
    background: {
        opacity: 0.018,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        position: 'absolute',
        zIndex: -1,
    },
})

export default topography
