import Svg, { Path } from 'react-native-svg'
import { DEFAULT_ICON_PROPS, IconProps } from '../icon.types'

const IconComponent: React.FC<IconProps> = function ({
    size = DEFAULT_ICON_PROPS.size,
    color = DEFAULT_ICON_PROPS.color,
}: IconProps) {
    return (
        <Svg width={size} height={size} viewBox="0 0 24 24">
            <Path
                fill={color}
                fillRule="evenodd"
                d="M12.705 4.705a.997.997 0 0 0-1.41 1.41L16.17 11H5a1 1 0 1 0 0 2h11.17l-4.876 4.885a.997.997 0 0 0 1.411 1.41L20 12l-7.295-7.295Z"
                clipRule="evenodd"
            />
        </Svg>
    )
}

export default IconComponent
