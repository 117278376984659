import Svg, { Path } from 'react-native-svg'
import { DEFAULT_ICON_PROPS, IconProps } from '../icon.types'

const IconComponent: React.FunctionComponent<IconProps> = function ({
    size = DEFAULT_ICON_PROPS.size,
    color = DEFAULT_ICON_PROPS.color,
}: IconProps) {
    return (
        <Svg width={size} height={size} fill="none" viewBox="0 0 24 24">
            <Path
                fill={color}
                fillRule="evenodd"
                d="M18.295 7.115a.997.997 0 1 0-1.41-1.41L12 10.59 7.115 5.705a.997.997 0 0 0-1.41 1.41L10.59 12l-4.885 4.885a.997.997 0 0 0 1.41 1.41L12 13.41l4.885 4.885a.997.997 0 1 0 1.41-1.41L13.41 12l4.885-4.885Z"
                clipRule="evenodd"
            />
        </Svg>
    )
}

export default IconComponent
