import { Image, ImageBackground, Pressable, View } from 'react-native'
import { ICONS } from 'shared/assets/icons'
import topography from 'shared/styles/topography.style'
import style from './ScreenHeader.style'
import { useFormatMessage } from 'i18n/hooks'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { ScreenStackList } from 'navigation/types'
import Screens from 'screens/screens'
import { FontType } from 'shared/styles/font_enums'
import StyledText from '../StyledText/StyledText'

type Props = {
    screen:
        | Screens.FavoriteAddresses
        | Screens.HazardsUnderstanding
        | Screens.Methodology
}
const ScreenHeader: React.FC<Props> = (props: Props) => {
    const t = useFormatMessage()

    const screenSetting = {
        [Screens.FavoriteAddresses]: {
            title: t({ defaultMessage: 'Mes adresses' }),
            subTitle: t({
                defaultMessage:
                    "Retrouvez ici toutes vos analyses d'adresses enregistrées",
            }),
            showButton: true,
        },
        [Screens.HazardsUnderstanding]: {
            title: t({ defaultMessage: 'Comprendre les aléas' }),
            subTitle: t({
                defaultMessage:
                    'Retrouvez ici toutes les notions concernant les aléas climatiques',
            }),
            showButton: false,
        },
        [Screens.Methodology]: {
            title: t({ defaultMessage: 'Méthodologie' }),
            subTitle: t({
                defaultMessage:
                    'Retrouvez ici nos différentes méthodes d’agrégations de la donnée et de calculs des indicateurs ainsi que la façon dont nous construisons les préconisations',
            }),
            showButton: false,
        },
    }

    const navigation =
        useNavigation<StackNavigationProp<ScreenStackList, Screens>>()

    const handleNewAddress = () => {
        navigation.navigate(Screens.Dashboard, { addNewAddress: true })
    }

    return (
        <View style={style.container}>
            <ImageBackground
                style={topography.background}
                source={ICONS.topography}
            />
            <StyledText weight={FontType.BOLD} style={style.title}>
                {screenSetting[props.screen].title}
            </StyledText>
            <View style={style.subTitleContainer}>
                <StyledText style={style.subTitle}>
                    {screenSetting[props.screen].subTitle}
                </StyledText>
                {screenSetting[props.screen].showButton ? (
                    <Pressable
                        style={style.pressable}
                        onPress={handleNewAddress}
                    >
                        <Image
                            style={style.magnifyingGlass}
                            source={ICONS.indigo.magnifyingGlass}
                        />
                        <StyledText
                            weight={FontType.SEMIBOLD}
                            style={style.textValue}
                        >
                            {
                                t({
                                    defaultMessage: 'NOUVELLE ADRESSE',
                                }) as string
                            }
                        </StyledText>
                    </Pressable>
                ) : null}
            </View>
        </View>
    )
}

export default ScreenHeader
