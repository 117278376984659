import { useFormatMessage } from 'i18n/hooks'
import { useCallback, useState } from 'react'
import { ImageBackground, Pressable, View } from 'react-native'
import { ICONS, ICON_COMPONENTS } from 'shared/assets/icons'
import topography from 'shared/styles/topography.style'
import style from './Tutorial.style'
import TutorialSection from './TutorialSection/TutorialSection'
import { TutorialContent } from './tutorial_content'
import { IconSize } from 'shared/styles/icon_enum'
import Colors from 'shared/styles/colors_enum'
import { FontType } from 'shared/styles/font_enums'
import StyledText from '../StyledText/StyledText'

type Props = {
    handleScrollToInput: () => void
}

const Tutorial: React.FC<Props> = (props: Props) => {
    const [showTutorial, setShowTutorial] = useState<boolean>(false)

    const t = useFormatMessage()

    const handleShowTutorial = useCallback(() => {
        setShowTutorial(!showTutorial)
    }, [showTutorial, setShowTutorial])

    return (
        <View style={style.container}>
            <ImageBackground
                style={topography.background}
                source={ICONS.topography}
            />
            <Pressable onPress={handleShowTutorial} style={style.pressable}>
                <View
                    style={{
                        ...style.arrowIconContainer,
                        transform: showTutorial ? [{ rotate: '90deg' }] : [],
                    }}
                >
                    {ICON_COMPONENTS.triangleArrow({
                        size: IconSize.twentyFour,
                        color: Colors.indigo,
                    })}
                </View>
                <StyledText weight={FontType.BOLD} style={style.title}>
                    {t({
                        defaultMessage: 'Comment ça marche ?',
                    })}
                </StyledText>
            </Pressable>
            {showTutorial && (
                <>
                    <View style={style.tutorialContainer}>
                        {TutorialContent.map((content, index) => {
                            const isLastElement =
                                index == TutorialContent.length - 1
                            return (
                                <TutorialSection
                                    key={`tutorial-section${index}`}
                                    index={index}
                                    content={content}
                                    isLastElement={isLastElement}
                                />
                            )
                        })}
                    </View>
                    <Pressable
                        style={style.button}
                        onPress={props.handleScrollToInput}
                    >
                        <StyledText
                            weight={FontType.SEMIBOLD}
                            style={style.buttonText}
                        >
                            {t({ defaultMessage: 'ANALYSER UNE ADRESSE' })}
                        </StyledText>
                    </Pressable>
                </>
            )}
        </View>
    )
}

export default Tutorial
