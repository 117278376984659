import { Platform, StyleSheet } from 'react-native'
import { Padding } from 'shared/styles/layout'
import Colors from '../../styles/colors_enum'

const style = StyleSheet.create({
    container: {
        padding: Padding.thirtySix,
        backgroundColor: Colors.lightGrey2,
        width: '100%',
    },
    title: {
        color: Colors.indigo,
        fontSize: 24,
        paddingBottom: 15,
        ...Platform.select({
            ios: {
                textAlign: 'center',
            },
        }),
    },
})

export default style
