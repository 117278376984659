import { Platform, StyleSheet } from 'react-native'
import { FontSize } from 'shared/styles/font_enums'
import Colors from '../../../styles/colors_enum'

const style = StyleSheet.create({
    subContainer: {
        flex: 3,
    },
    containerLinearGradiant: {
        backgroundColor: Colors.lightGrey,
        borderWidth: 0,
        borderRadius: 15,
    },
    linearGradient: {
        borderRadius: 15,
        height: '100%',
    },
    intensityContainer: {
        minWidth: 'fit-content',
        ...Platform.select({
            ios: {
                minWidth: 'auto',
            },
        }),
        alignSelf: 'flex-end',
    },
    intensityArrow: {
        width: 10,
        alignItems: 'flex-end',
        transform: [{ rotate: '-90deg' }],
    },
    intensityLabel: {
        textAlign: 'left',
        color: Colors.customBlack,
        fontSize: FontSize.twelve,
        textTransform: 'uppercase',
    },
    precisionsContainer: {
        width: '100%',
        paddingTop: 5,
    },
    precisions: {
        color: Colors.darkGrey,
        fontSize: FontSize.fourteen,
        textAlign: 'center',
    },
    gradations: {
        borderColor: Colors.white,
        borderLeftWidth: 1,
        position: 'absolute',
        height: '100%',
    },
})

export default style
