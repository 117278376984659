import { Platform, StyleSheet } from 'react-native'
import Colors from 'shared/styles/colors_enum'
import { FontSize, LetterSpacing } from 'shared/styles/font_enums'

const style = StyleSheet.create({
    container: {
        flexDirection: 'row',
        ...Platform.select({
            ios: {
                flexDirection: 'column',
            },
        }),
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 10,
        zIndex: 1,
    },
    inputContainer: {
        borderColor: 'transparent',
        borderWidth: 5,
        flexDirection: 'column',
        flex: 1,
        height: '100%',
        ...Platform.select({
            ios: {
                minHeight: 35,
                width: '100%',
                zIndex: 1,
                marginVertical: 10,
            },
        }),
    },
    pressable: {
        marginLeft: 10,
        padding: 3,
        borderRadius: 20,
    },
    buttonContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: Colors.indigo,
        color: Colors.white,
        borderRadius: 20,
        paddingRight: 30,
        paddingLeft: 18,
        paddingVertical: 10,
    },
    textInput: {
        backgroundColor: Colors.white,
        borderRadius: 20,
        borderColor: Colors.indigo,
        borderWidth: 1,
        color: Colors.customBlack,
        flex: 1,
        fontSize: FontSize.fourteen,
        minHeight: 35,
        marginRight: 2,
        outlineStyle: 'none',
        paddingHorizontal: 26,
        paddingVertical: 1,
    },
    textInputFocused: {
        borderWidth: 2,
        paddingHorizontal: 25,
        paddingVertical: 0,
    },
    textValue: {
        color: Colors.white,
        paddingLeft: 10,
        fontSize: FontSize.twelve,
        letterSpacing: LetterSpacing.small,
    },
    suggestionsContainer: {
        position: 'absolute',
        top: '100%',
        zIndex: 1,
        backgroundColor: 'transparent',
        width: '100%',
    },
    cancelContainer: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        width: 50,
        justifyContent: 'center',
        alignItems: 'center',
    },
    magnifyingGlass: {
        height: 15,
        width: 15,
    },
})

export default style
