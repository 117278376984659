import { StyleSheet } from 'react-native'

const style = StyleSheet.create({
    container: {
        position: 'absolute',
        top: 0,
        left: 0,
        marginLeft: 30,
        marginTop: 24,
        zIndex: 1,
    },
})

export default style
