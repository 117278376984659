import Layout from 'shared/components/Layout/Layout'

const withLayout = (Component: React.FC) => {
    const displayName = Component.displayName || Component.name || 'Component'
    const ComponentWithLayoutInfo = () => {
        return (
            <Layout>
                <Component />
            </Layout>
        )
    }
    ComponentWithLayoutInfo.displayName = `withLayout(${displayName})`

    return ComponentWithLayoutInfo
}

export default withLayout
