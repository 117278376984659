import { getRiskSeverity } from 'domain/types/risk_severity'
import { Analytics, AnalyticsService } from './../../domain/analytics_service'
import HttpClient from './client/http_client'
import { ROUTES } from './endpoints'

class HttpAnalyticsService implements AnalyticsService {
    private httpClient: HttpClient

    constructor(httpClient: HttpClient) {
        this.httpClient = httpClient
    }

    async get(): Promise<Analytics | undefined> {
        const httpResponse = await this.httpClient.client.get(ROUTES.analytics)
        const data = httpResponse.data

        return {
            allocation: {
                flood: data.high_risk_allocation.flood,
                drought: data.high_risk_allocation.drought,
                waterRestrictions: data.high_risk_allocation.waterRestrictions,
                combined: data.high_risk_allocation.combined,
            },
            summary: {
                flood: getRiskSeverity(data.analytics.flood),
                drought: getRiskSeverity(data.analytics.drought),
                waterRestrictions: getRiskSeverity(
                    data.analytics.waterRestrictions
                ),
            },
            address_count: data.address_count,
        }
    }
}

export default HttpAnalyticsService
