import { getLocales } from 'expo-localization'
import * as WebBrowser from 'expo-web-browser'
import { DEFAULT_LOCALE, SUPPORTED_EXTRA_LOCALE_CODES } from 'i18n/config'
import React from 'react'
import { IntlProvider } from 'react-intl'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import 'react-native-url-polyfill/auto'
import AuthProvider from 'shared/providers/AuthProvider/AuthProvider'
import ServicesProvider from 'shared/providers/ServicesProvider/ServicesProvider'
import SnackbarProvider from 'shared/providers/SnackbarProvider/SnackbarProvider'
import WindowProvider from 'shared/providers/WindowProvider/WindowProvider'
import importAllMessages from './i18n/import'
import NavigationWrapper from './navigation/NavigationWrapper'
import useCachedResources from './shared/hooks/useCachedResources'

WebBrowser.maybeCompleteAuthSession()

export default function App() {
    const isLoadingComplete = useCachedResources()
    const messages = importAllMessages()

    const supportedLocaleCodes = [
        DEFAULT_LOCALE,
        ...SUPPORTED_EXTRA_LOCALE_CODES,
    ]

    const locales = getLocales()
    const locale =
        locales.find(locale =>
            supportedLocaleCodes.includes(locale.languageCode)
        )?.languageCode || DEFAULT_LOCALE

    if (!isLoadingComplete) {
        return null
    } else {
        return (
            <IntlProvider
                locale={locale}
                key={locale}
                messages={messages[locale]}
            >
                <SafeAreaProvider>
                    <ServicesProvider>
                        <WindowProvider>
                            <AuthProvider>
                                <SnackbarProvider>
                                    <NavigationWrapper />
                                </SnackbarProvider>
                            </AuthProvider>
                        </WindowProvider>
                    </ServicesProvider>
                </SafeAreaProvider>
            </IntlProvider>
        )
    }
}
