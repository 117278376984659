enum Screens {
    AddressAnalysis = 'AddressAnalysis',
    Dashboard = 'Dashboard',
    FavoriteAddresses = 'FavoriteAddresses',
    HazardsUnderstanding = 'HazardsUnderstanding',
    Login = 'Login',
    Methodology = 'Methodology',
}

export default Screens
