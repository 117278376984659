import { useNavigation } from '@react-navigation/native'
import React, { useState } from 'react'
import { TouchableOpacity, View } from 'react-native'
import Screens from 'screens/screens'
import { ICON_COMPONENTS } from 'shared/assets/icons'
import useAuth from 'shared/hooks/authentication/useAuth'
import Colors from 'shared/styles/colors_enum'
import { IconSize } from 'shared/styles/icon_enum'
import style from './DropdownMenu.style'
import { menuBoxShadow } from 'shared/styles/layout'
import { useFormatMessage } from 'i18n/hooks'
import StyledText from '../StyledText/StyledText'
import { FontType } from 'shared/styles/font_enums'

const DropdownMenu: React.FC<unknown> = () => {
    const t = useFormatMessage()
    const [isVisible, setIsVisible] = useState<boolean>(false)
    const navigation = useNavigation()
    const { signOut } = useAuth()

    const handleOnPress = () => {
        setIsVisible(!isVisible)
    }

    const handleSignOut = () => {
        signOut()
    }
    const handleRedirection = (view: string) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        navigation.navigate(view)
        setIsVisible(false)
    }

    return (
        <View style={style.container}>
            <TouchableOpacity
                testID="menuButton"
                onPress={handleOnPress}
                style={[
                    style.menuButton,
                    isVisible && {
                        backgroundColor: Colors.white,
                        ...menuBoxShadow,
                    },
                ]}
            >
                {ICON_COMPONENTS.profile({
                    size: IconSize.twentyFour,
                    color: Colors.indigo,
                })}
                <View style={style.arrow}>
                    {isVisible
                        ? ICON_COMPONENTS.upArrow({
                              size: IconSize.sixteen,
                              color: Colors.indigo,
                          })
                        : ICON_COMPONENTS.downArrow({
                              size: IconSize.sixteen,
                              color: Colors.indigo,
                          })}
                </View>
            </TouchableOpacity>
            {isVisible && (
                <View style={style.menu}>
                    <TouchableOpacity
                        onPress={() => handleRedirection(Screens.Dashboard)}
                        style={style.item}
                    >
                        <StyledText
                            weight={FontType.MEDIUM}
                            style={style.itemText}
                        >
                            {t({ defaultMessage: 'Tableau de bord' }) as string}
                        </StyledText>
                    </TouchableOpacity>
                    <TouchableOpacity
                        onPress={() =>
                            handleRedirection(Screens.FavoriteAddresses)
                        }
                        style={style.item}
                    >
                        <StyledText
                            weight={FontType.MEDIUM}
                            style={style.itemText}
                        >
                            {t({ defaultMessage: 'Mes adresses' }) as string}
                        </StyledText>
                    </TouchableOpacity>
                    <TouchableOpacity
                        onPress={() => handleSignOut()}
                        style={style.item}
                    >
                        <StyledText
                            weight={FontType.MEDIUM}
                            style={style.itemText}
                        >
                            {t({ defaultMessage: 'Déconnexion' }) as string}
                        </StyledText>
                    </TouchableOpacity>
                </View>
            )}
        </View>
    )
}

export default DropdownMenu
