import { View } from 'react-native'
import RiskTooltip from '../../RiskTooltip/RiskTooltip'
import style from './RiskOccurences.style'
import StyledText from 'shared/components/StyledText/StyledText'
import { FontType } from 'shared/styles/font_enums'
import { v4 as uuidv4 } from 'uuid'

type Props = {
    infos?: string
    precisions?: string[]
    title: string
    occurences?: number
    zIndex?: number
}
const RiskOccurences: React.FC<Props> = (props: Props) => {
    const moreInfos = props.infos
    const occurences = props.occurences != undefined ? props.occurences : 0

    return (
        <View style={{ zIndex: props.zIndex, ...style.container }}>
            <View style={style.column}>
                <View style={style.row}>
                    <StyledText weight={FontType.BOLD} style={style.title}>
                        {props.title}
                    </StyledText>
                    <StyledText style={style.value}>
                        {`x${occurences}`}
                    </StyledText>
                </View>
                {props.precisions &&
                    props.precisions.map(precisions => {
                        const uuid = uuidv4()
                        return (
                            <StyledText
                                key={`precisions-${uuid}`}
                                style={style.precisions}
                            >
                                {precisions}
                            </StyledText>
                        )
                    })}
            </View>
            {moreInfos && <RiskTooltip content={moreInfos} />}
        </View>
    )
}

export default RiskOccurences
