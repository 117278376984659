import { IconProps } from 'shared/assets/icon.types'
import { ICON_COMPONENTS } from 'shared/assets/icons'
import Colors from 'shared/styles/colors_enum'

export interface RiskType {
    code: string
    description: string
    label: string
    iconFC?: React.FC<IconProps>
    colors: [string, string, string]
}

export const floodRisk: RiskType = {
    code: 'flood',
    description:
        'Une inondation correspond à la submersion temporaire de zones habituellement hors d’eau.',
    label: 'Inondation',
    iconFC: ICON_COMPONENTS.floodedHouse,
    colors: [Colors.turquoise, Colors.lightBlue, Colors.regularBlue],
}

export const droughtRisk: RiskType = {
    code: 'drought',
    description:
        'La sécheresse est un déficit anormal, sur une période prolongée, d’une des composantes du cycle de l’eau.',
    label: 'Sécheresse',
    iconFC: ICON_COMPONENTS.drought,
    colors: [Colors.yellow, Colors.orange, Colors.red],
}

export const waterRestrictions: RiskType = {
    code: 'waterRestrictions',
    description:
        'Mesures prises afin de préserver l’état des milieux naturels et de conserver les usages prioritaires.',
    label: "Restrictions d'eau",
    iconFC: ICON_COMPONENTS.noWater,
    colors: [Colors.lightPink, Colors.regularPink, Colors.darkPink],
}

export const risks: Record<string, RiskType> = {
    flood: floodRisk,
    drought: droughtRisk,
    waterRestrictions: waterRestrictions,
}

export const combinedRisk: RiskType = {
    code: 'combined',
    label: 'Risques combinés',
    colors: [Colors.vividPink, Colors.vividDarkPink, Colors.vividRed],
}
