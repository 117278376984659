import Svg, { Path } from 'react-native-svg'
import { DEFAULT_ICON_PROPS, IconProps } from '../icon.types'

const IconComponent: React.FunctionComponent<IconProps> = function ({
    size = DEFAULT_ICON_PROPS.size,
    color = DEFAULT_ICON_PROPS.color,
}: IconProps) {
    return (
        <Svg width={size} height={size} viewBox="0 0 32 32" fill="none">
            <Path
                fill={color}
                d="M16 32C7.163 32 0 24.837 0 16S7.163 0 16 0s16 7.163 16 16-7.163 16-16 16Zm0-2.667c7.364 0 13.333-5.97 13.333-13.333 0-7.364-5.97-13.333-13.333-13.333C8.636 2.667 2.667 8.637 2.667 16c0 7.364 5.97 13.333 13.333 13.333Z"
            />
            <Path
                fill={color}
                d="M12.787 20.773 7.93 15.918a1 1 0 0 0-1.411-.003l-.475.471a1 1 0 0 0-.002 1.417l6.744 6.744L24.92 12.279a1 1 0 0 0-.004-1.41l-.465-.465a1 1 0 0 0-1.419.004L12.787 20.773Z"
            />
        </Svg>
    )
}

export default IconComponent
