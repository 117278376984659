import React from 'react'
import { View } from 'react-native'
import style from './ProfileHeader.style'
import StyledText from '../StyledText/StyledText'

type Props = {
    name: string
}

const ProfileHeader: React.FC<Props> = (props: Props) => {
    return (
        <View style={style.container}>
            <StyledText
                style={style.text}
            >{`Bonjour, ${props.name} !`}</StyledText>
        </View>
    )
}

export default ProfileHeader
