import { FavoriteAddresses as FavoriteAddressesList } from 'domain/types/favorite_address'
import HttpError from 'infrastructure/http/http_error'
import React, { useCallback, useEffect, useState } from 'react'
import { ActivityIndicator, View } from 'react-native'
import ScreenHeader from 'shared/components/ScreenHeader/ScreenHeader'
import AddressesList from 'shared/components/AddressesList/AddressesList'
import useAuth from 'shared/hooks/authentication/useAuth'
import useServices from 'shared/hooks/useServices'
import useWindow from 'shared/hooks/useWindow'
import Colors from 'shared/styles/colors_enum'
import style from 'shared/styles/Screen.style'
import { adaptWidth } from 'shared/styles/window'
import Screens from 'screens/screens'
import { useIsFocused } from '@react-navigation/native'

const FavoriteAddresses: React.FC = () => {
    const [favoriteAddresses, setFavoriteAddresses] =
        useState<FavoriteAddressesList>([])
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const { resetUser } = useAuth()
    const { favoriteService } = useServices()
    const { isSmallWindow, isWeb } = useWindow()
    const isFocused = useIsFocused()
    const getFavoriteAddresses = useCallback(async () => {
        try {
            const response = await favoriteService.getAll()
            setFavoriteAddresses(response || [])
            setIsLoading(false)
        } catch (error: unknown) {
            if (error instanceof HttpError && error.status_code == 403) {
                resetUser()
            }
        }
    }, [favoriteService])

    useEffect(() => {
        if (isFocused) {
            getFavoriteAddresses()
        }
    }, [getFavoriteAddresses, isFocused])

    return isLoading ? (
        <ActivityIndicator color={Colors.indigo} size="large" />
    ) : (
        <View
            style={[
                style.screen,
                style.content,
                adaptWidth(isSmallWindow, isWeb),
            ]}
        >
            <ScreenHeader screen={Screens.FavoriteAddresses} />
            <AddressesList
                favoriteAddresses={favoriteAddresses}
                setFavoriteAddresses={setFavoriteAddresses}
            />
        </View>
    )
}

export default FavoriteAddresses
