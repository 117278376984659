import { Address } from 'domain/types/address'
import { View } from 'react-native'
import React from 'react'
import style from './Suggestions.style'
import {
    ScoredAddress,
    AddressSuggestions as SuggestionsType,
} from 'domain/types/address_suggestions'
import { useFormatMessage } from 'i18n/hooks'
import StyledText from 'shared/components/StyledText/StyledText'
import SuggestionElement from './SuggestionElement/SuggestionElement'

type Props = {
    handleSuggestionAsSearch: (address: Address) => void
    hoveredSuggestionIndex: number
    setHoveredSuggestionIndex: (index: number) => void
    suggestions: SuggestionsType | null | undefined
}
const Suggestions = (props: Props) => {
    const { suggestions } = props
    const t = useFormatMessage()
    const containerStyle = suggestions
        ? { ...style.container, padding: 4 }
        : style.container

    if (suggestions?.noResults)
        return (
            <View style={containerStyle}>
                <StyledText testID="noResultsMessage" style={style.warning}>
                    {t({
                        defaultMessage: 'Pas de résultats pour cette adresse.',
                    })}
                </StyledText>
            </View>
        )

    if (suggestions?.notEnoughCharacters)
        return (
            <View style={containerStyle}>
                <StyledText
                    testID="notEnoughCharactersMessage"
                    style={style.warning}
                >
                    {t({
                        defaultMessage:
                            "Veuillez entrer davantage d'informations pour trouver l'adresse.",
                    })}
                </StyledText>
            </View>
        )

    return (
        <View style={containerStyle}>
            {suggestions?.addresses?.map(
                (suggestion: ScoredAddress, index: number) => {
                    return (
                        <SuggestionElement
                            elementIndex={index}
                            textDifferenceIndex={
                                suggestion.indexOfFirstDifference
                            }
                            suggestion={suggestion.address}
                            key={`address-suggestion-${index}`}
                            {...props}
                        />
                    )
                }
            )}
        </View>
    )
}

export default Suggestions
