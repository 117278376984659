import { AddressService } from 'domain/address_service'
import { Address } from 'domain/types/address'
import {
    AddressSuggestions,
    ScoredAddress,
} from 'domain/types/address_suggestions'

type SetSuggestions = (elements: AddressSuggestions | null) => void

export const debounce = (
    callback: (
        text: string,
        sortSuggestions: (
            addresses: Address[],
            text: string
        ) => ScoredAddress[],
        setSuggestions: SetSuggestions,
        addressService: AddressService
    ) => Promise<void>,
    timeout = 300
) => {
    let timer: NodeJS.Timeout
    return (
        value: string,
        sort: (addresses: Address[], text: string) => ScoredAddress[],
        set: SetSuggestions,
        addressService: AddressService
    ) => {
        clearTimeout(timer)
        timer = setTimeout(() => {
            callback(value, sort, set, addressService)
        }, timeout)
    }
}

export const getIndexOfFirstDifference = (
    textToSearchThrough: string,
    textToSearchFor: string
) => {
    const lowerTextToSearchThrough = textToSearchThrough.toLowerCase()
    const lowerTextToSearchFor = textToSearchFor
        .toLowerCase()
        .split(/\s/)
        .join('')
    let indexOfFirstDifference = 0
    for (
        let i = 0;
        i < lowerTextToSearchFor.length &&
        indexOfFirstDifference < lowerTextToSearchThrough.length;
        i++
    ) {
        while (/\s/.test(lowerTextToSearchThrough[indexOfFirstDifference])) {
            indexOfFirstDifference++
        }
        if (
            lowerTextToSearchThrough[indexOfFirstDifference] !==
            lowerTextToSearchFor[i]
        ) {
            break
        }
        indexOfFirstDifference++
    }
    return indexOfFirstDifference
}

export const sortSuggestions = (addresses: Address[], text: string) => {
    const scoredAddresses = addresses.map(address => {
        const indexOfFirstDifference = getIndexOfFirstDifference(
            address.label,
            text
        )
        return {
            address: address,
            score: indexOfFirstDifference,
            indexOfFirstDifference: indexOfFirstDifference,
        }
    })
    const sortedSuggestions = scoredAddresses.sort(
        (address1, address2) => address2.score - address1.score
    )
    return sortedSuggestions.slice(0, 5)
}

export const changeSuggestions = debounce(
    async (
        text: string,
        sortSuggestions: (
            addresses: Address[],
            text: string
        ) => ScoredAddress[],
        setSuggestions: SetSuggestions,
        addressService: AddressService
    ) => {
        const minCharactersForSuggestions = 4
        if (text.length < minCharactersForSuggestions) setSuggestions(null)
        else {
            const suggestions = await addressService.get(text)
            if (suggestions) {
                const result = {
                    addresses: sortSuggestions(suggestions, text),
                    noResults: !suggestions.length,
                    notEnoughCharacters: false,
                }
                setSuggestions(result)
            }
        }
    }
)
