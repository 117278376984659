import { Address } from 'domain/types/address'
import { Pressable, View } from 'react-native'
import React from 'react'
import style from './SuggestionElement.style'
import StyledText from 'shared/components/StyledText/StyledText'
import { ICON_COMPONENTS } from 'shared/assets/icons'
import { IconSize } from 'shared/styles/icon_enum'
import Colors from 'shared/styles/colors_enum'
import { FontType } from 'shared/styles/font_enums'

type Props = {
    elementIndex: number
    textDifferenceIndex: number
    suggestion: Address
    hoveredSuggestionIndex: number
    setHoveredSuggestionIndex: (index: number) => void
    handleSuggestionAsSearch: (address: Address) => void
}
const SuggestionElement = (props: Props) => {
    const {
        elementIndex,
        textDifferenceIndex,
        suggestion,
        hoveredSuggestionIndex,
        setHoveredSuggestionIndex,
        handleSuggestionAsSearch,
    } = props

    const isHoveredOn = elementIndex === hoveredSuggestionIndex
    const highlightedSuggestionPart = suggestion.label.slice(
        0,
        textDifferenceIndex
    )
    const restOfSuggestion = suggestion.label.slice(textDifferenceIndex)

    return (
        <Pressable
            nativeID="addressSuggestion"
            testID="addressSuggestion"
            onPress={() => {
                handleSuggestionAsSearch(suggestion)
            }}
            onHoverIn={() => setHoveredSuggestionIndex(elementIndex)}
            onHoverOut={() => setHoveredSuggestionIndex(-1)}
            style={{
                ...style.contentContainer,
                backgroundColor: isHoveredOn ? Colors.lightGrey2 : Colors.white,
            }}
        >
            {ICON_COMPONENTS.localizationPin({
                size: IconSize.twentyFour,
                color: isHoveredOn ? Colors.indigo : Colors.grey2,
            })}
            <StyledText style={style.suggestionText}>
                <StyledText weight={FontType.BOLD}>
                    {highlightedSuggestionPart}
                </StyledText>
                {restOfSuggestion}
            </StyledText>
            {isHoveredOn ? (
                <View style={style.arrowIcon}>
                    {ICON_COMPONENTS.arrowBack({
                        size: IconSize.twentyFour,
                        color: Colors.indigo,
                    })}
                </View>
            ) : null}
        </Pressable>
    )
}

export default SuggestionElement
