export enum RiskType {
    DROUGHT = 'drought',
    EXZECO = 'exzeco',
    PPRIS = 'ppris',
    REMNAS = 'remnas',
    RGARGS = 'rgargs',
    SURFRESTRIS = 'surfrestris',
    UNDRESTRIS = 'undrestris',
    TRIS = 'tris',
    WATERCOURSES = 'watercourses',
    WATERLEVELS = 'waterlevels',
}

export type FloodData = {
    [RiskType.EXZECO]: {
        exzecoRuiL: number
        exzecoRuiL_50m: number
    }
    [RiskType.PPRIS]: {
        gasparId: string[]
        ppriDebL: number
        ppriRemL: number
        ppriRuiL: number
        ppriSubL: number
        pprnSTs: string[]
        pprnTs: string[]
        soumisalea: number
    }
    [RiskType.REMNAS]: {
        remnaR: string
        remnaRemL: number
        remnaT: string
        isUnderSeaLevelRiseBy2120Risk: boolean
        isUnderUnderwaterFlowRisk: boolean
    }
    [RiskType.SURFRESTRIS]: {
        surPrplRsH: string
        surPrplRsL: number
    }
    [RiskType.UNDRESTRIS]: {
        undPrplRsH: string
        undPrplRsL: number
    }
    [RiskType.TRIS]: {
        triDebL: number
        triId: string[]
        triRemL: number
        triRuiL: number
        triSubL: number
        triT: string[]
    }
    [RiskType.WATERCOURSES]: {
        waterC: string
        waterCode: string
        waterD: number
        waterN: string
    }
    [RiskType.WATERLEVELS]: {
        NH040_wl: number
        NN040_wl: number
        NX040_wl: number
        PM040_wl: number
        NH070_wl: number
        NN070_wl: number
        NX070_wl: number
        PM070_wl: number
        NH120_wl: number
        NN120_wl: number
        NX120_wl: number
        PM120_wl: number
    }
}

export interface DroughtData {
    [RiskType.DROUGHT]: {
        H1A2: {
            H1A2mtDtL: number
            H1A2slDtL: number
            H1A2spiL: number
            H1A2sswiL: number
        }
        H1A1?: {
            H1A1mtDtL: number
            H1A1slDtL: number
            H1A1spiL: number
            H1A1sswiL: number
        }
        H2A1B?: {
            H2A1BmtDtL: number
            H2A1BslDtL: number
            H2A1BspiL: number
            H2A1BsswiL: number
        }
        H2A2?: {
            H2A2BmtDtL: number
            H2A2BslDtL: number
            H2A2BspiL: number
            H2A2BsswiL: number
        }
        H2B1?: {
            H2B1BmtDtL: number
            H2B1BslDtL: number
            H2B1BspiL: number
            H2B1BsswiL: number
        }
        H1B1?: {
            H1B1mtDtL: number
            H1B1slDtL: number
            H1B1spiL: number
            H1B1sswiL: number
        }
        H3A1B?: {
            H3A1BmtDtL: number
            H3A1BslDtL: number
            H3A1BspiL: number
            H3A1BsswiL: number
        }
        H3A2?: {
            H3A2mtDtL: number
            H3A2slDtL: number
            H3A2spiL: number
            H3A2sswiL: number
        }
        H3B1?: {
            H3B1mtDtL: number
            H3B1slDtL: number
            H3B1spiL: number
            H3B1sswiL: number
        }
    }
    [RiskType.RGARGS]: {
        rgargL: number
    }
}

export type WaterRestrictionsData = {
    [RiskType.SURFRESTRIS]: {
        surPrplRsH: string
        surPrplRsL: number
    }
    [RiskType.UNDRESTRIS]: {
        undPrplRsH: string
        undPrplRsL: number
    }
}
