import Svg, { Path } from 'react-native-svg'
import { DEFAULT_ICON_PROPS, IconProps } from '../icon.types'

const IconComponent: React.FC<IconProps> = function ({
    size = DEFAULT_ICON_PROPS.size,
    color = DEFAULT_ICON_PROPS.color,
}: IconProps) {
    return (
        <Svg width={size} height={size} fill={color} viewBox="0 0 20 20">
            <Path d="M10.8327 10.8333V15C10.8327 15.4602 10.4596 15.8333 9.99935 15.8333C9.53911 15.8333 9.16602 15.4602 9.16602 15V10.8333H4.99935C4.53911 10.8333 4.16602 10.4602 4.16602 9.99996C4.16602 9.53972 4.53911 9.16663 4.99935 9.16663H9.16602V4.99996C9.16602 4.53972 9.53911 4.16663 9.99935 4.16663C10.4596 4.16663 10.8327 4.53972 10.8327 4.99996V9.16663H14.9993C15.4596 9.16663 15.8327 9.53972 15.8327 9.99996C15.8327 10.4602 15.4596 10.8333 14.9993 10.8333H10.8327Z" />
        </Svg>
    )
}

export default IconComponent
