import React from 'react'
import { GestureResponderEvent, Pressable } from 'react-native'
import style from './RedirectButton.style'
import StyledText from '../StyledText/StyledText'
import { FontType } from 'shared/styles/font_enums'

type Props = {
    label: string
    onPress: null | ((event: GestureResponderEvent) => void) | undefined
}

const RedirectButton = (props: Props) => {
    return (
        <Pressable style={style.container} onPress={props.onPress}>
            <StyledText weight={FontType.SEMIBOLD} style={style.text}>
                {props.label}
            </StyledText>
        </Pressable>
    )
}

export default RedirectButton
