import { Pressable, TextInput, View } from 'react-native'
import { ICON_COMPONENTS } from 'shared/assets/icons'
import Colors from 'shared/styles/colors_enum'
import { IconSize } from 'shared/styles/icon_enum'
import style from './SearchInputList.style'

type Props = {
    search: string
    setSearch: (text: string) => void
}

const SearchInputList: React.FC<Props> = (props: Props) => {
    const isEmptySearch = props.search.length === 0
    const handleSearch = (typedText: string) => {
        props.setSearch(typedText)
    }

    return (
        <View style={style.container}>
            <View style={style.textInputContainer}>
                <TextInput
                    testID="textInputFavoriteAddresses"
                    placeholder="Rechercher une adresse enregistrée"
                    placeholderTextColor="#96A2A8"
                    style={style.textInput}
                    onChangeText={handleSearch}
                    value={props.search}
                />
                {isEmptySearch ? null : (
                    <Pressable
                        style={style.cancelSearch}
                        onPress={() => props.setSearch('')}
                        testID="cancelSearch"
                    >
                        {ICON_COMPONENTS.cross({
                            size: IconSize.sixteen,
                            color: Colors.placeholderGrey,
                        })}
                    </Pressable>
                )}
            </View>
        </View>
    )
}

export default SearchInputList
