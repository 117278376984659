import { StyleSheet } from 'react-native'
import Colors from 'shared/styles/colors_enum'
import { FontSize } from 'shared/styles/font_enums'
import { Padding } from 'shared/styles/layout'

const style = StyleSheet.create({
    container: {
        flexDirection: 'column',
        paddingHorizontal: Padding.thirtySix,
    },
    title: {
        color: Colors.indigo,
        fontSize: FontSize.twentyFour,
        paddingBottom: 15,
    },
    content: {
        flexDirection: 'row',
        zIndex: 1,
    },
    column: {
        justifyContent: 'flex-start',
        flexDirection: 'column',
        paddingVertical: Padding.thirtySix,
    },
})

export default style
