import { StyleSheet } from 'react-native'
import Colors from 'shared/styles/colors_enum'
import { FontSize } from 'shared/styles/font_enums'

const style = StyleSheet.create({
    subRisk: {
        flexDirection: 'row',
        zIndex: 2,
    },
    subIndicatorTitle: {
        flex: 3,
        opacity: 1,
        color: Colors.customBlack,
        fontSize: FontSize.fourteen,
        textAlign: 'left',
        lineHeight: 17,
    },
    subRiskIcon: {
        paddingRight: 10,
    },
    progressBarContainer: { flex: 3 },
    childrenWrapper: {
        zIndex: 1,
    },
})

export default style
