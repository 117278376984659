import { Platform, StyleSheet } from 'react-native'
import Colors from 'shared/styles/colors_enum'
import { FontSize } from 'shared/styles/font_enums'

const style = StyleSheet.create({
    menu: {
        alignItems: 'center',
        flex: 8,
        flexDirection: 'row',
        justifyContent: 'center',
        paddingTop: 41,
        ...Platform.select({
            ios: {
                flexDirection: 'column',
                justifyContent: 'flex-end',
                paddingTop: 0,
                width: '100%',
            },
        }),
    },
    link: {
        alignItems: 'center',
        color: Colors.indigo,
        display: 'flex',
        flexDirection: 'row',
        fontFamily: 'plus-jakarta-sans-semiBold',
        fontSize: FontSize.twelve,
        gap: 2,
        letterSpacing: 0.2,
        ...Platform.select({
            ios: {
                fontSize: FontSize.ten,
            },
        }),
    },
    icon: {
        color: Colors.indigo,
    },
    separator: {
        alignItems: 'center',
        color: Colors.indigo,
        display: 'flex',
        margin: 0,
        fontSize: 18,
        paddingHorizontal: 15,
        paddingVertical: 0,
        ...Platform.select({
            ios: {
                fontSize: FontSize.ten,
            },
        }),
    },
})

export default style
