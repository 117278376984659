import Svg, { Path } from 'react-native-svg'
import { DEFAULT_ICON_PROPS, IconProps } from '../icon.types'

const IconComponent: React.FC<IconProps> = function ({
    size = DEFAULT_ICON_PROPS.size,
    color = DEFAULT_ICON_PROPS.color,
}: IconProps) {
    return (
        <Svg width={size} height={size} viewBox="0 0 68 68">
            <Path
                fill={color}
                d="M53.865 2h-39.72c-2.39 0-4.33 1.94-4.33 4.33v55.35c0 2.39 1.94 4.32 4.33 4.32h39.72c2.38 0 4.32-1.93 4.32-4.32V6.33c0-2.39-1.93-4.33-4.32-4.33zm-28.48 55.75c0 .87-.7 1.57-1.57 1.57h-4.84c-.87 0-1.57-.7-1.57-1.57v-4.28c0-.87.7-1.57 1.57-1.57h4.84c.87 0 1.57.7 1.57 1.57zm0-12c0 .86-.7 1.56-1.57 1.56h-4.84c-.87 0-1.57-.7-1.57-1.56v-4.29c0-.86.7-1.56 1.57-1.56h4.84c.87 0 1.57.7 1.57 1.56zm0-12c0 .86-.7 1.56-1.57 1.56h-4.84c-.87 0-1.57-.7-1.57-1.56v-4.29c0-.86.7-1.57 1.57-1.57h4.84c.87 0 1.57.71 1.57 1.57zm12.6 24c0 .87-.7 1.57-1.56 1.57h-4.85c-.86 0-1.56-.7-1.56-1.57v-4.29c0-.86.7-1.56 1.56-1.56h4.85c.86 0 1.56.7 1.56 1.56zm0-12c0 .86-.7 1.56-1.56 1.56h-4.85c-.86 0-1.56-.7-1.56-1.56v-4.29c0-.86.7-1.56 1.56-1.56h4.85c.86 0 1.56.7 1.56 1.56zm0-12c0 .86-.7 1.56-1.56 1.56h-4.85c-.86 0-1.56-.7-1.56-1.56v-4.29c0-.86.7-1.57 1.56-1.57h4.85c.86 0 1.56.71 1.56 1.57zm12.61 24c0 .87-.7 1.57-1.56 1.57h-4.85c-.86 0-1.56-.7-1.56-1.57v-4.29c0-.86.7-1.56 1.56-1.56h4.85c.86 0 1.56.7 1.56 1.56zm0-12c0 .86-.7 1.56-1.56 1.56h-4.85c-.86 0-1.56-.7-1.56-1.56v-4.29c0-.86.7-1.56 1.56-1.56h4.85c.86 0 1.56.7 1.56 1.56zm0-12c0 .86-.7 1.56-1.56 1.56h-4.85c-.86 0-1.56-.7-1.56-1.56v-4.29c0-.86.7-1.57 1.56-1.57h4.85c.86 0 1.56.71 1.56 1.57zm1.59-14.9c0 1.34-1.09 2.43-2.43 2.43h-31.51c-1.34 0-2.43-1.09-2.43-2.43v-8.42c0-1.34 1.09-2.42 2.43-2.42h31.51c1.34 0 2.43 1.08 2.43 2.42z"
            />
        </Svg>
    )
}

export default IconComponent
