type floodElevationInfos = {
    waterLevel: number
    text: string
}

const waterLevelsOptions = {
    20: {
        text: [
            '0\u00A0cm',
            'entre\u00A00\u00A0et\u00A020\u00A0cm',
            'entre\u00A020\u00A0et\u00A040\u00A0cm',
            'entre\u00A040\u00A0et\u00A060\u00A0cm',
            'entre\u00A060\u00A0et\u00A080\u00A0cm',
            'entre\u00A080\u00A0et\u00A0100\u00A0cm',
            '>1\u00A0m',
        ],
        formula: (index: number) => Math.min(index, 5) / 5,
    },
    50: {
        text: [
            '0\u00A0cm',
            'entre\u00A00\u00A0et\u00A050\u00A0cm',
            'entre\u00A050\u00A0cm\u00A0et\u00A01\u00A0m',
            'entre\u00A01\u00A0et\u00A02\u00A0m',
            'entre\u00A02\u00A0et\u00A03\u00A0m',
            '>3\u00A0m',
        ],
        formula: (index: number) => (index >= 3 ? 2 : index / 2),
    },
}

const formatFloodElevation = (
    score: number,
    step: 20 | 50
): floodElevationInfos => {
    const waterLevelsInfos = waterLevelsOptions[step]
    const textWaterLevels = waterLevelsInfos.text
    const formulaWaterLevels = waterLevelsInfos.formula

    const maxScore = textWaterLevels.length - 1

    const index = Math.max(Math.min(score, maxScore), 0)

    const waterLevel = formulaWaterLevels(index)

    return { waterLevel, text: textWaterLevels[index] }
}

export default { formatFloodElevation }
