import Svg, { Path } from 'react-native-svg'
import { DEFAULT_ICON_PROPS, IconProps } from '../icon.types'

const IconComponent: React.FunctionComponent<IconProps> = function ({
    size = DEFAULT_ICON_PROPS.size,
    color = DEFAULT_ICON_PROPS.color,
}: IconProps) {
    return (
        <Svg width={size} height={size} fill={color} viewBox="0 0 21 24">
            <Path d="M12.857 0c-.79 0-1.428.638-1.428 1.429 0 .79.638 1.428 1.428 1.428h2.264L10 7.977l-5.12-5.12h2.263c.79 0 1.428-.638 1.428-1.428C8.571.639 7.933 0 7.143 0H1.429C.639 0 0 .638 0 1.429v5.714c0 .79.638 1.428 1.429 1.428.79 0 1.428-.638 1.428-1.428V4.879L7.977 10l-5.12 5.12v-2.263c0-.79-.638-1.428-1.428-1.428-.79 0-1.429.638-1.429 1.428v5.714C0 19.361.638 20 1.429 20h5.714c.79 0 1.428-.638 1.428-1.429 0-.79-.638-1.428-1.428-1.428H4.879L10 12.023l5.12 5.12h-2.263c-.79 0-1.428.638-1.428 1.428 0 .79.638 1.429 1.428 1.429h5.714c.79 0 1.429-.638 1.429-1.429v-5.714c0-.79-.638-1.428-1.429-1.428-.79 0-1.428.638-1.428 1.428v2.264L12.023 10l5.12-5.12v2.263c0 .79.638 1.428 1.428 1.428.79 0 1.429-.638 1.429-1.428V1.429C20 .639 19.362 0 18.571 0h-5.714Z" />
        </Svg>
    )
}

export default IconComponent
