import Colors from 'shared/styles/colors_enum'
import { StyleSheet } from 'react-native'

const style = StyleSheet.create({
    container: {
        width: 14,
        height: '100%',
        backgroundColor: Colors.lightGrey2,
        borderRadius: 8,
    },
    content: {
        width: 10,
        backgroundColor: '#A1AEB7',
        borderRadius: 8,
        margin: 2,
    },
})

export default style
