// This should replace colors from colors.ts over time

enum Colors {
    white = '#FFFFFF',
    black = '#000000',
    transparentBlack = '#0000004D',
    hoverGrey = '#A5B7B8',
    customBlack = '#272927',
    lighterGrey = '#EAECED',
    lightGrey = '#F4F5F6',
    lightGrey2 = '#F6F8F9',
    lightGrey3 = '#C2D8D9',
    grey = '#98A4AA',
    grey2 = '#A1B5B7',
    grey3 = '#A1AEB7',
    placeholderGrey = '#96A2A8',
    shadowGrey = '#E8EBF7',
    precisionsGrey = '#637575',
    darkGrey = '#708585',
    greenButton = '#04AB03',
    hoverGreen = '#43D043',
    darkerGrey = '#333',
    indigo = '#3B3BE2',
    hoverIndigo = '#6161FF',
    turquoise = '#72E6FF',
    pictoBlue = '#00A5E4',
    lightBlue = '#00CDF7',
    regularBlue = '#00AAE9',
    yellow = '#FFF200',
    orange = '#FFBB34',
    red = '#FF6338',
    alarmingRed = '#E03100',
    lightPink = '#F5DDD8',
    regularPink = '#FA7BA1',
    darkPink = '#FF2F66',
    lightestTurquoise = '#EFFCFB',
    lightestPink = '#FBF6FF',
    successGreen = '#F2FEDF',
    darkGreen = '#00AC05',
    tutorialRed = '#FF3D15',
    tutorialPink = '#FC4F8A',
    tutorialFuchsia = '#F75FFF',
    vividPink = '#D40EBF',
    vividDarkPink = '#D513B0',
    vividRed = '#E12E00',
    green = '#00AC05',
    floodBlue = '#00BEF2',
    droughtOrange = '#FFAA00',
    waterRestrictionsPink = '#FF8C8C',
}

export default Colors
