import Svg, { Path } from 'react-native-svg'
import { DEFAULT_ICON_PROPS, IconProps } from '../icon.types'

const IconComponent: React.FC<IconProps> = function ({
    size = DEFAULT_ICON_PROPS.size,
    color = DEFAULT_ICON_PROPS.color,
}: IconProps) {
    return (
        <Svg width={size} height={size} viewBox="0 0 72 72">
            <Path
                fill={color}
                d="M13.5,13.5C13.5,9.4,16.9,6,21,6h19.4
                c2,0,3.9,0.8,5.3,2.2l10.6,10.6c1.4,1.4,2.2,3.3,2.2,5.3v34.4c0,4.1-3.4,7.5-7.5,7.5H21c-4.1,0-7.5-3.4-7.5-7.5V13.5z M19.1,58.5
                c0,1,0.8,1.9,1.9,1.9h30c1,0,1.9-0.8,1.9-1.9V24.8h-9.4c-2.1,0-3.8-1.7-3.8-3.8v-9.4H21c-1,0-1.9,0.8-1.9,1.9V58.5z M27.6,36
                c-1.6,0-2.8,1.3-2.8,2.8s1.3,2.8,2.8,2.8h16.9c1.6,0,2.8-1.3,2.8-2.8S46,36,44.4,36H27.6z M24.8,50.1c0-1.6,1.3-2.8,2.8-2.8h16.9
                c1.6,0,2.8,1.3,2.8,2.8s-1.3,2.8-2.8,2.8H27.6C26,52.9,24.8,51.6,24.8,50.1z"
            />
        </Svg>
    )
}

export default IconComponent
