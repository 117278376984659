import Svg, { Path } from 'react-native-svg'
import { DEFAULT_ICON_PROPS, IconProps } from '../icon.types'

const IconComponent: React.FC<IconProps> = function ({
    size = DEFAULT_ICON_PROPS.size,
    color = DEFAULT_ICON_PROPS.color,
}: IconProps) {
    return (
        <Svg width={size} height={size} viewBox="0 0 24 24">
            <Path
                fill={color}
                fillRule="evenodd"
                d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7Zm0 9.5a2.5 2.5 0 0 1 0-5 2.5 2.5 0 0 1 0 5Z"
                clipRule="evenodd"
            />
        </Svg>
    )
}

export default IconComponent
