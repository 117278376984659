import { StyleSheet } from 'react-native'
import Colors from 'shared/styles/colors_enum'
import { FontSize, LetterSpacing } from 'shared/styles/font_enums'
import { IconSize } from 'shared/styles/icon_enum'
import { Margin, Padding } from 'shared/styles/layout'

const style = StyleSheet.create({
    container: {
        padding: Padding.thirtySix,
        backgroundColor: Colors.lightGrey2,
    },
    arrowIconContainer: {
        height: IconSize.twentyFour,
        width: IconSize.twentyFour,
        justifyContent: 'center',
        marginRight: 5,
    },
    pressable: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    title: {
        color: Colors.indigo,
        fontSize: FontSize.twentyFour,
    },
    tutorialContainer: {
        marginVertical: Margin.thirtySix,
        alignItems: 'center',
    },
    buttonText: {
        color: Colors.indigo,
        fontSize: FontSize.twelve,
        letterSpacing: LetterSpacing.small,
    },
    button: {
        alignItems: 'center',
        alignSelf: 'center',
        backgroundColor: 'transparent',
        flexDirection: 'row',
        borderColor: Colors.indigo,
        borderRadius: 20,
        borderWidth: 1,
        paddingHorizontal: 30,
        paddingVertical: 10,
    },
})

export default style
