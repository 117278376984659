import { StyleSheet } from 'react-native'
import Colors from 'shared/styles/colors_enum'
import { FontSize } from 'shared/styles/font_enums'

const style = StyleSheet.create({
    contentContainer: {
        height: 40,
        alignItems: 'center',
        borderRadius: 3,
        paddingHorizontal: 4,
        flexDirection: 'row',
    },
    suggestionText: {
        flex: 1,
        marginHorizontal: 12,
        color: Colors.customBlack,
        fontSize: FontSize.fourteen,
    },
    arrowIcon: {
        transform: [{ rotate: '180deg' }],
        marginHorizontal: 4,
    },
})

export default style
