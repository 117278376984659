import { fetchUserInfoAsync, refreshAsync } from 'expo-auth-session'
import appConfiguration from 'infrastructure/appConfiguration'
import { discovery } from 'infrastructure/authentication/configuration'
import { useEffect, useState } from 'react'
import { UserIdentity } from 'shared/contexts/AuthContext'
import useServices from '../useServices'

const useRefresh = (): UserIdentity | undefined => {
    const [user, setUser] = useState<UserIdentity>()
    const { credentialsStorage } = useServices()

    const getUserInfo = async (accessToken: string) => {
        try {
            const userInfo = await fetchUserInfoAsync(
                { accessToken: accessToken },
                discovery
            )
            setUser({
                name: userInfo.name,
            })
        } catch (error) {
            throw new Error('Invalid token')
        }
    }

    const refreshUser = async (refreshToken: string | undefined) => {
        try {
            const refreshInfos = await refreshAsync(
                {
                    clientId: appConfiguration.HOZEE_IDP_CLIENT_ID,
                    refreshToken: refreshToken,
                },
                discovery
            )
            await getUserInfo(refreshInfos.accessToken)
            await credentialsStorage.set(
                refreshInfos.accessToken,
                refreshInfos.refreshToken
            )
        } catch (error) {
            credentialsStorage.clear()
        }
    }

    useEffect(() => {
        const refresh = async () => {
            const { accessToken, refreshToken } = await credentialsStorage.get()
            if (accessToken !== null) {
                try {
                    await getUserInfo(accessToken)
                } catch (error) {
                    if (refreshToken !== null) {
                        await refreshUser(refreshToken)
                    }
                }
            }
        }
        refresh()
    }, [])
    return user
}

export default useRefresh
