import Constants from 'expo-constants'

const {
    HOZEE_MOBILE_APP_URL,
    HOZEE_BASE_URL,
    HOZEE_API_URL,
    HOZEE_IDP_CLIENT_ID,
    HOZEE_IDP_FRONT_URL,
    HOZEE_IDP_REDIRECT_URI_WEB,
    HOZEE_IDP_REDIRECT_URI_MOBILE,
} = Constants.manifest.extra

export default {
    HOZEE_MOBILE_APP_URL,
    HOZEE_BASE_URL,
    HOZEE_API_URL,
    HOZEE_IDP_CLIENT_ID,
    HOZEE_IDP_FRONT_URL,
    HOZEE_IDP_REDIRECT_URI_WEB,
    HOZEE_IDP_REDIRECT_URI_MOBILE,
}
