import { Platform, StyleSheet } from 'react-native'
import { FontSize } from 'shared/styles/font_enums'
import Colors from '../../styles/colors_enum'
import { Padding } from 'shared/styles/layout'

const style = StyleSheet.create({
    container: {
        flex: 1,
        flexGrow: 1,
        maxWidth: 200,
        position: 'absolute',
        paddingTop: Padding.thirtySix,
        ...Platform.select({
            ios: {
                paddingTop: 60,
                justifyContent: 'flex-start',
            },
        }),
        right: 85,
        top: 5,
    },
    text: {
        color: Colors.indigo,
        fontSize: FontSize.sixteen,
    },
})

export default style
