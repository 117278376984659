import { StyleSheet } from 'react-native'
import Colors from 'shared/styles/colors_enum'
import { FontSize } from 'shared/styles/font_enums'
import { Margin } from 'shared/styles/layout'

const style = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'space-evenly',
        flex: 1,
    },
    highRisksContainer: {
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    chartContainer: {
        paddingHorizontal: 5,
        justifyContent: 'center',
        alignItems: 'center',
    },
    analysisNumber: {
        marginHorizontal: Margin.eight,
        textAlign: 'center',
        color: Colors.customBlack,
    },
    analysisText: {
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
    },
    analyzedAddresses: {
        textAlign: 'center',
        color: Colors.customBlack,
        fontSize: FontSize.fourteen,
    },
    addressesContainer: {
        position: 'absolute',
        justifyContent: 'center',
        alignItems: 'center',
    },
    highRisksText: {
        color: Colors.customBlack,
    },
})

export default style
