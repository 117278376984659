enum Level {
    NO_DATA = -2,
    NEARLY_NON_EXISTENT = -1,
    VERY_LOW = 0,
    LOW = 1,
    MEDIUM = 2,
    STRONG = 3,
}

export interface RiskSeverity {
    noData?: boolean
    label: string
    progress: number
}

export const RiskSeverities = {
    low: {
        label: 'Faible',
        progress: Level.LOW,
    },
    medium: {
        label: 'Moyen',
        progress: Level.MEDIUM,
    },
    nearlyNonExistent: {
        label: 'Très faible voire inexistant',
        progress: Level.NEARLY_NON_EXISTENT,
    },
    noData: {
        noData: true,
        label: "Pas d'information disponible",
        progress: Level.NO_DATA,
    },
    strong: {
        label: 'Fort',
        progress: Level.STRONG,
    },
    veryLow: {
        label: 'Très faible',
        progress: Level.VERY_LOW,
    },
}

export const getRiskSeverity = (value: number): RiskSeverity => {
    switch (value) {
        case Level.NEARLY_NON_EXISTENT:
            return RiskSeverities.nearlyNonExistent
        case Level.VERY_LOW:
            return RiskSeverities.veryLow
        case Level.LOW:
            return RiskSeverities.low
        case Level.MEDIUM:
            return RiskSeverities.medium
        case Level.STRONG:
            return RiskSeverities.strong
        default:
            return RiskSeverities.noData
    }
}
