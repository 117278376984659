import { StyleSheet } from 'react-native'
import Colors from 'shared/styles/colors_enum'
import { menuBoxShadow } from 'shared/styles/layout'

const style = StyleSheet.create({
    container: {
        position: 'absolute',
        right: 20,
        top: 27,
    },
    menuButton: {
        alignItems: 'flex-end',
        flexDirection: 'row',
        justifyItems: 'center',
        padding: 8,
    },
    menu: {
        ...menuBoxShadow,
        alignSelf: 'flex-end',
        backgroundColor: Colors.white,
        padding: 15,
        position: 'absolute',
        top: 38,
        width: 180,
    },
    item: {
        color: Colors.indigo,
        flexDirection: 'row',
        alignItems: 'center',
    },
    icon: {
        color: Colors.indigo,
    },
    itemText: {
        color: Colors.indigo,
        fontSize: 16,
        padding: 10,
        whiteSpace: 'nowrap',
    },
    arrow: {
        paddingLeft: 6,
    },
})

export default style
