import { RiskSeverities, RiskSeverity } from 'domain/types/risk_severity'
import { LinearGradient } from 'expo-linear-gradient'
import React from 'react'
import { View } from 'react-native'
import Colors from 'shared/styles/colors_enum'
import style from './RiskProgressBar.style'
import { ICON_COMPONENTS } from 'shared/assets/icons'
import { IconSize } from 'shared/styles/icon_enum'
import { FontType } from 'shared/styles/font_enums'
import StyledText from 'shared/components/StyledText/StyledText'

type Props = {
    colors: Array<string>
    sub?: boolean
    severity: RiskSeverity
}

export const riskSeverityStep = (step: number) => Math.max((100 / 3) * step, 0)

const RiskProgressBar: React.FC<Props> = (props: Props) => {
    const noData = props.severity.noData
    const riskSeverityProgress = riskSeverityStep(props.severity.progress)
    const missingProgressProportion = 100 - riskSeverityProgress
    const containerSize = { height: props.sub ? 20 : 30 }
    const numberGradations = props.severity.progress
    const alignIntensityText =
        props.severity.progress === RiskSeverities.strong.progress
            ? 'flex-end'
            : 'flex-start'

    const gradations = []
    // here to make linearGradiants colors evolve depending on the risk level
    const gradientColors = [props.colors[0], props.colors[1]]

    for (let i = 1; i < numberGradations; i++) {
        gradations.push(
            <View
                style={{ ...style.gradations, left: `${riskSeverityStep(i)}%` }}
                key={`gradations-${i}`}
            />
        )
        gradientColors.push(props.colors[i])
    }

    return (
        <View style={[{ height: 'auto' }, props.sub && style.subContainer]}>
            <LinearGradient
                start={{ x: 0, y: 1 }}
                end={{ x: 0, y: 0 }}
                colors={[Colors.lightGrey2, Colors.lighterGrey]}
                style={[style.containerLinearGradiant, containerSize]}
            >
                <LinearGradient
                    testID="linearGradient"
                    start={{ x: 0, y: 0 }}
                    end={{ x: 1, y: 0 }}
                    colors={gradientColors}
                    style={{
                        ...style.linearGradient,
                        width: `${riskSeverityProgress}%`,
                    }}
                />
                {gradations}
            </LinearGradient>
            {noData ? (
                <View style={style.precisionsContainer}>
                    <StyledText
                        weight={FontType.SEMIBOLD}
                        style={style.precisions}
                    >
                        {props.severity.label}
                    </StyledText>
                </View>
            ) : (
                <View
                    testID="intensityText"
                    style={{
                        ...style.intensityContainer,
                        width: `${missingProgressProportion}%`,
                        alignItems: alignIntensityText,
                    }}
                >
                    <View style={style.intensityArrow}>
                        {ICON_COMPONENTS.triangleArrow({
                            size: IconSize.twelve,
                            color: Colors.customBlack,
                        })}
                    </View>
                    <StyledText
                        weight={FontType.SEMIBOLD}
                        style={style.intensityLabel}
                    >
                        {props.severity.label}
                    </StyledText>
                </View>
            )}
        </View>
    )
}

export default RiskProgressBar
