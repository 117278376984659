enum IconSize {
    ten = 10,
    twelve = 12,
    sixteen = 16,
    twenty = 20,
    twentyFour = 24,
    thirtyTwo = 32,
    eighty = 80,
    ninetySix = 96,
}

export { IconSize }
