import {
    Modal,
    Pressable,
    TouchableOpacity,
    TouchableWithoutFeedback,
    View,
} from 'react-native'
import style from './ConfirmModal.style'
import { useFormatMessage } from 'i18n/hooks'
import { FontType } from 'shared/styles/font_enums'
import StyledText from '../StyledText/StyledText'

type Props = {
    onConfirm: () => void
    onCancel: () => void
    visible: boolean
    children?: JSX.Element | JSX.Element[]
}

const ConfirmModal: React.FC<Props> = (props: Props) => {
    const t = useFormatMessage()

    return (
        <Modal
            animationType="none"
            transparent={true}
            visible={props.visible}
            onRequestClose={() => {
                props.onCancel()
            }}
        >
            <TouchableOpacity
                style={style.background}
                activeOpacity={1}
                onPressOut={() => props.onCancel()}
            >
                <TouchableWithoutFeedback>
                    <View style={style.container}>
                        {props.children}
                        <View style={style.buttonsContainer}>
                            <Pressable
                                style={style.confirmButton}
                                onPress={() => props.onConfirm()}
                            >
                                <StyledText
                                    weight={FontType.SEMIBOLD}
                                    style={style.confirmText}
                                >
                                    {
                                        t({
                                            defaultMessage: 'CONFIRMER',
                                        }) as string
                                    }
                                </StyledText>
                            </Pressable>
                            <Pressable
                                style={style.cancelButton}
                                onPress={() => props.onCancel()}
                            >
                                <StyledText
                                    weight={FontType.SEMIBOLD}
                                    style={style.cancelText}
                                >
                                    {t({ defaultMessage: 'ANNULER' }) as string}
                                </StyledText>
                            </Pressable>
                        </View>
                    </View>
                </TouchableWithoutFeedback>
            </TouchableOpacity>
        </Modal>
    )
}

export default ConfirmModal
