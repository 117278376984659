import { Defs, LinearGradient, Stop } from 'react-native-svg'

export enum Direction {
    VERTICAL = 'vertical',
    HORIZONTAL = 'horizontal',
}
type Props = {
    id: string
    offsets: {
        start: number
        middle: number
        end: number
    }
    colors: {
        start: string
        middle: string
        end: string
    }
    angle: number
    direction: Direction
}

const CurveGradient: React.FC<Props> = (props: Props) => {
    const angle = (props.angle / 2) * (Math.PI / 180)
    const topCoordinates = {
        x: 50,
        y: 0,
    }
    const bottomCoordinates = {
        x: 50,
        y: 100,
    }
    const origin = {
        x: 50,
        y: 50,
    }
    const getCoordinatesAfterRotation = (
        x: number,
        y: number,
        angle: number,
        origin: { x: number; y: number }
    ) => {
        const newX = Math.min(
            Math.max(
                origin.x +
                    (x - origin.x) * Math.cos(angle) -
                    (y - origin.y) * Math.sin(angle),
                0
            ),
            100
        )
        const newY = Math.min(
            Math.max(
                origin.y +
                    (x - origin.x) * Math.sin(angle) +
                    (y - origin.y) * Math.cos(angle),
                0
            ),
            100
        )
        return { x: newX, y: newY }
    }

    const { x: topX, y: topY } = getCoordinatesAfterRotation(
        topCoordinates.x,
        topCoordinates.y,
        angle,
        origin
    )
    const { x: bottomX, y: bottomY } = getCoordinatesAfterRotation(
        bottomCoordinates.x,
        bottomCoordinates.y,
        angle,
        origin
    )

    return (
        <Defs key={props.id}>
            <LinearGradient
                id={props.id}
                x1={`${topX}%`}
                y1={`${topY}%`}
                x2={`${bottomX}%`}
                y2={`${bottomY}%`}
                gradientUnits="userSpaceOnUse"
            >
                <Stop
                    offset={props.offsets.start}
                    stopColor={props.colors.start}
                />
                <Stop
                    offset={props.offsets.middle}
                    stopColor={props.colors.middle}
                />
                <Stop offset={props.offsets.end} stopColor={props.colors.end} />
            </LinearGradient>
        </Defs>
    )
}

export default CurveGradient
