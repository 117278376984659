import { StyleSheet } from 'react-native'
import Colors from 'shared/styles/colors_enum'
import { Margin } from 'shared/styles/layout'

const style = StyleSheet.create({
    highRisksLegendContainer: {
        flexDirection: 'row',
        backgroundColor: Colors.lightGrey2,
        borderRadius: 6,
        padding: 6,
        maxWidth: 800,
    },
    legendColumn: {
        flexDirection: 'column',
        flex: 1,
    },
    legendCell: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        margin: 6,
    },
    analysisNumber: {
        marginHorizontal: Margin.eight,
        textAlign: 'center',
        color: Colors.customBlack,
        alignSelf: 'center',
    },
    tooltipOuterContainer: {
        justifyContent: 'flex-end',
    },
    tooltipInnerContainer: {
        height: '50%',
        paddingTop: 6,
        left: -10,
        alignItems: 'center',
    },
})

export default style
