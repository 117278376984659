import AsyncStorage from '@react-native-async-storage/async-storage'
import CredentialsStorage, {
    Credentials,
} from 'domain/storage/credentials_storage'

enum ASYNC_STORAGE_KEYS {
    ACCESS_TOKEN = 'accessToken',
    REFRESH_TOKEN = 'refreshToken',
}

class WebAsyncCredentialsStorage implements CredentialsStorage {
    async set(
        accessToken: string,
        refreshToken: string | undefined
    ): Promise<void> {
        await AsyncStorage.setItem(ASYNC_STORAGE_KEYS.ACCESS_TOKEN, accessToken)

        if (refreshToken) {
            await AsyncStorage.setItem(
                ASYNC_STORAGE_KEYS.REFRESH_TOKEN,
                refreshToken
            )
        }
    }

    async get(): Promise<Credentials> {
        const accessToken = await AsyncStorage.getItem(
            ASYNC_STORAGE_KEYS.ACCESS_TOKEN
        )
        const refreshToken = await AsyncStorage.getItem(
            ASYNC_STORAGE_KEYS.REFRESH_TOKEN
        )

        return {
            accessToken,
            refreshToken,
        }
    }

    async clear(): Promise<void> {
        await AsyncStorage.removeItem(ASYNC_STORAGE_KEYS.ACCESS_TOKEN)
        await AsyncStorage.removeItem(ASYNC_STORAGE_KEYS.REFRESH_TOKEN)
    }
}

export default WebAsyncCredentialsStorage
