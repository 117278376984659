import { Platform, StyleSheet } from 'react-native'

const style = StyleSheet.create({
    container: {
        marginHorizontal: 'auto',
        marginVertical: 'auto',
        height: '90vh',
        justifyContent: 'center',
        ...Platform.select({
            android: {
                height: '90%',
                justifyContent: 'flex-start',
            },
            ios: {
                height: '90%',
                justifyContent: 'flex-start',
            },
        }),
    },
    title: {
        alignSelf: 'center',
        color: '#3B3BE2',
        flexWrap: 'nowrap',
        fontSize: 35,
        lineHeight: 48,
        marginBottom: 36,
        maxWidth: '60%',
        textAlign: 'center',
    },
    subContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 56,
    },
    doLogin: {
        alignItems: 'center',
        backgroundColor: '#3B3BE2',
        borderRadius: 22.5,
        flexDirection: 'row',
        marginHorizontal: 'auto',
        height: 40,
        paddingVertical: 13,
        paddingHorizontal: 36,
        ...Platform.select({
            android: {
                marginVertical: 36,
                marginHorizontal: 36,
                justifyContent: 'center',
            },
            ios: {
                marginVertical: 36,
                marginHorizontal: 36,
                justifyContent: 'center',
            },
        }),
    },
    doLoginLabel: {
        color: '#FFFFFF',
        marginLeft: 3,
        fontSize: 12,
        textAlign: 'center',
        lineHeight: 15,
        letterSpacing: 0.5,
    },
})

export default style
