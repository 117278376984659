import { Platform, StyleSheet } from 'react-native'
import Colors from 'shared/styles/colors_enum'
import { FontSize, LetterSpacing } from 'shared/styles/font_enums'
import { Margin, Padding } from 'shared/styles/layout'

const style = StyleSheet.create({
    container: {
        backgroundColor: Colors.lightGrey2,
        flexDirection: 'column',
        flex: 1,
        gap: 8,
        paddingHorizontal: Padding.thirtySix,
        paddingTop: Padding.thirty,
        paddingBottom: Padding.fourtyFive,
        marginBottom: Margin.thirty,
    },
    backButton: {
        flexDirection: 'row',
        flexShrink: 1,
    },
    headerTitle: {
        alignSelf: 'center',
        color: Colors.indigo,
        flexWrap: 'nowrap',
        flexShrink: 0,
        fontSize: FontSize.twentyFour,
        marginLeft: 16,
    },
    address: {
        color: Colors.customBlack,
        fontSize: FontSize.twentyFour,
    },
    header: {
        alignItems: 'center',
        color: Colors.indigo,
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'center',
        ...Platform.select({
            ios: {
                alignItems: 'flex-start',
                flexDirection: 'column',
                justifyContent: 'flex-start',
            },
            android: {
                flexWrap: 'wrap',
            },
        }),
    },
    actions: {
        alignSelf: 'flex-end',
        alignItems: 'center',
        marginLeft: 'auto',
        flexDirection: 'row',
        flexWrap: 'nowrap',
    },
    link: {
        alignItems: 'center',
        flexDirection: 'row',
        marginVertical: 10,
    },
    linkMargin: {
        marginHorizontal: 30,
        gap: 6,
    },
    linkText: {
        color: Colors.indigo,
        fontSize: FontSize.twelve,
        letterSpacing: LetterSpacing.small,
        ...Platform.select({
            ios: {
                paddingVertical: 5,
            },
        }),
        paddingLeft: 6,
        textAlign: 'left',
    },
    modalTitle: {
        color: Colors.indigo,
        fontSize: FontSize.twentyFour,
        lineHeight: 30,
        marginBottom: 10,
    },
    modalTextContainer: {
        alignItems: 'center',
    },
    modalText: {
        color: Colors.customBlack,
        fontSize: FontSize.fourteen,
        lineHeight: 22,
    },
    modalAddress: {
        color: Colors.customBlack,
        fontSize: FontSize.fourteen,
        lineHeight: 22,
    },
})

export default style
