import {
    SubRiskRules,
    RiskInformation,
    ProgressBarRules,
} from 'domain/progress_bar_rules'
import { RisksData, RisksSummary } from 'domain/types/risk_at_address'

export type RisksInformation = {
    flood: RiskInformation
    drought: RiskInformation
    waterRestrictions: RiskInformation
}

export const getRisksToSummarize = (risksData: RisksData): RisksInformation => {
    const {
        flood: {
            exzeco: { exzecoRuiL },
            ppris: { ppriDebL },
            remnas: { remnaRemL },
            waterlevels: { NX120_wl },
        },
        drought: {
            drought: {
                H1A2: { H1A2mtDtL, H1A2slDtL },
            },
            rgargs: { rgargL },
        },
        waterRestrictions: {
            surfrestris: { surPrplRsL },
            undrestris: { undPrplRsL },
        },
    } = risksData

    const floodInformation = {
        ppris: {
            value: ppriDebL,
            levels: SubRiskRules.PPRIS,
        },
        exzeco: {
            value: exzecoRuiL,
            levels: SubRiskRules.EXZECO,
        },
        remnas: {
            value: remnaRemL,
            levels: SubRiskRules.REMNAS,
        },
        waterlevels: {
            value: NX120_wl,
            levels: SubRiskRules.WATERLEVELS,
        },
    }

    const droughtInformation = {
        H1A2mtDtL: {
            value: H1A2mtDtL,
            levels: SubRiskRules.H1A2,
        },
        H1A2slDtL: {
            value: H1A2slDtL,
            levels: SubRiskRules.H1A2,
        },
        rgargs: {
            value: rgargL,
            levels: SubRiskRules.RGARGL,
        },
    }

    const waterRestrictionsInformation = {
        surfrestris: {
            value: surPrplRsL,
            levels: SubRiskRules.SURFRESTRIS,
        },
        undrestris: {
            value: undPrplRsL,
            levels: SubRiskRules.UNDRESTRIS,
        },
    }

    return {
        flood: floodInformation,
        drought: droughtInformation,
        waterRestrictions: waterRestrictionsInformation,
    }
}

export const buildRiskSummary = (
    risks: RisksData,
    progressBarRules: ProgressBarRules
): RisksSummary => {
    const {
        flood: floodRiskInfos,
        drought: droughtRiskInfos,
        waterRestrictions: waterRestrictionsInfos,
    } = getRisksToSummarize(risks)

    const floodSummary = progressBarRules.summarize(floodRiskInfos)
    const droughtSummary = progressBarRules.summarize(droughtRiskInfos)
    const waterRestrictionsSummary = progressBarRules.summarize(
        waterRestrictionsInfos
    )

    return {
        flood: floodSummary,
        drought: droughtSummary,
        waterRestrictions: waterRestrictionsSummary,
    }
}
