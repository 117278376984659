import Svg, {
    Defs,
    LinearGradient,
    RadialGradient,
    Path,
    Stop,
} from 'react-native-svg'
import { DEFAULT_ICON_PROPS, IconProps } from '../icon.types'

const IconComponent: React.FC<IconProps> = function ({
    size = DEFAULT_ICON_PROPS.size,
}: IconProps) {
    return (
        <Svg width={size} height={size} viewBox="0 0 80 80">
            <Path
                fill="url(#a)"
                d="M32.709 40.749s2.349-4.13.123-5.83l3.049 2.226s.827 1.873-1.656 5.292l-1.516-1.688Z"
            />
            <Path
                fill="url(#b)"
                d="m37.016 43.35-2.791-.913 1.656-5.292 6.476 1.732s-2.733.655-5.341 4.473Z"
            />
            <Path
                fill="url(#c)"
                d="M65.739 9.58c-.401-1.51-1.112-2.858-2.151-3.98h.004l.009.01c0-.004-.004-.004-.004-.01l1.698 1.22a62.679 62.679 0 0 0-.793-.676c0-.004 0-.004-.004-.004-.866-.728-2.19-1.802-2.754-2.06l.039.03c-3.173-1.987-7.651-2.091-12.35-.517a27.52 27.52 0 0 0-5.026 2.285 32.913 32.913 0 0 0-6.026 4.492c-8.742 8.177-11.634 19.285-6.466 24.807a8.79 8.79 0 0 0 2.384 1.81c.46.24.94.448 1.444.616.082.03.168.06.254.082.642.203 1.319.349 2.022.444h.009c.625.082 1.272.12 1.94.108 1.66-.01 3.43-.285 5.246-.823.017-.004.034-.01.052-.013 3.96-1.168 8.125-3.552 11.828-7.022 4.539-4.242 7.5-9.272 8.578-13.906.57-2.47.61-4.828.067-6.893Zm-2.164-3.996h-.004c-.004-.004-.01-.01-.01-.013.01.004.01.009.014.013Zm-7.31 23.842c-7.237 6.768-16.35 8.78-20.36 4.496-3.06-3.272-2.258-9.268 1.514-14.932l.142-.211a30.65 30.65 0 0 1 4.004-4.689c.078-.073.15-.147.228-.22l.44-.401c.147-.134.293-.263.44-.388 7.074-6.1 15.596-7.772 19.436-3.668 4.009 4.284 1.393 13.245-5.844 20.013Z"
            />
            <Path
                fill="url(#d)"
                d="M65.959 7.394a8.405 8.405 0 0 0-1.457-1.25c0-.004 0-.004-.004-.004a8.476 8.476 0 0 0-.901-.539c-.004-.009-.013-.009-.017-.013l-.004-.004h-.004c-3.306-1.74-7.841-1.638-12.535.112a27.886 27.886 0 0 0-5.022 2.48 33.229 33.229 0 0 0-5.237 4.016c-7.763 7.263-10.914 16.837-7.897 22.76v.004c.237.466.509.905.823 1.32a9.104 9.104 0 0 0 .608.723c.388.418.815.79 1.267 1.116.134.1.272.194.414.284 1.25.802 2.703 1.31 4.302 1.53 5.647.79 13.048-1.979 19.199-7.729 8.737-8.172 11.633-19.28 6.465-24.806Zm-24.165 6.474.44-.4c.147-.135.293-.264.44-.389C49.748 6.98 58.27 5.307 62.11 9.411c4.009 4.285 1.392 13.246-5.845 20.014-7.237 6.768-16.35 8.781-20.36 4.496-3.06-3.272-2.258-9.268 1.514-14.932.047-.069.095-.142.147-.21a30.65 30.65 0 0 1 4-4.689c.077-.075.15-.149.228-.222Z"
            />
            <Path
                fill="#CFCFFF"
                d="M35.47 46.474 16.21 76.846l-.128.197-.234.278c-.469.374-1.219.43-2.005.179-.358-.112-1.174-.49-1.523-.731-.912-.632-1.44-1.392-1.447-2.105a1.195 1.195 0 0 1 .194-.626l.211-.345 19.238-30.558 3.888 2.62 1.065.719Z"
            />
            <Path
                fill="url(#e)"
                d="M65.738 9.58a7.867 7.867 0 0 0-.905-1.168c-4.72-5.048-15.186-2.923-23.38 4.746-8.2 7.664-11.014 17.966-6.298 23.01.405.43.849.81 1.328 1.138.478.323.987.595 1.53.819l.004.004h.009c1.259.522 2.69.776 4.229.78 4.979.009 11.087-2.625 16.285-7.483 7.603-7.117 10.578-16.501 7.198-21.846Zm-28.174 9.199a30.65 30.65 0 0 1 4-4.689c.078-.073.15-.147.228-.22l.44-.401c.147-.134.293-.263.44-.388 7.074-6.1 15.596-7.772 19.436-3.668 4.009 4.285 1.392 13.246-5.845 20.014-7.237 6.768-16.35 8.78-20.36 4.496-3.06-3.272-2.258-9.268 1.514-14.932.048-.07.096-.143.147-.212Z"
            />
            <Path
                fill="url(#f)"
                d="M35.47 46.475s1.068.057 1.232-1.292c.164-1.35.313-1.832.313-1.832l-2.791-.914-1.516-1.688s-1.096 1.195-1.67 1.399c-.572.204-.523.991-.523.991s.904 1.407 2.419 2.377c1.517.969 2.536.959 2.536.959Z"
            />
            <Path
                fill="url(#g)"
                d="M65.668 16.475c-.553 4.492-3.399 9.745-8.075 14.118-3.907 3.655-8.343 5.995-12.332 6.81-3.889.797-7.353.145-9.495-2.144-3.355-3.586-2.48-10.143 1.651-16.267-3.77 5.665-4.573 11.66-1.514 14.933 4.008 4.284 13.123 2.27 20.36-4.498 7.236-6.768 9.852-15.728 5.846-20.013-4-4.276-13.087-2.28-20.317 4.459l.084-.08c7.716-7.216 17.488-9.305 21.828-4.665 1.711 1.825 2.322 4.426 1.964 7.347Z"
            />
            <Path
                fill="url(#h)"
                d="M56.264 29.424c-7.235 6.77-16.351 8.782-20.36 4.498-3.058-3.271-2.256-9.266 1.515-14.932.047-.071.096-.142.144-.212a30.58 30.58 0 0 1 4.003-4.691c.076-.072.152-.146.229-.216 7.23-6.738 16.315-8.734 20.316-4.46 4.006 4.286 1.39 13.245-5.847 20.014Z"
            />
            <Defs>
                <LinearGradient
                    id="a"
                    x1="43.006"
                    x2="15.853"
                    y1="46.102"
                    y2="24.509"
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop offset=".042" stopColor="#465D74" />
                    <Stop offset=".369" stopColor="#A9B5C5" />
                    <Stop offset=".376" stopColor="#B8C2CF" />
                    <Stop offset=".388" stopColor="#CED5DE" />
                    <Stop offset=".402" stopColor="#DFE3E9" />
                    <Stop offset=".42" stopColor="#EAEEF1" />
                    <Stop offset=".445" stopColor="#F1F3F6" />
                    <Stop offset=".514" stopColor="#F3F5F7" />
                    <Stop offset=".532" stopColor="#E2E6EA" />
                    <Stop offset=".584" stopColor="#B9C2CB" />
                    <Stop offset=".638" stopColor="#96A3B0" />
                    <Stop offset=".696" stopColor="#79899A" />
                    <Stop offset=".756" stopColor="#627689" />
                    <Stop offset=".822" stopColor="#52687D" />
                    <Stop offset=".897" stopColor="#496076" />
                    <Stop offset="1" stopColor="#465D74" />
                </LinearGradient>
                <LinearGradient
                    id="b"
                    x1="22.945"
                    x2="30.009"
                    y1="49.906"
                    y2="44.853"
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop offset=".042" stopColor="#465D74" />
                    <Stop offset=".145" stopColor="#A9B5C5" />
                    <Stop offset=".16" stopColor="#B8C2CF" />
                    <Stop offset=".19" stopColor="#CED5DE" />
                    <Stop offset=".224" stopColor="#DFE3E9" />
                    <Stop offset=".267" stopColor="#EAEEF1" />
                    <Stop offset=".327" stopColor="#F1F3F6" />
                    <Stop offset=".493" stopColor="#F3F5F7" />
                    <Stop offset=".512" stopColor="#E2E6EA" />
                    <Stop offset=".566" stopColor="#B9C2CB" />
                    <Stop offset=".623" stopColor="#96A3B0" />
                    <Stop offset=".683" stopColor="#79899A" />
                    <Stop offset=".746" stopColor="#627689" />
                    <Stop offset=".815" stopColor="#52687D" />
                    <Stop offset=".893" stopColor="#496076" />
                    <Stop offset="1" stopColor="#465D74" />
                </LinearGradient>
                <LinearGradient
                    id="c"
                    x1="32.356"
                    x2="61.588"
                    y1="39.377"
                    y2="3.266"
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#7C879C" />
                    <Stop offset=".322" stopColor="#9FAABA" />
                    <Stop offset=".773" stopColor="#D5E0E8" />
                    <Stop offset="1" stopColor="#8391A5" />
                </LinearGradient>
                <LinearGradient
                    id="d"
                    x1="97.492"
                    x2="30.024"
                    y1="52.203"
                    y2="9.44"
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop offset=".042" stopColor="#465D74" />
                    <Stop offset=".145" stopColor="#A9B5C5" />
                    <Stop offset=".16" stopColor="#B8C2CF" />
                    <Stop offset=".19" stopColor="#CED5DE" />
                    <Stop offset=".224" stopColor="#DFE3E9" />
                    <Stop offset=".267" stopColor="#EAEEF1" />
                    <Stop offset=".327" stopColor="#F1F3F6" />
                    <Stop offset=".493" stopColor="#F3F5F7" />
                    <Stop offset=".512" stopColor="#E2E6EA" />
                    <Stop offset=".566" stopColor="#B9C2CB" />
                    <Stop offset=".623" stopColor="#96A3B0" />
                    <Stop offset=".683" stopColor="#79899A" />
                    <Stop offset=".746" stopColor="#627689" />
                    <Stop offset=".815" stopColor="#52687D" />
                    <Stop offset=".893" stopColor="#496076" />
                    <Stop offset="1" stopColor="#465D74" />
                </LinearGradient>
                <LinearGradient
                    id="e"
                    x1="32.308"
                    x2="82.319"
                    y1="41.285"
                    y2="-12.415"
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#7C879C" />
                    <Stop offset=".322" stopColor="#9FAABA" />
                    <Stop offset=".773" stopColor="#D5E0E8" />
                </LinearGradient>
                <LinearGradient
                    id="f"
                    x1="37.468"
                    x2="27.59"
                    y1="46.153"
                    y2="39.301"
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#475B80" />
                    <Stop offset=".656" stopColor="#2C3D51" />
                    <Stop offset="1" stopColor="#21313E" />
                </LinearGradient>
                <LinearGradient
                    id="g"
                    x1="32.873"
                    x2="66.62"
                    y1="29.68"
                    y2="14.696"
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#A9B5C5" />
                    <Stop offset=".106" stopColor="#B9C3D0" />
                    <Stop offset=".311" stopColor="#D3D9E1" />
                    <Stop offset=".524" stopColor="#E5E9ED" />
                    <Stop offset=".748" stopColor="#EFF2F5" />
                    <Stop offset="1" stopColor="#F3F5F7" />
                </LinearGradient>
                <RadialGradient
                    id="h"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="rotate(124.979 22.918 18.307) scale(28.034 15.4791)"
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop offset=".503" stopColor="#D6E1FB" stopOpacity=".1" />
                    <Stop offset="1" stopColor="#A0C9F5" stopOpacity=".6" />
                </RadialGradient>
            </Defs>
        </Svg>
    )
}

export default IconComponent
