import { Address } from 'domain/types/address'
import { AddressSuggestions as SuggestionsType } from 'domain/types/address_suggestions'
import { forwardRef, LegacyRef, useRef, useState } from 'react'
import { Pressable, TextInput, View } from 'react-native'
import { ICON_COMPONENTS } from 'shared/assets/icons'
import useServices from 'shared/hooks/useServices'
import { changeSuggestions, sortSuggestions } from './helper'
import style from './SearchInput.style'
import Suggestions from './Suggestions/Suggestions'
import { useFormatMessage } from 'i18n/hooks'
import { FontType } from 'shared/styles/font_enums'
import StyledText from 'shared/components/StyledText/StyledText'
import Colors from 'shared/styles/colors_enum'
import { IconSize } from 'shared/styles/icon_enum'
import { KeyCode } from 'domain/types/keyboard'

type Props = {
    onPress: (selectedAddress: Address) => void
}

const SearchInput: React.FC<Props> = forwardRef((props: Props, ref) => {
    const analysisButtonRef = useRef<View>(null)
    const t = useFormatMessage()
    const [text, setText] = useState('')
    const [isInputFocused, setIsInputFocused] = useState<boolean>(false)
    const { addressService } = useServices()
    const [suggestions, setSuggestions] = useState<SuggestionsType | null>()
    const [selectedAddress, setSelectedAddress] = useState<Address | null>()

    const handleOnpress = () => {
        const minCharactersForSuggestions = 4
        const hasEnoughCharacters = text.length >= minCharactersForSuggestions
        if (hasEnoughCharacters) {
            const hasSuggestions = suggestions?.addresses.length
            if (selectedAddress) props.onPress(selectedAddress)
            else if (hasSuggestions)
                props.onPress(suggestions.addresses[0].address)
        } else if (!hasEnoughCharacters) {
            const results = {
                addresses: [],
                noResults: false,
                notEnoughCharacters: true,
            }
            setSuggestions(results)
            if (selectedAddress) setSelectedAddress(null)
        }
    }

    const handleSuggestionAsSearch = (address: Address) => {
        setText(address.label)
        setSelectedAddress(address)
        setSuggestions(null)
        setHoveredSuggestionIndex(0)
    }

    const handleOnChangeText = (text: string) => {
        setText(text)
        if (selectedAddress) setSelectedAddress(null)
        changeSuggestions(text, sortSuggestions, setSuggestions, addressService)
    }
    const [hoveredSuggestionIndex, setHoveredSuggestionIndex] =
        useState<number>(0)
    return (
        <View style={style.container}>
            <View style={style.inputContainer}>
                <TextInput
                    testID="textInputAddressAnalyzer"
                    placeholder="Ex : 7 All. du Port Maillard, 44000 Nantes"
                    placeholderTextColor="#96A2A8"
                    onKeyPress={event => {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        const currentKeycode = event.nativeEvent.keyCode
                        if (suggestions && suggestions.addresses.length > 0) {
                            if (currentKeycode === KeyCode.ARROW_DOWN) {
                                event.preventDefault()
                                setHoveredSuggestionIndex(
                                    Math.min(
                                        hoveredSuggestionIndex + 1,
                                        (suggestions.addresses.length || 0) - 1
                                    )
                                )
                            } else if (currentKeycode === KeyCode.ARROW_UP) {
                                event.preventDefault()
                                setHoveredSuggestionIndex(
                                    Math.max(hoveredSuggestionIndex - 1, -1)
                                )
                            } else if (currentKeycode === KeyCode.ENTER) {
                                event.preventDefault()
                                if (hoveredSuggestionIndex >= 0) {
                                    const suggestion =
                                        suggestions.addresses[
                                            hoveredSuggestionIndex
                                        ].address
                                    handleSuggestionAsSearch(suggestion)
                                }
                            }
                        }
                        if (currentKeycode === KeyCode.TAB && selectedAddress) {
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            ref.current.blur()
                        }
                    }}
                    style={[
                        style.textInput,
                        {
                            fontFamily: text.length
                                ? 'plus-jakarta-sans-regular'
                                : 'plus-jakarta-sans-light',
                        },
                        isInputFocused ? style.textInputFocused : {},
                    ]}
                    onFocus={() => setIsInputFocused(true)}
                    onBlur={({ nativeEvent }) =>
                        setTimeout(() => {
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            if (nativeEvent.relatedTarget?.id == null)
                                setIsInputFocused(false)
                        }, 100)
                    }
                    onChangeText={text => handleOnChangeText(text)}
                    ref={ref as LegacyRef<TextInput>}
                    value={text}
                />
                {isInputFocused ? (
                    <View style={style.suggestionsContainer}>
                        <Suggestions
                            handleSuggestionAsSearch={handleSuggestionAsSearch}
                            hoveredSuggestionIndex={hoveredSuggestionIndex}
                            setHoveredSuggestionIndex={
                                setHoveredSuggestionIndex
                            }
                            suggestions={suggestions}
                        />
                    </View>
                ) : null}
                {selectedAddress ? (
                    <View style={style.cancelContainer}>
                        <Pressable
                            onPress={() => {
                                setText('')
                                setSelectedAddress(null)
                                setIsInputFocused(false)
                            }}
                        >
                            {ICON_COMPONENTS.cross({
                                size: IconSize.twenty,
                                color: Colors.placeholderGrey,
                            })}
                        </Pressable>
                    </View>
                ) : null}
            </View>
            <Pressable
                ref={analysisButtonRef}
                style={style.pressable}
                onPress={handleOnpress}
                testID="searchButton"
            >
                <View style={style.buttonContainer}>
                    {ICON_COMPONENTS.magnifyingGlass()}
                    <StyledText
                        weight={FontType.SEMIBOLD}
                        style={style.textValue}
                    >
                        {t({ defaultMessage: 'ANALYSER' })}
                    </StyledText>
                </View>
            </Pressable>
        </View>
    )
})
SearchInput.displayName = 'SearchInput'
export default SearchInput
