import { useMemo, useRef, useState } from 'react'
import { Animated, View } from 'react-native'
import ScrollBar from './ScrollBar/ScrollBar'
import style from './CustomScrollView.style'

type Props = {
    containerMaxHeight: number
    children: JSX.Element
}

const CustomScrollView: React.FC<Props> = (props: Props) => {
    // logic made for the interaction between the custom scrollbar and the scrollview
    // so both are in sync and have the correct height
    // see : https://amanhimself.dev/blog/custom-scroll-bar-indicator-with-react-native-animated-api/
    const [completeScrollViewHeight, setCompleteScrollViewHeight] = useState(1)
    const [visibleScrollViewHeight, setVisibleScrollViewHeight] = useState(0)
    const isShowingScrollBar = useMemo<boolean>(
        () => completeScrollViewHeight > visibleScrollViewHeight,
        [completeScrollViewHeight, visibleScrollViewHeight]
    )

    const scrollIndicatorRatio =
        visibleScrollViewHeight / completeScrollViewHeight
    const scrollBarHeight = visibleScrollViewHeight * scrollIndicatorRatio

    const scrollViewOffset = useRef(new Animated.Value(0)).current

    const scrollViewRef = useRef(null)

    const maxScrollBarOffset =
        visibleScrollViewHeight > scrollBarHeight
            ? visibleScrollViewHeight - scrollBarHeight
            : 1

    const scrollBarPosition = Animated.multiply(
        scrollViewOffset,
        scrollIndicatorRatio
    ).interpolate({
        inputRange: [0, maxScrollBarOffset],
        outputRange: [0, maxScrollBarOffset],
        extrapolate: 'clamp',
    })

    return (
        <View
            style={{
                ...style.scrollViewContainer,
                maxHeight: props.containerMaxHeight,
            }}
        >
            <Animated.ScrollView
                testID={'animatedScrollView'}
                ref={scrollViewRef}
                style={style.listContainer}
                contentContainerStyle={style.contentContainerStyle}
                showsVerticalScrollIndicator={false}
                onContentSizeChange={(_w, height) => {
                    setCompleteScrollViewHeight(height)
                }}
                onLayout={({
                    nativeEvent: {
                        layout: { height },
                    },
                }) => {
                    setVisibleScrollViewHeight(height)
                }}
                onScroll={Animated.event(
                    [
                        {
                            nativeEvent: {
                                contentOffset: { y: scrollViewOffset },
                            },
                        },
                    ],
                    { useNativeDriver: false }
                )}
                scrollEventThrottle={10}
            >
                {props.children}
            </Animated.ScrollView>
            {isShowingScrollBar && (
                <ScrollBar
                    height={scrollBarHeight}
                    position={scrollBarPosition}
                />
            )}
        </View>
    )
}

export default CustomScrollView
