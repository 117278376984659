import { StyleSheet } from 'react-native'
import { FontSize } from 'shared/styles/font_enums'
import Colors from 'shared/styles/colors_enum'

const style = StyleSheet.create({
    container: {
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
    },
    title: {
        color: Colors.customBlack,
        fontSize: FontSize.fourteen,
        flex: 1,
    },
    value: {
        color: Colors.darkGrey,
        fontSize: FontSize.twenty,
    },
    tooltipContainer: {
        paddingTop: 3,
    },
})

export default style
