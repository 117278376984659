import CredentialsStorage from 'domain/storage/credentials_storage'
import {
    AuthRequestConfig,
    CodeChallengeMethod,
    ResponseType,
    exchangeCodeAsync,
    fetchUserInfoAsync,
    makeRedirectUri,
    revokeAsync,
    useAuthRequest,
} from 'expo-auth-session'

import appConfiguration from 'infrastructure/appConfiguration'
import { discovery } from 'infrastructure/authentication/configuration'
import AuthError from './auth_error'

const useAuthClient = (
    isWeb: boolean,
    credentialsStorage: CredentialsStorage
) => {
    const redirectUri = isWeb
        ? appConfiguration.HOZEE_IDP_REDIRECT_URI_WEB
        : appConfiguration.HOZEE_IDP_REDIRECT_URI_MOBILE

    const authRequestConfig: AuthRequestConfig = {
        codeChallengeMethod: CodeChallengeMethod.S256,
        clientId: appConfiguration.HOZEE_IDP_CLIENT_ID,
        redirectUri: makeRedirectUri({
            scheme: redirectUri,
        }),
        responseType: ResponseType.Code,
        scopes: ['openid', 'profile', 'email'],
        usePKCE: true,
    }

    const [request, response, promptAsync] = useAuthRequest(
        authRequestConfig,
        discovery
    )

    const doPKCE = async (code: string, codeVerifier: string) => {
        const userCredentials = await exchangeCodeAsync(
            {
                code: code,
                clientId: appConfiguration.HOZEE_IDP_CLIENT_ID,
                extraParams: {
                    code_verifier: codeVerifier || '',
                },
                redirectUri: makeRedirectUri({
                    scheme: redirectUri,
                }),
            },
            discovery
        )

        if (
            userCredentials.accessToken.length === 0 ||
            !userCredentials.refreshToken
        ) {
            throw new AuthError(
                `error while getting user credentials with code ${code} and code verifier ${codeVerifier}`
            )
        }

        await credentialsStorage.set(
            userCredentials.accessToken,
            userCredentials.refreshToken
        )

        return userCredentials
    }

    return {
        request,
        response,
        promptAsync,
        doPKCE,
        fetchUserInfoAsync,
        revokeAsync,
    }
}

export default useAuthClient
