import { StyleSheet } from 'react-native'
import Colors from 'shared/styles/colors_enum'
import { FontSize } from 'shared/styles/font_enums'

const style = StyleSheet.create({
    background: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Colors.transparentBlack,
    },
    container: {
        alignItems: 'center',
        backgroundColor: 'white',
        borderRadius: 6,
        margin: 20,
        paddingHorizontal: 100,
        paddingVertical: 60,
        shadowColor: Colors.black,
        shadowOffset: {
            width: 0,
            height: 18,
        },
        shadowOpacity: 0.12,
        shadowRadius: 24,
    },
    buttonsContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: 40,
        width: '100%',
    },
    confirmButton: {
        backgroundColor: 'transparent',
        borderColor: Colors.indigo,
        borderRadius: 25,
        borderWidth: 1,
        marginHorizontal: 15,
        paddingHorizontal: 32,
        paddingVertical: 13,
    },
    confirmText: {
        color: Colors.indigo,
        fontSize: FontSize.twelve,
        lineHeight: 14,
    },
    cancelButton: {
        backgroundColor: Colors.indigo,
        borderRadius: 25,
        marginHorizontal: 15,
        paddingHorizontal: 32,
        paddingVertical: 13,
    },
    cancelText: {
        color: Colors.white,
        fontSize: FontSize.twelve,
        lineHeight: 14,
    },
})

export default style
