import React from 'react'
import { View } from 'react-native'
import { RisksSummary } from 'domain/types/risk_at_address'
import style from './RisksSynthesis.style'
import MainRiskSynthesis from './MainRiskSynthesis/MainRiskSynthesis'
import {
    droughtRisk,
    floodRisk,
    waterRestrictions as waterRestrictionsRisk,
} from 'domain/types/risk_type'
import Separator, { Direction } from '../Separator/Separator'
import useWindow from 'shared/hooks/useWindow'
import { useFormatMessage } from 'i18n/hooks'
import { FontType } from 'shared/styles/font_enums'
import StyledText from '../StyledText/StyledText'

type Props = {
    summary: RisksSummary
}

const RisksSynthesis: React.FC<Props> = (props: Props) => {
    const t = useFormatMessage()
    const floodInfos = t({
        defaultMessage:
            'Une inondation correspond à la submersion temporaire de zones habituellement hors d’eau.',
    })
    const drougthInfos = t({
        defaultMessage:
            'La sécheresse est un déficit anormal, sur une période prolongée, d’une des composantes du cycle de l’eau.',
    })
    const waterRestrictionsInfos = t({
        defaultMessage:
            'Mesures prises afin de préserver l’état des milieux naturels et de conserver les usages prioritaires.',
    })
    const { flood, drought, waterRestrictions } = props.summary
    const { isSmallWindow } = useWindow()
    return (
        <View style={style.container}>
            <StyledText weight={FontType.BOLD} style={style.title}>
                {t({ defaultMessage: 'Synthèse du risque à cette adresse' })}
            </StyledText>
            <View
                style={[
                    style.row,
                    isSmallWindow && { flexDirection: 'column' },
                ]}
            >
                <MainRiskSynthesis
                    riskType={floodRisk}
                    severity={flood.global}
                    infos={floodInfos}
                />
                {!isSmallWindow && <Separator direction={Direction.VERTICAL} />}
                <MainRiskSynthesis
                    riskType={droughtRisk}
                    severity={drought.global}
                    infos={drougthInfos}
                />
                {!isSmallWindow && <Separator direction={Direction.VERTICAL} />}
                <MainRiskSynthesis
                    riskType={waterRestrictionsRisk}
                    severity={waterRestrictions.global}
                    infos={waterRestrictionsInfos}
                />
            </View>
            <Separator direction={Direction.HORIZONTAL} />
        </View>
    )
}

export default RisksSynthesis
