import { StyleSheet } from 'react-native'
import { FontSize } from 'shared/styles/font_enums'
import { Padding } from 'shared/styles/layout'
import Colors from '../../styles/colors_enum'

const style = StyleSheet.create({
    container: {
        flexDirection: 'column',
        paddingHorizontal: Padding.thirtySix,
    },
    title: {
        color: Colors.indigo,
        fontSize: FontSize.thirtyFive,
        paddingBottom: 15,
    },
    row: {
        flexDirection: 'row',
        width: '100%',
    },
})

export default style
