import Svg, {
    Path,
    Defs,
    LinearGradient,
    Stop,
    RadialGradient,
} from 'react-native-svg'
import {
    DEFAULT_ILLUSTRATION_PROPS,
    IllustrationProps,
} from 'shared/assets/illustration'

const IllustrationComponent: React.FC<IllustrationProps> = function ({
    size = DEFAULT_ILLUSTRATION_PROPS.size,
    idBaseName = DEFAULT_ILLUSTRATION_PROPS.idBaseName,
}: IllustrationProps) {
    return (
        <Svg width={size} height={size} fill="none" viewBox="0 0 200 200">
            <Path
                fill={`url(#${idBaseName}-a)`}
                d="M164.508 179.317c.245 9.323-36.202 17.845-81.41 19.035-45.207 1.19-82.055-5.402-82.3-14.725-.245-9.322 36.203-17.845 81.41-19.035 45.208-1.19 82.053 5.403 82.3 14.725Z"
            />
            <Path
                fill={`url(#${idBaseName}-b)`}
                d="m177.422 156.937-.243.648c-.355.727-.925 1.263-1.66 1.409h-.016L64.944 174.958c-1.098.234-2.23-.528-2.808-1.883L16.624 44.147c-.025-.061-.052-.12-.07-.181a4.705 4.705 0 0 1-.259-2.317c.095-.64.31-1.218.64-1.685.441-.64 1.08-1.045 1.841-1.028l95.753 1.962c.768.008 1.512.483 2.04 1.287l60.333 111.011c.735 1.114.52 3.741.52 3.741Z"
            />
            <Path
                fill="#D6E7FA"
                d="m180.84 146.654-.009.034-.243.648c-.354.727-.925 1.263-1.659 1.409h-.017l-90.878 13.397-.872.156-18.808 2.411c-1.098.235-2.23-.528-2.809-1.883L20.986 36.588c-.025-.061-.053-.12-.07-.181a4.667 4.667 0 0 1-.26-2.282v-.035c.096-.64.311-1.218.64-1.684.009-.009.009-.009.009-.017.017-.009.025-.026.025-.034a2.7 2.7 0 0 1 .33-.372v-.008c.405-.38.907-.615 1.477-.596l8.592.17 1.52.033.38.008 1.392.026 2.964.061.978.025 6.431.13 1.35.033.544.008 1.218.026 10.572.215 1.168.025.692.009h.009l1.053.025 10.726.216 1.02.017.847.016.908.026 10.882.215.872.017.97.025.785.009 11.011.224.751.016 1.09.026.657.008 11.15.235 1.824.033 2.532.07c.768.008 1.512.484 2.039 1.288l60.248 108.333c.734 1.115.863 2.548.528 3.707Z"
            />
            <Path
                fill={`url(#${idBaseName}-c)`}
                d="M183.358 134.564c-12.838 16.733-95.823 26.872-95.823 26.872l-18.813 3.275c-1.098.232-2.227-.53-2.808-1.885l-5.289-14.996-7.234-20.485L21.36 36.59c-.025-.065-.05-.122-.072-.188a4.221 4.221 0 0 1-.264-1.079 4.759 4.759 0 0 1 .007-1.201v-.032c.097-.64.31-1.22.64-1.685.006-.012.006-.012.006-.019a.038.038 0 0 0 .025-.031c.104-.142.22-.27.33-.374v-.006c.407-.38.91-.615 1.477-.602l8.596.175 1.518.038.38.007 1.39.025 2.97.057.976.025 6.431.129 1.343.038.549.006 1.214.026 10.572.22 1.17.025.69.006h.006l1.06.025 10.726.214 1.02.018.847.02.904.025 10.882.213.878.02.963.024.787.007 11.012.226.755.019 1.092.025.652.006 11.153.233 1.822.038 2.532.065c.775.013 1.518.484 2.039 1.292l18.948 34.072a445.756 445.756 0 0 0 9.907 18.27c1.603 2.79 3.159 5.431 4.675 7.937.006.02.019.032.031.057.621 1.02 1.227 2.014 1.835 2.977 13.847 22.196 23.939 31.114 27.045 34.104.674.667 1.048 1.771.48 2.527Z"
            />
            <Path
                fill={`url(#${idBaseName}-d)`}
                d="M47.806 39.025a1.779 1.779 0 0 1-.45-.148 2.994 2.994 0 0 1-.968-.623c-.347-.319-.632-.7-.77-1.09-.267-.734.043-1.254.71-1.27.104 0 .224.008.347.033.492.087 1.011.38 1.425.769.346.32.631.7.768 1.09.31.859-.164 1.412-1.062 1.239Z"
            />
            <Path
                fill={`url(#${idBaseName}-e)`}
                d="M35.07 37.785c.313.856-.162 1.41-1.06 1.238-.897-.171-1.879-1.005-2.191-1.86-.313-.856.162-1.411 1.06-1.238.897.173 1.879 1.005 2.191 1.86Z"
            />
            <Path
                fill={`url(#${idBaseName}-f)`}
                d="M61.117 38.826c.19.095.38.165.57.199.9.173 1.374-.38 1.064-1.235-.181-.503-.597-.995-1.09-1.358a2.856 2.856 0 0 0-1.106-.5c-.26-.053-.483-.045-.665.016-.441.155-.612.613-.388 1.219.084.242.232.485.413.71.31.396.752.742 1.202.95Z"
            />
            <Path
                fill={`url(#${idBaseName}-g)`}
                d="M75.014 39.025a1.952 1.952 0 0 1-.475-.157 3.166 3.166 0 0 1-1.064-.726 2.813 2.813 0 0 1-.657-.977c-.26-.701.017-1.202.623-1.263.129-.008.277-.008.441.025.459.087.942.347 1.341.693.389.338.701.752.855 1.167.31.858-.164 1.41-1.064 1.238Z"
            />
            <Path
                fill={`url(#${idBaseName}-h)`}
                d="M88.092 38.868c.163.07.32.123.484.157.9.173 1.374-.38 1.066-1.238-.154-.416-.466-.83-.855-1.167-.4-.346-.883-.606-1.34-.693a1.55 1.55 0 0 0-.434-.025c-.614.061-.89.553-.63 1.263.128.346.362.692.664.977.302.313.665.57 1.045.726Z"
            />
            <Path
                fill={`url(#${idBaseName}-i)`}
                d="M102.137 39.025a1.998 1.998 0 0 1-.475-.157 3.347 3.347 0 0 1-1.064-.726c-.293-.293-.528-.631-.656-.977-.26-.71.016-1.202.631-1.263.129-.008.276-.008.433.025.458.087.941.347 1.34.693.381.338.693.752.847 1.167.319.858-.156 1.41-1.056 1.238Z"
            />
            <Path
                fill={`url(#${idBaseName}-j)`}
                d="M115.363 38.826c.19.095.38.165.57.199.9.173 1.375-.38 1.065-1.235-.182-.503-.606-1.003-1.098-1.358-.347-.25-.727-.43-1.098-.5-.26-.053-.484-.045-.665.016-.442.155-.613.613-.389 1.219.085.242.232.485.406.71.318.396.751.742 1.209.95Z"
            />
            <Path
                fill="#EAE0E2"
                d="m88.035 161.274-.874.156L37.988 31.677l.978.025 49.033 129.46c.017.034.026.07.036.112Z"
            />
            <Path
                fill={`url(#${idBaseName}-k)`}
                d="M46.972 31.008c-1.184-2.944-3.22-5.16-5.664-5.813-1.634-.441-3.241-.129-4.669.891-1.451 1.047-2.583 2.787-3.227 4.918-1.183-2.942-3.214-5.153-5.666-5.809-1.763-.475-3.674.112-5.23 1.607-1.858 1.799-3.475 5.308-2.436 9.801a.854.854 0 0 0 1.028.64c.061-.009-.12-.657-.12-.657l-.016-.038a1.143 1.143 0 0 1-.054-.144 4.704 4.704 0 0 1-.26-2.316c.095-.64.31-1.218.64-1.685.112-.156.232-.302.363-.424v-.008c.355-1.721 1.168-3.068 2.057-3.926 1.106-1.064 2.41-1.495 3.579-1.184 1.84.492 3.439 2.3 4.433 4.685.796 1.894 1.202 4.157 1.065 6.423a.852.852 0 0 0 .804.916.106.106 0 0 0 .05.009.862.862 0 0 0 .864-.813 16.47 16.47 0 0 0-.88-6.488l1.39.025c.527-1.816 1.443-3.285 2.628-4.132.986-.71 2.092-.925 3.207-.623 1.911.52 3.562 2.445 4.538 4.97.492 1.244.813 2.629.933 4.063.061.682.07 1.374.025 2.073a.632.632 0 0 0 .034.285c.285.268.623.492.97.623a.858.858 0 0 0 .717-.796c.025-.467.034-.925.025-1.383a16.442 16.442 0 0 0-.81-4.82l1.218.025c.509-1.953 1.46-3.526 2.704-4.417.986-.71 2.093-.925 3.208-.623 1.989.536 3.682 2.593 4.66 5.255a14.47 14.47 0 0 1 .813 3.83c.059.632.067 1.28.025 1.928.31.397.752.743 1.201.95a.87.87 0 0 0 .52-.743c.033-.553.042-1.106.025-1.651a22.64 22.64 0 0 0-.133-1.584v-.008a17.488 17.488 0 0 0-.586-2.686h.001L62 32.18c.492-2.075 1.47-3.76 2.774-4.693.986-.71 2.093-.916 3.207-.623 2.05.553 3.805 2.732 4.747 5.532a14.46 14.46 0 0 1 .718 3.51c.059.681.067 1.374.025 2.064 0 .061 0 .12.009.173.304.313.675.57 1.064.726a.854.854 0 0 0 .656-.785c.034-.494.043-.977.026-1.461a16.825 16.825 0 0 0-.131-1.78 17.782 17.782 0 0 0-.5-2.411c-.146-.492-.311-.959-.501-1.417-1.182-2.947-3.214-5.16-5.66-5.817-1.635-.44-3.244-.129-4.67.891-1.46 1.045-2.583 2.783-3.232 4.918v.008c.154.37.293.75.405 1.138h-.001c-.112-.388-.25-.768-.406-1.14v-.009c-1.193-2.947-3.224-5.151-5.66-5.808-1.635-.44-3.252-.129-4.669.891-1.457 1.047-2.587 2.79-3.229 4.922Z"
            />
            <Path
                fill={`url(#${idBaseName}-l)`}
                d="M88.762 38.083c.034-.494.042-.977.025-1.461a17.855 17.855 0 0 0-.126-1.772 15.283 15.283 0 0 0-.433-2.134l.785.008c.44-2.324 1.47-4.227 2.887-5.238.986-.71 2.092-.925 3.207-.623 2.185.587 4.026 3.026 4.917 6.085a14.288 14.288 0 0 1 .57 5.021c0 .061 0 .12.009.173.312.313.684.57 1.064.726a.856.856 0 0 0 .657-.785c.033-.494.042-.977.025-1.461a17.758 17.758 0 0 0-.127-1.78 17.45 17.45 0 0 0-.354-1.847l.652.008c.408-2.456 1.461-4.462 2.939-5.515.986-.71 2.093-.916 3.207-.623 2.247.606 4.141 3.171 5.005 6.37.232.864.396 1.78.466 2.713.051.64.059 1.28.017 1.928.319.397.752.743 1.21.95a.87.87 0 0 0 .519-.743c.036-.553.045-1.106.017-1.649a16.413 16.413 0 0 0-.405-3.163c-.942-4.043-3.345-7.259-6.379-8.072-1.634-.439-3.251-.129-4.668.891-1.46 1.044-2.59 2.788-3.231 4.919-1.182-2.945-3.215-5.156-5.658-5.812-1.635-.44-3.252-.129-4.669.891-1.46 1.045-2.593 2.791-3.232 4.926-1.183-2.947-3.214-5.16-5.661-5.817-1.634-.44-3.252-.129-4.668.891-1.461 1.045-2.585 2.791-3.233 4.926.19.458.353.925.5 1.417l.908.025c.467-2.204 1.47-3.993 2.836-4.97.986-.71 2.092-.925 3.207-.623 2.118.57 3.915 2.878 4.84 5.809.32 1.02.536 2.109.631 3.232.05.682.06 1.375.017 2.065 0 .061 0 .12.017.173.302.313.665.57 1.045.726a.862.862 0 0 0 .665-.785Z"
            />
            <Path
                fill={`url(#${idBaseName}-m)`}
                d="m180.591 147.332-1.491 4.027c0 .014-.015.044-.015.059a3.63 3.63 0 0 1-.12.462c-.327.94-.969 1.67-1.835 1.865L66.54 169.703c-1.104.223-2.238-.537-2.804-1.894l-32.54-92.216-5.505-15.57-6.503-18.435c-.566-1.313-.463-2.76.074-3.803l.418-1.104.135-.374.836-2.22v.029c-.03.224-.044.447-.044.671 0 .18.014.36.044.537v.014c.044.36.135.716.268 1.06.015.074.044.135.06.194l7.904 22.341 5.95 16.883 30.709 87.01c.536 1.252 1.552 1.999 2.565 1.909.089 0 .165-.015.239-.03l2.386-.342 108.174-15.631h.015c1.148-.253 1.67-1.4 1.67-1.4Z"
            />
            <Path
                fill={`url(#${idBaseName}-n)`}
                d="M179.084 151.418c-.03.164-.074.312-.121.462L67.449 168.256c-.15.03-.298.045-.448.045-1.06 0-2.073-.775-2.595-2.059L19.828 39.992c-.433-1.043-.492-2.268-.15-3.31l.135-.375c.03-.088.074-.18.12-.268l.716-1.923c-.03.224-.044.447-.044.671 0 .18.015.36.044.537v.014l-.327.866c-.551 1.075-.566 2.46-.074 3.64l44.564 126.235c.521 1.253 1.552 1.954 2.55 1.745l111.722-16.406Z"
                opacity={0.6}
            />
            <Defs>
                <LinearGradient
                    id={`${idBaseName}-b`}
                    x1={16.251}
                    x2={177.451}
                    y1={106.968}
                    y2={106.968}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#475B80" />
                    <Stop offset={0.656} stopColor="#2C3D51" />
                    <Stop offset={1} stopColor="#21313E" />
                </LinearGradient>
                <LinearGradient
                    id={`${idBaseName}-c`}
                    x1={84.425}
                    x2={173.65}
                    y1={92.296}
                    y2={159.759}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#fff" />
                    <Stop offset={1} stopColor="#EDEEFF" />
                </LinearGradient>
                <LinearGradient
                    id={`${idBaseName}-d`}
                    x1={122.642}
                    x2={119.94}
                    y1={18.69}
                    y2={25.671}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#465A80" />
                    <Stop offset={0.651} stopColor="#2B3D52" />
                    <Stop offset={1} stopColor="#20313F" />
                </LinearGradient>
                <LinearGradient
                    id={`${idBaseName}-e`}
                    x1={122.642}
                    x2={119.94}
                    y1={18.69}
                    y2={25.671}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#465A80" />
                    <Stop offset={0.651} stopColor="#2B3D52" />
                    <Stop offset={1} stopColor="#20313F" />
                </LinearGradient>
                <LinearGradient
                    id={`${idBaseName}-f`}
                    x1={122.642}
                    x2={119.94}
                    y1={18.69}
                    y2={25.671}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#465A80" />
                    <Stop offset={0.651} stopColor="#2B3D52" />
                    <Stop offset={1} stopColor="#20313F" />
                </LinearGradient>
                <LinearGradient
                    id={`${idBaseName}-g`}
                    x1={122.642}
                    x2={119.94}
                    y1={18.69}
                    y2={25.671}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#465A80" />
                    <Stop offset={0.651} stopColor="#2B3D52" />
                    <Stop offset={1} stopColor="#20313F" />
                </LinearGradient>
                <LinearGradient
                    id={`${idBaseName}-h`}
                    x1={122.642}
                    x2={119.94}
                    y1={18.69}
                    y2={25.671}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#465A80" />
                    <Stop offset={0.651} stopColor="#2B3D52" />
                    <Stop offset={1} stopColor="#20313F" />
                </LinearGradient>
                <LinearGradient
                    id={`${idBaseName}-i`}
                    x1={122.642}
                    x2={119.94}
                    y1={18.69}
                    y2={25.671}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#465A80" />
                    <Stop offset={0.651} stopColor="#2B3D52" />
                    <Stop offset={1} stopColor="#20313F" />
                </LinearGradient>
                <LinearGradient
                    id={`${idBaseName}-j`}
                    x1={122.642}
                    x2={119.94}
                    y1={18.69}
                    y2={25.671}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#465A80" />
                    <Stop offset={0.651} stopColor="#2B3D52" />
                    <Stop offset={1} stopColor="#20313F" />
                </LinearGradient>
                <LinearGradient
                    id={`${idBaseName}-k`}
                    x1={108.49}
                    x2={108.743}
                    y1={37.804}
                    y2={25.637}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#8598B6" />
                    <Stop offset={0.213} stopColor="#899CB9" />
                    <Stop offset={0.441} stopColor="#96A7C1" />
                    <Stop offset={0.676} stopColor="#ACBBCE" />
                    <Stop offset={0.914} stopColor="#C9D6E1" />
                    <Stop offset={1} stopColor="#D6E1E9" />
                </LinearGradient>
                <LinearGradient
                    id={`${idBaseName}-l`}
                    x1={108.49}
                    x2={108.743}
                    y1={37.804}
                    y2={25.637}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#8598B6" />
                    <Stop offset={0.213} stopColor="#899CB9" />
                    <Stop offset={0.441} stopColor="#96A7C1" />
                    <Stop offset={0.676} stopColor="#ACBBCE" />
                    <Stop offset={0.914} stopColor="#C9D6E1" />
                    <Stop offset={1} stopColor="#D6E1E9" />
                </LinearGradient>
                <LinearGradient
                    id={`${idBaseName}-m`}
                    x1={97.475}
                    x2={100.775}
                    y1={133.086}
                    y2={136.386}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop offset={0.001} stopColor="#C2D5E4" />
                    <Stop offset={1} stopColor="#C0C0D3" />
                </LinearGradient>
                <LinearGradient
                    id={`${idBaseName}-n`}
                    x1={16.232}
                    x2={7.146}
                    y1={46.531}
                    y2={36.843}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#8598B6" />
                    <Stop offset={0.213} stopColor="#899CB9" />
                    <Stop offset={0.441} stopColor="#96A7C1" />
                    <Stop offset={0.676} stopColor="#ACBBCE" />
                    <Stop offset={0.914} stopColor="#C9D6E1" />
                    <Stop offset={1} stopColor="#D6E1E9" />
                </LinearGradient>
                <RadialGradient
                    id={`${idBaseName}-a`}
                    cx={0}
                    cy={0}
                    r={1}
                    gradientTransform="matrix(81.8533 -2.15626 .44463 16.87844 82.65 181.438)"
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#98A3B3" stopOpacity={0.54} />
                    <Stop
                        offset={0.262}
                        stopColor="#BBC3CD"
                        stopOpacity={0.399}
                    />
                    <Stop
                        offset={0.579}
                        stopColor="#E0E3E8"
                        stopOpacity={0.227}
                    />
                    <Stop
                        offset={0.837}
                        stopColor="#F7F8F9"
                        stopOpacity={0.088}
                    />
                    <Stop offset={1} stopColor="#fff" stopOpacity={0} />
                </RadialGradient>
            </Defs>
        </Svg>
    )
}
export default IllustrationComponent
