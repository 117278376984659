import Svg, { Path } from 'react-native-svg'
import { DEFAULT_ICON_PROPS, IconProps } from '../icon.types'

const IconComponent: React.FC<IconProps> = function ({
    size = DEFAULT_ICON_PROPS.size,
    color = DEFAULT_ICON_PROPS.color,
}: IconProps) {
    return (
        <Svg width={size} height={size} fill={color} viewBox="0 0 24 24">
            <Path d="M24.009 22.632A4.006 4.006 0 0 1 18 22.618a3.994 3.994 0 0 1-6 0 3.994 3.994 0 0 1-6 0 4.006 4.006 0 0 1-6.009.014L1.5 21.316A2.01 2.01 0 0 0 5 20h2a2 2 0 0 0 4 0h2a2 2 0 0 0 4 0h2a2.01 2.01 0 0 0 3.5 1.316ZM15 18v-1.192a3 3 0 1 0-6-.147V18Z" />
            <Path d="M7 18v-1.151c.138-6.817 9.76-6.85 10-.188V18h7v-7.913L13.338.494a2 2 0 0 0-2.676 0L0 10.09V18Z" />
        </Svg>
    )
}

export default IconComponent
