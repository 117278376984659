import { Platform, StyleSheet } from 'react-native'
import { ScreenProportion } from 'shared/styles/layout'

const style = StyleSheet.create({
    container: {
        height: '100%',
        minWidth: ScreenProportion.width1045,
        flexDirection: 'column',
    },
    linearGradient: {
        position: 'absolute',
        height: '100%',
        width: '100%',
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 40,
        ...Platform.select({
            ios: {
                height: '50%',
                width: '100%',
            },
        }),
        zIndex: 1,
    },
    profile: {
        justifyContent: 'flex-end',
    },
    fixedBottomContainer: {
        position: 'relative',
        height: 0,
        width: '100%',
        alignItems: 'center',
    },
    snackbarContainer: {
        position: 'absolute',
        bottom: 0,
    },
})

export default style
