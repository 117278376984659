import { StyleSheet } from 'react-native'
import { FontSize } from 'shared/styles/font_enums'
import { Margin, Padding } from 'shared/styles/layout'
import Colors from 'shared/styles/colors_enum'

const style = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Colors.white,
        paddingHorizontal: Padding.thirtySix,
        paddingTop: Padding.thirtySix,
    },
    title: {
        color: Colors.indigo,
        fontSize: FontSize.thirtyFive,
    },
    subContainer: {
        flex: 1,
        flexDirection: 'row',
    },
    section: {
        flex: 1,
        justifyContent: 'center',
    },
    subTitle: {
        color: Colors.customBlack,
        fontSize: FontSize.twentyFour,
        paddingBottom: 20,
    },

    addresses: {
        color: Colors.customBlack,
        fontSize: FontSize.sixteen,
    },
    risksContainer: {
        flexDirection: 'column',
    },
    riskContainer: {
        flexDirection: 'row',
        paddingBottom: Padding.thirtySix,
    },
    riskLabel: {
        alignSelf: 'center',
        color: Colors.customBlack,
        fontSize: FontSize.eighteen,
        flex: 0.8,
    },
    riskChart: {
        flex: 1.2,
        paddingLeft: 10,
    },
    myAddressesContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: Padding.thirtySix,
        paddingBottom: Padding.thirtySix,
        width: '100%',
    },
    noAddressesContainer: {
        flex: 1,
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: Margin.thirtySix,
    },
    noAddressesTextContainer: {
        marginVertical: Margin.thirtyTwo,
        width: '50%',
    },
    noAddressesText: {
        color: Colors.grey3,
        fontSize: FontSize.twentyFour,
        textAlign: 'center',
    },
})

export default style
